import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import FormController from "../../widgets/FormController/FormController";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import api_endpoints from "../../api";
import styles from "./DonorProfile.module.css";
import { useParams } from "react-router-dom";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { Alert, AlertTitle } from "@mui/material";

const DonorProfile = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialFormData, setInitialFormData] = useState({});
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    fetchDonorData();
  }, []);

  const fetchDonorData = async () => {
    setLoading(true);
    try {
      const response = await api_endpoints.getDonorProfileFromTempURL(
        params.id
      );

      if (response.status === 200) {
        setInitialFormData(response.data.donorProfile);
        setOrganizationName(response.data.organization_name.legal_name);
      } else {
        setError(true);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await api_endpoints.saveProfileDonor(
        params.id,
        formData.email,
        formData.adresse,
        formData.first_name,
        formData.last_name,
        formData.phone,
        formData.company
      );

      if (response.status === 200) {
        setDone(true);
      }
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
  };

  return (
      <div className={styles.main_container}>
        <div className={styles.right_panel}>
          <div className="flex flex-1 rounded-3xl bg-white w-1/2 min-w-[400px] max-w-[600px] p-12 flex-col">
            <div className="flex flex-1 justify-center items-center flex-col">
              <div className="text-xl px-10 text-center">
                Profil donateur pour{" "}
                <span className="font-semibold text-2xl">{organizationName}</span>{" "}
                propulsé par
              </div>
              <div className="flex w-[200px] mb-2">
                <img
                    className="object-contain"
                    src={process.env.PUBLIC_URL + "/Kiosk-Donations.svg"}
                />
              </div>
              {!done && !error ? (
                  <LoadingContainer loading={loading}>
                    <div className={styles.title_container}>
                      <label className="text-gray-500"></label>
                      <FormController initialValues={initialFormData}>
                        {({value, handleChange, getFormFields}) => (
                            <form onSubmit={(e) => handleSubmit(e, getFormFields())}>
                              <div className="flex flex-1 flex-col space-y-4 mt-3 ">
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Courriel"
                                    value={value.email}
                                    name="email"
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Prénom"
                                    value={value.first_name}
                                    name="first_name"
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Nom"
                                    value={value.last_name}
                                    name="last_name"
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Téléphone"
                                    value={value.phone}
                                    name="phone"
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Adresse"
                                    value={value.adresse}
                                    name="adresse"
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                    label="Nom d'entreprise"
                                    value={value.company}
                                    name="company"
                                    onChange={handleChange}
                                />
                                <ButtonLoading
                                    loading={submitting}
                                    type="submit"
                                    variant="contained"
                                >
                                  Enregistrer
                                </ButtonLoading>
                              </div>
                            </form>
                        )}
                      </FormController>
                    </div>
                  </LoadingContainer>
              ) : (
                  <></>
              )}
              {done ? (
                  <div className="flex flex-1 justify-center items-center">
                    <Alert
                        className="flex flex-1 justify-center items-center"
                        severity="success"
                    >
                      <AlertTitle>Merci pour votre interêt !</AlertTitle>
                      <p>
                        Vos informations ont été enregistrés avec succès. Veuillez
                        fermer cette page.
                      </p>
                    </Alert>
                  </div>
              ) : (
                  <></>
              )}
              {error ? (
                  <div className="flex flex-1 justify-center items-center">
                    <Alert
                        className="flex flex-1 justify-center items-center"
                        severity="error"
                    >
                      <AlertTitle>Lien invalide</AlertTitle>
                      <p>Votre lien est invalide et est expiré.</p>
                      <p>Veuillez fermer cette page.</p>
                    </Alert>
                  </div>
              ) : (
                  <></>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default DonorProfile;
