import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso as TblV } from "react-virtuoso";
import { Checkbox, Toolbar, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    columns,
    selectable,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {selectable ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        ) : (
          <></>
        )}
        {columns.map((column) => (
          <TableCell
            sx={{ fontWeight: 600 }}
            key={column.key}
            sortDirection={orderBy === column.label ? order : false}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTableToolbar(props) {
  const { numSelected, actions } = props;

  return (
    <Toolbar
      variant="dense"
      sx={{
        display: numSelected > 0 ? "flex" : "none",
        fontSize: "1rem",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <div className="flex flex-1 justify-between items-center">
        {numSelected > 0 ? (
          <div>{numSelected} lignes selectionnées</div>
        ) : (
          <></>
        )}
        {numSelected > 0 ? <Tooltip>{actions}</Tooltip> : <></>}
      </div>
    </Toolbar>
  );
}

const TableVirtuoso = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(props?.columns[0].key);
  const [selected, setSelected] = React.useState([]);
  const [selectable, setSelectable] = React.useState(props.selectable);

  React.useEffect(() => {
    if (props.onRowsSelected) props.onRowsSelected(selected);
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((row, index) => index);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead: React.forwardRef(() => (
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={props.rows.length}
        columns={props.columns}
        selectable={selectable}
      />
    )),
    TableRow: ({ item: _item, ...props }) => {
      const isItemSelected = isSelected(props["data-index"]);

      return (
        <TableRow
          onClick={(event) => {
            if (selectable) handleClick(event, props["data-index"]);
          }}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          sx={{ cursor: selectable ? "pointer" : "" }}
          {...props}
        />
      );
    },
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {props.columns.map((column) => (
          <TableCell key={column.label}>{column.label}</TableCell>
        ))}
      </TableRow>
    );
  };

  const rowContent = (_index, row) => {
    const isItemSelected = isSelected(_index);

    return (
      <React.Fragment>
        {selectable ? (
          <TableCell padding="checkbox">
            <Checkbox checked={isItemSelected} />
          </TableCell>
        ) : (
          <></>
        )}
        {props.columns.map((column, index) => (
          <TableCell key={column.label}>
            {row && row[column.key] ? row[column.key] : ""}
          </TableCell>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        actions={props.actions}
      />
      <TblV
        components={VirtuosoTableComponents}
        data={props.rows}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </>
  );
};

export default TableVirtuoso;
