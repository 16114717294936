import * as echarts from "echarts";
import { numberWithCommas } from "../utils";

export const dashboardChartOption = {
  tooltip: {
    trigger: "axis",
    formatter: (params, ticket, callback) => {
      return `${params[0].value[0]} <br/> <span> Total : ${numberWithCommas(
        params[0].value[1],
      )} $</span>`;
    },
  },
  title: {
    text: "Dons de toutes les campagnes",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
  },
  textStyle: {
    color: "gray",
    fontFamily: "Poppins",
  },
  xAxis: {
    type: "category",
    showGrid: true,
    splitLine: {
      show: true,
    },
    data: [], // Initialize data as an empty array
  },

  yAxis: {
    type: "value",
    showGrid: true,
    splitLine: {
      show: true,
    },
  },
  grid: {
    left: "25px",
    right: "10px",
    top: "45px",
    bottom: "25px",
    containLabel: true,
  },
  responsive: true,
  series: [
    {
      name: "Donations",
      type: "bar",
      color: "#1976d2",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#1976d2" },
          { offset: 1, color: "#b1d4e0" },
        ]),
      },
      emphasis: {
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 1, color: "#1976d2" },
          ]),
        },
      },
      data: [], // Initialize data as an empty array
    },
  ],
};
