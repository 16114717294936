import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { formatTimezoneData } from "../../utils";

// TimezoneAutocomplete Component
const TimezoneAutocomplete = ({
  timezone,
  changeTimezone,
  label,
  queryData,
  selectFirstOption = false,
}) => {
  // Helper function to format timezone options
  const getTimezoneOptions = () => {
    return queryData ? formatTimezoneData(queryData) : [];
  };

  // Get the selected timezone
  const getSelectedTimezone = () => {
    const timezoneOptions = getTimezoneOptions();

    // Check if the selected timezone is in the options
    if (timezone && timezoneOptions.length > 0) {
      const selectedTimezone = timezoneOptions.find(
        (option) => option.id === timezone
      );
      return selectedTimezone || null;
    }
    // Automatically select the first option if none is selected and selectFirstOption is true
    if (selectFirstOption && timezoneOptions.length > 0) {
      return timezoneOptions[0]; // Automatically select the first option
    }

    return null; // Return null if no selection and not selecting first option
  };

  useEffect(() => {
    if (selectFirstOption) {
      const firstOption = getTimezoneOptions()[0]; // Get the first option
      if (firstOption) {
        changeTimezone(firstOption.id); // Update the timezone in formData
      }
    }
  }, [selectFirstOption, queryData]);

  return (
    <Autocomplete
      options={getTimezoneOptions()}
      getOptionLabel={(option) => option.label || ""}
      onChange={(event, newValue) => {
        // Store only the ID of the selected timezone
        changeTimezone(newValue ? newValue.id : null);
      }}
      value={getSelectedTimezone()}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      fullWidth
      renderTags={() => null}
      disableClearable
      renderInput={(params) => <TextField required {...params} label={label} />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  );
};

export default TimezoneAutocomplete;
