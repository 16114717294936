import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";  

export const NavigationButton = ({text, startIcon, endIcon, path, params={}}, props) => {
    const navigate = useNavigate()
    
    const handleNavigate = () => {
        navigate(path, params)
    }
    
    return(
        <Button
            variant={"contained"}
            {...(({ text, startIcon, endIcon, path, params, ...o }) => o)(props)}    
            onClick={handleNavigate}
        >
            <div className={"mr-2"}>
                {startIcon}    
            </div>
            {text}
            <div className={"ml-2"}>
                {endIcon}    
            </div>
        </Button>
    )
}