const MIN_PASS_LENGTH = 8;

export const validPassword = (password) => {
  let validRegex =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*()'";:{}<>,[.?/_+\-|]{8,}$/;

  return password.length >= MIN_PASS_LENGTH && validRegex.test(password)
    ? true
    : false;
};

export const numberWithCommas = (number) => {
  if (!number) return "";

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validEmail = (email) => {
  let validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email.match(validRegex) ? true : false;
};

export const validName = (name) => {
  const regName = /^[a-zA-Z ]{2,30}$/;

  return regName.test(name) ? true : false;
};

export const validCurrencyAmount = (amount) => {
  const regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

  if (isNaN(amount) || !regex.test(amount)) return false;

  return true;
};

export const dateTimeReadable = (datetime, locale = "fr-CA") => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date(datetime));
};

export const dateTimeReadableSimple = (datetime, locale = "fr-CA") => {
  let datestring = "";

  const date = new Date(datetime);

  datestring = date.toISOString().replace("T", " ").split(".")[0];

  return datestring;
};

export const formatDateTime = (dateString, timeString = null) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  if (timeString) {
    // If a time string is provided, return date combined with it
    return `${year}-${month}-${day} ${timeString}`;
  } else {
    // If no time string is provided, use the current time from the date object
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
};

export const formatOffsetToHours = (offsetInSeconds) => {
  const totalHours = Math.floor(Math.abs(offsetInSeconds) / 3600);
  const totalMinutes = Math.floor((Math.abs(offsetInSeconds) % 3600) / 60);

  // Format hours and minutes with padding
  const formattedHours = totalHours.toString().padStart(2, "0");
  const formattedMinutes = totalMinutes.toString().padStart(2, "0");

  // Determine the sign
  const sign = offsetInSeconds >= 0 ? "+" : "-";

  return `${sign}${formattedHours}:${formattedMinutes}`;
};

export const convertUTCDateToLocalDate = (date, offset) => {
  // Convert offset from seconds to milliseconds
  const offsetInMilliseconds = offset * 1000;
  // Create a new Date object from the provided UTC date and apply the offset
  return new Date(new Date(date).getTime() + offsetInMilliseconds);
};

export const formatTimezoneData = (data) => {
  return data.map((timezone) => {
    // Determine the base structure to use
    const base = timezone.timezone._id ? timezone.timezone : timezone;
    return {
      id: base._id,
      label: `${formatOffsetToHours(base.offset)} ${base.timezone}`,
      key: base._id,
    };
  });
};
