import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const ImageInputV2 = ({
  defaultImage,
  image,
  onChange,
  removeImage,
  removeDefaultImage,
  helperText,
  placeholder,
}) => {
  return (
    <div className="w-full flex space-x-2">
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col justify-center items-center space-y-2">
          <div className="border outline-gray-500 outline-dashed rounded-md flex w-full justify-center items-center h-[200px] text-gray-500">
            {defaultImage && !image ? (
              <img
                className="object-contain h-full w-full"
                src={`${process.env.REACT_APP_S3_URL}${defaultImage}`}
              />
            ) : (
              <></>
            )}
            {image ? (
              <img
                alt={image.name}
                className="object-contain h-full w-full"
                src={URL.createObjectURL(image)}
              />
            ) : (
              <></>
            )}
            {!image && !defaultImage ? <div>{placeholder}</div> : <></>}
          </div>
          <div className="flex flex-1 space-x-4 w-full">
            <Button
              className="flex flex-1"
              size="small"
              variant="contained"
              onClick={() => document.getElementById("imageInput").click()}
            >
              <FileUploadOutlinedIcon /> Téléverser une image
              <input
                styles={{ display: "none" }}
                type="file"
                hidden
                onChange={onChange}
                id="imageInput"
              />
            </Button>
            {!defaultImage ? (
              <Button
                className="flex flex-1"
                variant="contained"
                color="error"
                disabled={image ? false : true}
                onClick={removeImage}
              >
                Retirer l'apérçu
              </Button>
            ) : (
              <></>
            )}
            {!image && defaultImage ? (
              <Button
                className="flex flex-1"
                variant="contained"
                color="error"
                disabled={defaultImage ? false : true}
                onClick={removeDefaultImage}
              >
                Retirer l'image originelle
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <label className="mt-2 text-sm text-gray-500">{helperText}</label>
      </div>
    </div>
  );
};

export default ImageInputV2;
