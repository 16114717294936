import { numberWithCommas } from "../utils";

export const viewCampaignChartOption = {
  title: {
    text: "",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: 20,
  },
  tooltip: {
    trigger: "axis",
    formatter: (params, ticket, callback) => {
      let sum = 0;
      let htmlString = "";

      for (const param of params) {
        const value = isNaN(param.value[1]) ? 0 : param.value[1];

        sum += value;

        htmlString += `<div> ${param.marker} ${
          param.seriesName
        } : ${numberWithCommas(value)} $</div>`;
      }

      return `
        <div>
          <div>${params[0]?.name}</div>
          ${htmlString}
          <div>Total : ${numberWithCommas(sum)} $</div>
        </div>
      `;
    },
  },
  grid: {
    left: "50px",
    right: "10px",
    top: "45px",
    bottom: "25px",
  },
  xAxis: {
    type: "category",
    showGrid: true,
    splitLine: {
      show: true,
    },
    data: Array.from(
      { length: 24 },
      (_, index) => `${index.toString().padStart(2, "0")}:00`
    ), // Populate the data array with labels for each hour
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} $",
    },
    showGrid: true,
    splitLine: {
      show: true,
    },
  },
  series: [
    {
      name: "Débit / Crédit",
      type: "bar",
      data: [],
      itemStyle: {
        color: "#1976d2",
      },
      stack: "x",
    },
    {
      name: "Argent Comptant",
      type: "bar",
      data: [],
      itemStyle: {
        color: "#293241",
      },
      stack: "x",
    },
  ],
};
