import styles from "./EditLocation.module.css";
import TimezoneAutocomplete from "../../widgets/TimezoneAutocomplete/TimezoneAutocomplete";
import { getPageFromName } from "../../route_utils";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import {
  MenuItem,
  Select,
  TextField,
  Button,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

const EditLocation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const authContext = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreview, setPreviewOpen] = useState(false);
  const [placementPreview, setPlacementPreview] = useState();
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [formData, setFormData] = useState({});

  const getUsersQuery = useApiClientQuery(
    () =>
      api_endpoints.getUsers(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `edit_location_getUsersQuery_${authContext.authContext.accessToken.organization_id}`,
      ],
    }
  );

  const getOrganizationTimezoneQuery = useApiClientQuery(
    () =>
      api_endpoints.getOrganizationCountryTimezone(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `edit_organization_countryTimezoneQuery_${authContext.authContext.accessToken.organization_id}`,
      ],
    }
  );

  const fetchEditLocationDataQuery = useApiClientQuery(
    () =>
      api_endpoints.getLocation(
        authContext.authContext.accessToken.organization_id,
        state.location_name
      ),
    {
      queryKey: [
        `fetch_edit_location_dataQuery_${authContext.authContext.accessToken.organization_id}_${state.location_name}`,
        // Enable the query when getUsersQuery.data.users has data
      ],
      enabled: getUsersQuery.data && getUsersQuery.data.users.length > 0,
    },
    (data) => {
      const newdata = data[0];
      newdata.person_responsible = newdata.person_responsible._id;
      newdata.timezone = newdata.timezone || null; // Set default value
      setFormData(newdata);
    }
  );

  const editLocationMutation = useApiClientMutation(
    api_endpoints.editLocation,
    [
      `edit_locationData_${authContext.authContext.accessToken.organization_id}`,
    ],
    (data) => {
      handleSuccessfulMutation(data);
    }
  );

  const handleSubmit = async (e) => {
    e?.preventDefault();

    const data = setRequestData();

    editLocationMutation.mutate(data);
  };

  const setRequestData = () => {
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    return data;
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPreviewOpen((prev) => placementPreview !== newPlacement || !prev);
    setPlacementPreview(newPlacement);
  };

  const handleSuccessfulMutation = (successMessage) => {
    setMessageSuccessAlert(successMessage);
    setShowSuccessAlert(true);
    navigate(getPageFromName("Emplacements").path);
  };

  return (
    <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
      <LoadingContainer
        loading={
          getUsersQuery.isFetching || fetchEditLocationDataQuery.isFetching
        }
        error={getUsersQuery.isError || fetchEditLocationDataQuery.isError}
      >
        <form
          id="publish_campaign_form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={`${styles.details_container} mt-4`}>
            <div className="text-lg">Détails de l'emplacement</div>
            <div className="[&>div]:flex [&>div]:items-center [&>div]:mt-4 [&>div]:space-x-4">
              <div>
                <TextField
                  fullWidth
                  placeholder="Nom de l'emplacement"
                  label="Nom de l'emplacement"
                  required
                  value={formData?.location_name}
                  onInput={(e) =>
                    changeFormData("location_name", e.target.value)
                  }
                />
                <TimezoneAutocomplete
                  timezone={formData.timezone} // Send only the timezone ID
                  changeTimezone={
                    (timezoneId) =>
                      setFormData((prev) => ({ ...prev, timezone: timezoneId })) // Update the timezone directly
                  }
                  label="Sélectionner le fuseau horaire"
                  queryData={getOrganizationTimezoneQuery.data} // Pass the data for timezone options
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  placeholder="Adresse"
                  label="Adresse"
                  required
                  value={formData?.address}
                  onInput={(e) => changeFormData("address", e.target.value)}
                />
                <TextField
                  fullWidth
                  placeholder="Numéro de téléphone"
                  label="Numéro de téléphone"
                  required
                  value={formData?.phone}
                  onInput={(e) => changeFormData("phone", e.target.value)}
                />
              </div>
              <div>
                <FormControl fullWidth required>
                  <InputLabel>Personne responsable</InputLabel>
                  <Select
                    fullWidth
                    value={formData?.person_responsible}
                    required
                    label="Personne responsable"
                    onChange={(e) =>
                      changeFormData("person_responsible", e.target.value)
                    }
                  >
                    {getUsersQuery.data?.users?.length > 0 &&
                      getUsersQuery.data.users.map((user) => (
                        <MenuItem value={user._id} key={user._id}>
                          <div className="flex flex-col">
                            <div className="text-lg font-semibold">{`${user.last_name} ${user.first_name}`}</div>
                            <div>{user.email}</div>
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <AccessControl allowedPermissions={["is_super_user"]}>
                <div className="mb-4 mt-4 flex space-x-2">
                  {image || formData.splash_screen_url ? (
                    <div className="">
                      <IconButton onClick={handleClick("right")}>
                        <ImageSearchIcon />
                      </IconButton>
                      <Popper
                        sx={{ zIndex: 1200 }}
                        open={openPreview}
                        anchorEl={anchorEl}
                        placement={placementPreview}
                        transition
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper className="w-[450px] h-[300px] border border-black p-4 flex-1 flex flex-col">
                              <div>Apperçu du splash screen</div>
                              <div className="flex flex-1 box-content justify-center items-center">
                                {formData.splash_screen_url.includes(
                                  "video/"
                                ) ? (
                                  <video
                                    autoplay
                                    muted
                                    controls
                                    width={125}
                                    className="object-fit"
                                  >
                                    <source
                                      src={
                                        image
                                          ? URL.createObjectURL(image)
                                          : formData.splash_screen_url
                                          ? `${process.env.REACT_APP_S3_URL}splashscreens/${formData.splash_screen_url}`
                                          : ""
                                      }
                                      type="video/mp4"
                                    />
                                    <source
                                      src={
                                        image
                                          ? URL.createObjectURL(image)
                                          : formData.splash_screen_url
                                          ? `${process.env.REACT_APP_S3_URL}splashscreens/${formData.splash_screen_url}`
                                          : ""
                                      }
                                      type="video/webm"
                                    />
                                    <source
                                      src={
                                        image
                                          ? URL.createObjectURL(image)
                                          : formData.splash_screen_url
                                          ? `${process.env.REACT_APP_S3_URL}splashscreens/${formData.splash_screen_url}`
                                          : ""
                                      }
                                      type="video/ogg"
                                    />
                                    Votre navigateur ne supporte pas ce type de
                                    vidéo
                                  </video>
                                ) : (
                                  <img
                                    className="object-contain"
                                    src={
                                      image
                                        ? URL.createObjectURL(image)
                                        : formData.splash_screen_url
                                        ? `${process.env.REACT_APP_S3_URL}splashscreens/${formData.splash_screen_url}`
                                        : ""
                                    }
                                  />
                                )}
                              </div>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-1 flex-col">
                    <div className="flex flex-1 space-x-2">
                      <div className="border outline-gray-500 outline-dashed rounded-md w-full">
                        <TextField
                          placeholder={image ? image.name : "Ajouter une image"}
                          variant="standard"
                          type="text"
                          fullWidth
                          sx={{
                            input: { cursor: "pointer" },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            startAdornment: (
                              <IconButton component="label">
                                <FileUploadOutlinedIcon />
                                <input
                                  styles={{ display: "none" }}
                                  type="file"
                                  hidden
                                  onChange={handleSelectImage}
                                  id="imageInput"
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="error"
                        disabled={image ? false : true}
                        onClick={removeImage}
                      >
                        Retirer
                      </Button>
                    </div>
                    <label className="text-sm text-gray-500">
                      Les formats suivants sont acceptés : .png .gif .jpeg
                    </label>
                  </div>
                </div>
              </AccessControl>
            </div>
          </div>
          <div className="flex mt-4 justify-end space-x-4">
            <CancelButton
              disabled={editLocationMutation.isPending}
              label="Annuler"
            />
            <ButtonLoading
              loading={editLocationMutation.isPending}
              type="submit"
              variant="contained"
            >
              Modifier
            </ButtonLoading>
          </div>
        </form>
      </LoadingContainer>
    </AccessControl>
  );
};

export default EditLocation;
