import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const SwitchWrapper = ({
  name,
  children,
  checked,
  disabled,
  style,
  className,
  label,
  onChange,
}) => {
  return (
    <div style={style} className={className}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name={name}
              checked={checked}
              value={checked}
              disabled={disabled}
              onChange={onChange}
            />
          }
          label={label}
        />
      </FormGroup>
      <div
        style={
          checked
            ? {}
            : {
                pointerEvents: "none",
                opacity: 0.7,
                cursor: "not-allowed",
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default SwitchWrapper;
