import React, { useState } from "react";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import Divider from "@mui/material/Divider";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import { formatTimezoneData } from "../../utils";

const Localization = ({ pageTranslation }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCountry, setModalCountry] = useState(null); // Store the current country object
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [selectedLocales, setSelectedLocales] = useState({});
  const [selectedTimezones, setSelectedTimezones] = useState({});
  const [tableFilter, setTableFilter] = useState({
    country_name: "",
    country_code: "",
  });

  const columns = [
    {
      field: "countryName",
      headerName: "Nom du pays",
      sortable: true,
      numberSort: false,
    },
    {
      field: "countryCode",
      headerName: "Code postal",
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    countryName: (
      <CustomTextField
        value={tableFilter.country_name || ""}
        onChange={(e) => changeTableFilter("country_name", e.target.value)}
        clearFilter={() => clearTableFilter("country_name")}
      />
    ),
    countryCode: (
      <CustomTextField
        value={tableFilter.country_code || ""}
        onChange={(e) => changeTableFilter("country_code", e.target.value)}
        clearFilter={() => clearTableFilter("country_code")}
      />
    ),
  };

  const allTimezonesQuery = useApiClientQuery(
    () => api_endpoints.getAllTimezones(),
    {
      queryKey: ["getallTimezones"],
      enabled: modalOpen,
    }
  );

  const allLocalesQuery = useApiClientQuery(
    () => api_endpoints.getAllLocales(),
    {
      queryKey: ["getAllLocales"],
      enabled: modalOpen,
    }
  );

  const getCountryTimezonesQuery = useApiClientQuery(
    () => api_endpoints.getCountryTimezones(modalCountry?.id),
    {
      queryKey: [`countryTimezoneQuery_${modalCountry?.id}`],
      enabled: !!modalCountry && modalOpen,
    },
    (data) => {
      setSelectedTimezones((prevSelected) => ({
        ...prevSelected,
        [modalCountry?.id]: formatTimezoneData(data),
      }));
    }
  );

  const getCountryLocalesQuery = useApiClientQuery(
    () => api_endpoints.getCountryLocales(modalCountry?.id),
    {
      queryKey: [
        `countryLocalesQuery_${modalCountry?.id}`,
        modalCountry?.id,
        modalOpen,
      ],
      enabled: !!modalCountry && modalOpen,
    },
    (data) => {
      setSelectedLocales((prevSelected) => ({
        ...prevSelected,
        [modalCountry?.id]: formatLocaleData(data),
      }));
    }
  );

  const getCountriesQuery = useApiClientQuery(
    () =>
      api_endpoints.getCountries(
        tableFilter.country_name,
        tableFilter.country_code
      ),
    {
      queryKey: [`countries_query_${tableFilter}`, tableFilter],
    }
  );

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalCountry(null);
    editCountryLocalesmutation.reset();
    editCountryTimezonesmutation.reset();
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const openModal = async (country) => {
    setModalOpen(true);
    setModalCountry(null); // Reset modalCountry state initially
    setModalCountry(country);
  };

  const handleSuccessfulMutation = (successMessage) => {
    setMessageSuccessAlert(successMessage);
    setShowSuccessAlert(true);
    handleCloseModal();
  };

  const editCountryLocalesmutation = useApiClientMutation(
    api_endpoints.editCountryLocales,
    [`edit_countryLocales_${modalCountry?.id}`]
  );

  const editCountryTimezonesmutation = useApiClientMutation(
    api_endpoints.editCountryTimezones,
    [`edit_countryTimezones_${modalCountry?.id}`]
  );

  const handleSubmit = async () => {
    const activeLocales = selectedLocales[modalCountry?.id] || [];
    const localeIds = activeLocales.map((locale) => locale.id);
    const activeTimezone = selectedTimezones[modalCountry?.id] || [];
    const timezoneIds = activeTimezone.map((timezone) => timezone.id);

    const countryLocaleData = {
      countryId: modalCountry?.id,
      locales: localeIds,
    };

    const countryTimezoneData = {
      countryId: modalCountry?.id,
      timezoneIds: timezoneIds,
    };

    try {
      // Await the completion of both mutations
      await editCountryLocalesmutation.mutateAsync(countryLocaleData);
      await editCountryTimezonesmutation.mutateAsync(countryTimezoneData);

      // If both mutations complete without error, show the success message
      handleSuccessfulMutation("Localization Settings Updated Successfully");
    } catch (error) {
      // Handle any errors that occur during the mutations
      console.error("Error updating localization settings:", error);
    }
  };

  const handleChipDelete = (itemToDelete, setSelected) => {
    setSelected((prevSelected) => {
      const updatedChips = { ...prevSelected };
      updatedChips[modalCountry?.id] = updatedChips[modalCountry?.id].filter(
        (item) => item.id !== itemToDelete.id
      );
      return updatedChips;
    });
  };

  const handleLocaleSelect = (event, value) => {
    setSelectedLocales((prevSelected) => ({
      ...prevSelected,
      [modalCountry?.id]: value,
    }));
  };

  const handleTimezoneSelect = (event, value) => {
    setSelectedTimezones((prevSelected) => ({
      ...prevSelected,
      [modalCountry?.id]: value,
    }));
  };

  const formatLocaleData = (data) => {
    return data.map((locale) => {
      // Determine the base structure to use
      if (locale.locale?._id) {
        return {
          id: locale.locale._id,
          label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
          key: locale.locale._id,
        };
      }
      return {
        key: locale._id,
        id: locale._id,
        label: `${locale.locale} (${locale.locale_abbreviation})`,
      };
    });
  };

  return (
    <>
      <Dialog
        open={modalOpen} // Use unified modal state
        onClose={handleCloseModal}
        sx={{ "& .MuiDialog-paper": { minHeight: 70, minWidth: 800 } }}
      >
        <DialogTitle>
          {getCountryTimezonesQuery.isFetching ||
          getCountryLocalesQuery.isFetching
            ? "Loading..." // Show loading text if Loading is true
            : `Modifier la localisation pour ${
                modalCountry?.countryName || ""
              }`}{" "}
        </DialogTitle>
        <DialogContent>
          <LoadingContainer
            loading={
              getCountryTimezonesQuery.isFetching ||
              getCountryLocalesQuery.isFetching ||
              editCountryLocalesmutation.isPending ||
              editCountryTimezonesmutation.isPending
            }
            error={
              getCountryTimezonesQuery.isError ||
              getCountryLocalesQuery.isError ||
              editCountryLocalesmutation.isError ||
              editCountryTimezonesmutation.isError
            }
          >
            <div className="flex flex-row space-x-4">
              <div className="flex-1 space-y-4">
                <Autocomplete
                  options={
                    allLocalesQuery.data
                      ? formatLocaleData(allLocalesQuery.data.locales) // List of all available locales
                      : []
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={handleLocaleSelect}
                  value={selectedLocales[modalCountry?.id] || []}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                  renderTags={() => null}
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label="Select Languages" />
                  )}
                />

                <div className="flex flex-wrap gap-2">
                  {(selectedLocales[modalCountry?.id] || []).map((locale) => (
                    <Chip
                      key={locale.id}
                      label={locale.label}
                      onDelete={() =>
                        handleChipDelete(locale, setSelectedLocales)
                      }
                    />
                  ))}
                </div>
              </div>
              <Divider orientation="vertical" flexItem />{" "}
              <div className="flex-1 space-y-4">
                <Autocomplete
                  options={
                    allTimezonesQuery.data
                      ? formatTimezoneData(allTimezonesQuery.data)
                      : []
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={handleTimezoneSelect}
                  value={selectedTimezones[modalCountry?.id] || []}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                  renderTags={() => null}
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label="Select Timezones" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                />
                <div className="flex flex-wrap gap-2">
                  {(selectedTimezones[modalCountry?.id] || []).map(
                    (timezone) => (
                      <Chip
                        key={timezone.id}
                        label={timezone.label}
                        onDelete={() =>
                          handleChipDelete(timezone, setSelectedTimezones)
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </LoadingContainer>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            loading={
              getCountryTimezonesQuery.isFetching ||
              getCountryLocalesQuery.isFetching ||
              editCountryLocalesmutation.isPending ||
              editCountryTimezonesmutation.isPending
            }
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </ButtonLoading>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setModalOpen(false)} // Correctly close the modal
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="text-2xl flex justify-between">
        <div className="text-gray-500 text-sm font-normal"></div>
      </div>
      <div>
        <div className="flex flex-1 h-full mt-5 flex-col">
          <DataGrid
            columns={columns}
            rows={
              getCountriesQuery.data?.map((country) => ({
                id: country._id,
                countryName: country.name,
                countryCode: country.code,
              })) || []
            }
            showDetails={(params) => openModal(params.row)}
            filters={filters}
            loading={getCountriesQuery.isFetching}
          />
        </div>
      </div>
    </>
  );
};
export default Localization;
