import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";

const OTPModal = (props) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435 } }}
      maxWidth="sm"
      open={props.open}
    >
      {/* <DialogTitle>Code OTP de du propriétaire</DialogTitle> */}
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <div className="flex flex-1 flex-col space-y-4">
          {/* <h1>Veuillez communiquer le code OTP suivant à propriétaire</h1> */}
          <h1>{props.message}</h1>
          <strong className="w-full text-center text-7xl font-extrabold bg-gradient-to-b from-[#F3CD86] to-[#905037] bg-clip-text text-transparent">
            {props.OTPCode}
          </strong>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained" color="secondary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTPModal;
