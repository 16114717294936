import { useEffect, useState } from "react";
import {
  useQuery,
  useMutation,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";

// Hook using React Query library for fetching data
// For creating, deleting, or editing data, use the useApiClientMutation hook
export function useApiClientQuery(endpoint, options, callBack) {
  // Initiates the query with the endpoint
  const query = useQuery({
    queryFn: async () => {
      const { data } = await endpoint(); // Simplified destructuring for data
      return data;
    },
    ...options,
  });

  // When the query is successful or data changes and there's a callback function, call the callback
  useEffect(() => {
    if (query.isSuccess && callBack) {
      callBack(query.data);
    }
  }, [query.isSuccess, query.data]);
  return query;
}
/**
 * Hook to use for executing multiple fetches
 * @param queries A list of query options
 * @param callBack Optional callback function once all data has been fetched
 */
export function useApiClientQueries(queries, callBack) {
  const [data, setData] = useState([]);
  const response = useQueries({ queries });

  // Validating if all queries are fetched
  // If so, set the data for the callback
  useEffect(() => {
    let dataArray = [];

    if (!response.some((query) => !query.isFetched) && callBack) {
      response.forEach((query) => {
        dataArray.push(query.data);
      });

      setData(dataArray);
    }
  }, [JSON.stringify(response), callBack]);

  // Call the callback function only if all queries are done fetching
  useEffect(() => {
    if (data.length > 0 && callBack) {
      callBack(data);
    }
  }, [data, callBack]);

  return response;
}

// Hook using React Query library for creating, deleting, or updating server data
export function useApiClientMutation(
  endpoint,
  queriesToInvalidate,
  callBack = () => {},
  onErrorCallback
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (endpointData) => {
      const response = await endpoint(endpointData);
      return response.data;
    },
    onSuccess: (data) => {
      callBack(data);
      // Removes cache from specified query to refetch data after a successful
      // API operation. This is the equivalent of refetching data
      if (queriesToInvalidate) {
        queriesToInvalidate.forEach((queryKey) =>
          queryClient.invalidateQueries({ queryKey })
        );
      }
    },
    onError: (error) => {
      // Call the custom onErrorCallback function if provided
      if (onErrorCallback) {
        onErrorCallback(error);
      }

      // You can also handle errors here, for example:
      console.error("An error occurred:", error);
    },
  });

  return mutation;
}
