export const tableStyles = {
  table: {
    style: {
      backgroundColor: "transparent",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: 0,
    },
  },
  headCells: {
    style: {
      fontWeight: 600,
      fontSize: "1rem",
      backgroundColor: "transparent",
      borderRadius: 0,
    },
  },
  headRow: {
    style: {
      backgroundColor: "transparent",
      border: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: 0,
    },
  },
  cells: {
    style: {
      fontSize: "0.875rem",
      borderRadius: 0,
    },
  },
  rows: {
    style: {
      borderRadius: 0,
      backgroundColor: "transparent",
      "&:not(:last-of-type)": {
        borderBottomWidth: 1,
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: "rgba(25, 118, 210, 0.1)",
      border: "0",
      outline: "unset",
    },
  },
  noData: {
    style: {
      borderRadius: 0,
      backgroundColor: "white",
    },
  },
  pagination: {
    style: {
      borderRadius: 0,
      width: "100%",
      border: 0,
      backgroundColor: "transparent",
      justifyContent: "left",
      borderTop: 0,
    },
  },
};
