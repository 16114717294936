import styles from "./Login.module.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./LoginPage";
import ForgotPassword from "./ForgotPass";

const Login = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.right_panel}>
        <div className="flex flex-1 rounded-3xl bg-white w-1/2 min-w-[400px] max-w-[600px] p-12 flex-col">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Login;
