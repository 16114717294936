import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { numberWithCommas } from "../../utils";
import ReactECharts from "echarts-for-react";

const ChartComponent = ({ data, title, pageTranslation }) => {
  const echartRef = useRef(null);
  const [chartOption, setChartOption] = useState({});

  useEffect(() => {
    onResizeEvent();
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    const totalAmount = data.reduce((acc, campaign) => acc + campaign.value, 0);

    const option = {
      title: {
        text: title,
      },
      responsive: true,
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const percent = `${params.percent}%`;
          const campaignName = params.name;
          const amount = params.value;
          return `<strong>${pageTranslation.pie_chart_slice_name || "Nom"}:</strong> ${campaignName}<br><strong>${pageTranslation.pie_chart_slice_amount || "Montant"}:</strong> ${numberWithCommas(
            amount,
          )}$<br>(${percent} ${pageTranslation.pie_chart_slice_summary || "du montant total"}: <strong>${numberWithCommas(
            totalAmount,
          )}$</strong>)`;
        },
      },
      grid: {
        left: "35px",
        right: "10px",
        top: "45px",
        bottom: "25px",
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: "75%",
          data: data.map((transaction_data) => ({
            name: transaction_data.name,
            value: transaction_data.value,
            label: {
              formatter: `{b}: {d}%`,
            },
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          color: [
            "#8BC1F7",
            "#A2D9D9",
            "#519DE9",
            "#73C5C5",
            "#06C",
            "#009596",
            "#004B95",
            "#005F60",
            "#002F5D",
            "#003737",
          ],
        },
      ],
    };

    if (option && typeof option === "object") {
      setChartOption({ ...option });
    }
  }, [data, title]); // Update the chart when data or title changes

  const onResizeEvent = () => {
    // Add event listener
    window.addEventListener("resize", resizeHandler);
  };

  const resizeHandler = () => {
    if (echartRef.current) {
      const echartInstance = echartRef.current.getEchartsInstance();
      if (echartInstance) {
        echartInstance.resize();
      }
    }
  };

  return (
    <>
      <ReactECharts
        ref={echartRef}
        option={chartOption}
        style={{ height: "100%", width: "100%" }}
        lazyUpdate={true}
        notMerge={true}
      />
    </>
  );
};

export default ChartComponent;
