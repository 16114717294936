import axios from "axios";
import { API_URL, CLOVER_URL, OTP_URL } from "./constants";

const api_endpoints = {
  register: (firstName, lastName, organization_id, email, password, otp) => {
    return axios.post(`${API_URL}/register`, {
      first_name: firstName,
      last_name: lastName,
      organization_id: organization_id,
      email: email,
      password: password,
      otp: otp,
    });
  },
  login: (email, password, rememberMe) => {
    return axios.post(`${API_URL}/login`, {
      email: email,
      password: password,
      remember_me: rememberMe,
    });
  },
  send_otp: (email, organization_id) => {
    return axios.post(`${OTP_URL}/send-otp`, {
      email: email,
      organization_id: organization_id,
    });
  },
  emailExists: (email) => {
    return axios.post(`${API_URL}/emailExists`, {
      email: email,
    });
  },
  sendOTPForgotPassword: (email) => {
    return axios.post(`${OTP_URL}/sendOTPForgotPassword`, {
      email: email,
    });
  },
  verifyOTPForgotPassword: (email, otp) => {
    return axios.post(`${OTP_URL}/verifyOTPForgotPassword`, {
      email: email,
      otp: otp,
    });
  },
  resetPassword: (email, password) => {
    return axios.post(`${API_URL}/resetPassword`, {
      email: email,
      password: password,
    });
  },
  allDonationsStats: (
    organization_id,
    emplacement,
    campaign,
    kiosk_id,
    startdate,
    enddate
  ) => {
    return axios.get(
      `${API_URL}/allDonationsStats?organization_id=${organization_id}&emplacement=${emplacement}&campaign=${campaign}&kiosk_id=${kiosk_id}&startdate=${startdate}&enddate=${enddate}`
    );
  },
  getCampaignsStatus: (organization_id) => {
    return axios.get(
      `${API_URL}/getCampaignsStatus?organization_id=${organization_id}`
    );
  },
  getCampaignsRepartition: (organization_id) => {
    return axios.get(
      `${API_URL}/getCampaignsRepartition?organization_id=${organization_id}`
    );
  },
  getCampaigns: (
    organization_id,
    campaignName,
    campaignCreator,
    donationAmount,
    status,
    acceptCash,
    is_archived,
    is_deleted
  ) => {
    return axios.get(
      `${API_URL}/getCampaigns?organization_id=${organization_id}&campaignName=${campaignName}&campaignCreator=${campaignCreator}&donationAmount=${donationAmount}&status=${status}&acceptCash=${acceptCash}&is_archived=${is_archived}&is_deleted=${is_deleted}`
    );
  },
  createCampaign: (data) => {
    return axios.post(`${API_URL}/createCampaign`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getCampaign: (campaign_id, date) => {
    return axios.get(
      `${API_URL}/getCampaign?campaign_id=${campaign_id}&date=${date}`
    );
  },
  getCampaignTranslations: (campaign_id) => {
    return axios.get(
      `${API_URL}/getCampaignTranslations?campaign_id=${campaign_id}`
    );
  },
  addCashToCampaign: (
    campaign_id,
    cash_amount,
    inserted_by,
    organization_id,
    date_donated,
    donor_id
  ) => {
    return axios.post(`${API_URL}/addCashToCampaign`, {
      campaign_id: campaign_id,
      cash_amount: cash_amount,
      inserted_by: inserted_by,
      organization_id: organization_id,
      date_donated: date_donated,
      donor_id: donor_id,
    });
  },
  setArchiveCampaignStatus: (campaign_id, archive_status) => {
    return axios.post(`${API_URL}/setArchiveCampaignStatus`, {
      campaign_id: campaign_id,
      archive_status: archive_status,
    });
  },
  getCampaignsTransacitons: (campaign_id, date) => {
    return axios.get(
      `${API_URL}/getCampaignTransactions?campaign_id=${campaign_id}&date=${date}`
    );
  },
  getCampaignsTransacitonsStats: (campaign_id, date) => {
    return axios.get(
      `${API_URL}/getCampaignTransactionsStats?campaign_id=${campaign_id}&date=${date}`
    );
  },
  deleteCampaign: (campaign_id) => {
    return axios.post(`${API_URL}/deleteCampaign`, {
      campaign_id: campaign_id,
    });
  },
  editCampaign: (data) => {
    return axios.post(`${API_URL}/editCampaign`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getUsers: (
    organization_id,
    first_name,
    last_name,
    email,
    created_at,
    is_active,
    is_verified,
    is_super_user
  ) => {
    return axios.get(
      `${API_URL}/getUsers?organization_id=${organization_id}&first_name=${first_name}&last_name=${last_name}&email=${email}&created_at=${created_at}&is_active=${is_active}&is_super_user=${is_super_user}&is_verified=${is_verified}`
    );
  },
  createLocation: (data) => {
    return axios.post(`${API_URL}/createLocation`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  editLocation: (data) => {
    return axios.post(`${API_URL}/editLocation`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getLocations: (organization_id, location_name, person_responsible) => {
    return axios.get(
      `${API_URL}/getLocations?organization_id=${organization_id}&location_name=${location_name}&person_responsible=${person_responsible}`
    );
  },
  getLocation: (organization_id, location_name) => {
    return axios.get(
      `${API_URL}/getLocation?organization_id=${organization_id}&location_name=${location_name}`
    );
  },
  createKiosk: (data) => {
    return axios.post(`${API_URL}/createKiosk`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  editKiosk: (data) => {
    return axios.post(`${API_URL}/editKiosk`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getKiosks: (organization_id, kioskname, location) => {
    return axios.get(
      `${API_URL}/getKiosks?organization_id=${organization_id}&kioskname=${kioskname}&location=${location}`
    );
  },
  getPieChartTransactions: (
    organization_id,
    emplacement,
    campaign,
    kiosk,
    startdate,
    enddate
  ) => {
    return axios.get(
      `${API_URL}/getPieChartTransactions?organization_id=${organization_id}&emplacement=${emplacement}&campaign=${campaign}&kiosk=${kiosk}&startdate=${startdate}&enddate=${enddate}`
    );
  },
  getTransactions: (
    organization_id,
    amount,
    location_name,
    donor_email,
    campaignName,
    kioskName,
    startdate,
    enddate
  ) => {
    return axios.get(
      `${API_URL}/getTransactions?organization_id=${organization_id}&amount=${amount}&location_name=${location_name}&donor_email=${donor_email}&campaignName=${campaignName}&kioskName=${kioskName}&startdate=${startdate}&enddate=${enddate}`
    );
  },
  getKioskByID: (kiosk_id) => {
    return axios.get(`${API_URL}/getKioskByID?kiosk_id=${kiosk_id}`);
  },
  getOrganizations: (
    legal_name,
    address,
    phone,
    owner_name,
    federal_tax_number
  ) => {
    return axios.get(
      `${API_URL}/getOrganizations?legal_name=${legal_name}&address=${address}&phone=${phone}&owner_name=${owner_name}&federal_tax_number=${federal_tax_number}`
    );
  },
  getOrganization: (organization_id) => {
    return axios.get(
      `${API_URL}/getOrganization?organization_id=${organization_id}`
    );
  },
  getLocationCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getLocationCount?organization_id=${organization_id}`
    );
  },
  getCampaignCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getCampaignCount?organization_id=${organization_id}`
    );
  },
  getBorneCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getBorneCount?organization_id=${organization_id}`
    );
  },
  getUsersCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getUsersCount?organization_id=${organization_id}`
    );
  },
  getKiosksCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getKiosksCount?organization_id=${organization_id}`
    );
  },
  getUserCount: (organization_id) => {
    return axios.get(
      `${API_URL}/getUserCount?organization_id=${organization_id}`
    );
  },
  createOrganization: (formData, country_id) => {
    return axios.post(`${API_URL}/createOrganization`, {
      ...formData,
      country_id,
    });
  },
  editOrganization: (formData, organization_id, country_id) => {
    delete formData.owner;
    return axios.post(`${API_URL}/editOrganization`, {
      formData: formData,
      organization_id: organization_id,
      country_id: country_id,
    });
  },
  deleteOrganization: (organization_id) => {
    return axios.post(`${API_URL}/deleteOrganization`, {
      organization_id: organization_id,
    });
  },
  getSuperUserCode: () => {
    return axios.get(`${API_URL}/getSuperUserCode`);
  },
  getSuperUsers: () => {
    return axios.get(`${API_URL}/getSuperUsers`);
  },
  createSuperUser: (first_name, last_name, email) => {
    return axios.post(`${API_URL}/createSuperUser`, {
      first_name: first_name,
      last_name: last_name,
      email: email,
    });
  },
  createUser: (first_name, last_name, email, organization_id, kiosk_code) => {
    return axios.post(`${API_URL}/createUser`, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      organization_id: organization_id,
      kiosk_code: kiosk_code,
    });
  },
  createUserOTP: (email, is_regular_user, is_super_user, is_owner) => {
    return axios.post(`${OTP_URL}/createUserOTP`, {
      email: email,
      is_regular_user: is_regular_user,
      is_super_user: is_super_user,
      is_owner: is_owner,
    });
  },
  checkUserOTP: (email) => {
    return axios.get(`${OTP_URL}/checkUserOTP?email=${email}`);
  },
  validateUser: (email, password, otpcode) => {
    return axios.post(`${API_URL}/validateUser`, {
      email: email,
      password: password,
      otpcode: otpcode,
    });
  },
  setKioskActiveStatus: (kiosk_id, active_status) => {
    return axios.post(`${API_URL}/setKioskActiveStatus`, {
      kiosk_id: kiosk_id,
      active_status: active_status,
    });
  },
  setClientID: (client_id) => {
    return axios.post(`${CLOVER_URL}/setClientID`, {
      client_id: client_id,
    });
  },
  getClientID: () => {
    return axios.post(`${CLOVER_URL}/getClientID`);
  },
  getDonorProfileFromTempURL: (url) => {
    return axios.get(`${API_URL}/getDonorProfileFromTempURL?url=${url}`);
  },
  saveProfileDonor: (
    url,
    email,
    adresse,
    first_name,
    last_name,
    phone,
    company
  ) => {
    return axios.post(`${API_URL}/saveProfileDonor`, {
      url: url,
      email: email,
      adresse: adresse,
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      company: company,
    });
  },
  importDonations: (data, campaign_id, organization_id, user_id) => {
    return axios.post(`${API_URL}/importDonations`, {
      data: JSON.stringify(data),
      campaign_id: campaign_id,
      organization_id: organization_id,
      user_id: user_id,
    });
  },
  getTransactionsYears: (organization_id) => {
    return axios.get(
      `${API_URL}/getTransactionsYears?organization_id=${organization_id}`
    );
  },
  // getDonors: (search, organization_id, year) => {
  //   return axios.get(
  //     `${API_URL}/getDonors?organization_id=${organization_id}&search=${search}&year=${year}`
  //   );
  // },
  getDonors: (search, sort, organization_id) => {
    return axios.get(
      `${API_URL}/getDonors?organization_id=${organization_id}&search=${search}&sort=${sort}`
    );
  },
  getDonor: (email) => {
    return axios.get(`${API_URL}/getDonor?email=${email}`);
  },
  getDonorTransactions: (
    organization_id,
    email,
    year,
    amount,
    campaignId,
    startdate,
    enddate
  ) => {
    return axios.get(
      `${API_URL}/getDonorTransactions?email=${email}&organization_id=${organization_id}&year=${year}&amount=${amount}&campaignId=${campaignId}&startdate=${startdate}&enddate=${enddate}`
    );
  },
  getKioskAppVersion: (environment_target) => {
    return axios.get(
      `${API_URL}/getKioskAppVersion?environment_target=${environment_target}`
    );
  },
  getKioskAppLink: (environment_target) => {
    return axios.get(
      `${API_URL}/getKioskAppLink?environment_target=${environment_target}`
    );
  },
  generateReceipt: (receiptData) => {
    return axios.post(`${API_URL}/generateReceipt`, receiptData);
  },
  getDonorLastReceiptStatus: (donor_id, organization_id, year) => {
    return axios.get(
      `${API_URL}/getDonorLastReceiptStatus?organization_id=${organization_id}&donor_id=${donor_id}&year=${year}`
    );
  },
  getKioskPingStatus: (kiosk_id, date) => {
    return axios.get(
      `${API_URL}/getKioskPingStatus?date=${date}&kiosk_id=${kiosk_id}`
    );
  },
  getKiosksPingStatus: (organization_id, startDate, endDate) => {
    return axios.get(
      `${API_URL}/getKiosksPingStatus?organization_id=${organization_id}&startDate=${startDate}&endDate=${endDate}`
    );
  },
  getLocales: (organization) => {
    return axios.get(`${API_URL}/getLocales?organization=${organization}`);
  },
  getAllLocales: () => {
    return axios.get(`${API_URL}/getAllLocales`);
  },
  getCountryLocales: (countryId) => {
    return axios.get(`${API_URL}/getCountryLocales?countryId=${countryId}`);
  },
  editCountryLocales: (data) => {
    return axios.post(`${API_URL}/editCountryLocales`, data);
  },
  editCountryTimezones: (data) => {
    return axios.post(`${API_URL}/editCountryTimezones`, data);
  },
  getCountries: (name, code) => {
    return axios.get(`${API_URL}/getCountries?name=${name}&code=${code}`);
  },
  getPageTranslation: (locale, page_name, platform) => {
    return axios.get(
      `${API_URL}/getPageTranslation?locale=${locale}&page_name=${page_name}&platform=${platform}`
    );
  },
  getCountryLocales: (countryId) => {
    return axios.get(`${API_URL}/getCountryLocales?countryId=${countryId}`);
  },
  getOrganizationLocale: (organizationId) => {
    return axios.get(
      `${API_URL}/getOrganizationLocale?organizationId=${organizationId}`
    );
  },
  getAllLocales: () => {
    return axios.get(`${API_URL}/getAllLocales`);
  },
  editOrganizationLocales: (organizationId, locales) => {
    return axios.post(`${API_URL}/editOrganizationLocales`, {
      organizationId: organizationId,
      locales: locales,
    });
  },
  editCampaignTranslations: (campaignId, translations) => {
    return axios.post(`${API_URL}/editCampaignTranslations`, {
      campaignId: campaignId,
      translations: translations,
    });
  },
  getPages: (platform) => {
    return axios.get(`${API_URL}/getPages?platform=${platform}`);
  },
  getPagesTranslations: (platform) => {
    return axios.get(`${API_URL}/getPagesTranslations?platform=${platform}`);
  },
  editTranslations: (page_id, locale_id, translations) => {
    return axios.post(`${API_URL}/editTranslations`, {
      page_id,
      locale_id,
      translations,
    });
  },
  emailTransactionExport: (data) => {
    return axios.post(`${API_URL}/emailTransactionExport`, data);
  },
  createRecurringTransactionExport: (data) => {
    return axios.post(`${API_URL}/createRecurringTransactionExport`, data);
  },
  getAllTimezones: () => {
    return axios.get(`${API_URL}/getAllTimezones`);
  },
  getCountryTimezones: (countryId) => {
    return axios.get(`${API_URL}/getCountryTimezones?countryId=${countryId}`);
  },
  getOrganizationCountryTimezone: (organizationId) => {
    return axios.get(
      `${API_URL}/getOrganizationCountryTimezone?organizationId=${organizationId}`
    );
  },
};

export default api_endpoints;
