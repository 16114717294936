import styles from "./Settings.module.css";
import PageTitle from "../../widgets/PageTitle/PageTitle";
import { PAGES } from "../../route_utils";
import SlTab from "@shoelace-style/shoelace/dist/react/tab";
import SlTabGroup from "@shoelace-style/shoelace/dist/react/tab-group";
import SlTabPanel from "@shoelace-style/shoelace/dist/react/tab-panel";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
// import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
// import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import SlRadioButton from "@shoelace-style/shoelace/dist/react/radio-button";
import SlRadioGroup from "@shoelace-style/shoelace/dist/react/radio-group";

const Settings = () => {
  return (
    <div className={styles.main_container}>
      <PageTitle title={PAGES.settings.label} />

      <div className={styles.inner_container}>
        <SlTabGroup>
          <SlTab slot="nav" panel="accountSettings">
            Mon compte
          </SlTab>
          <SlTab slot="nav" panel="password">
            Mot de passe
          </SlTab>
          {/* <SlTab slot="nav" panel="notifications">
            Notifications
          </SlTab> */}
          <SlTabPanel name="accountSettings">
            {/* <div className={styles.picture_select_container}>
              Your profile picture
              <label className={styles.custom_file_upload}>
                <input type="file" accept="image/*" />
                <SlIcon
                  library="boxicons"
                  name="bx-image-add"
                  style={{ fontSize: 40 }}
                ></SlIcon>
                Upload your photo
              </label>
            </div> */}
            <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
            <div className={styles.inputs_container}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <SlInput placeholder="First Name" label="First Name" />
                <SlInput
                  placeholder="Last Name"
                  label="Last Name"
                  style={{ marginLeft: 30 }}
                />
              </div>
              <div>
                <SlInput placeholder="Email Address" label="Email Address" />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <SlInput placeholder="Phone Number" label="Phone Number" />
                {/* <SlInput
                  placeholder="User Role"
                  label="User Role"
                  style={{ marginLeft: 30 }}
                /> */}
              </div>
              {/* <div style={{ display: "flex", flexDirection: "row" }}>
                <SlTextarea
                  resize="none"
                  placeholder="Add Additional Information"
                  label="Add Additional Information"
                />
              </div> */}
            </div>
            <div className={styles.footer}>
              <SlButton variant="primary">Save</SlButton>
            </div>
          </SlTabPanel>
          <SlTabPanel name="password">
            <div className={styles.inputs_container}>
              <div style={{ width: "30%" }}>
                <SlInput
                  placeholder="Current Password"
                  label="Current Password"
                  password-toggle
                  type="password"
                />
              </div>
              <div style={{ fontSize: 14 }}>
                Make sure your new password is at least 8 characters long,
                contains at least a number and a special character
              </div>
              <div style={{ width: "30%" }}>
                <SlInput
                  placeholder="New Password"
                  label="New Password"
                  password-toggle
                  type="password"
                />
              </div>
              <div style={{ width: "30%" }}>
                <SlInput
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  password-toggle
                  type="password"
                />
              </div>
            </div>
            <div className={styles.footer}>
              <SlButton variant="primary">Update Password</SlButton>
            </div>
          </SlTabPanel>
          <SlTabPanel name="notifications">
            <div className={styles.inputs_container}>
              <div className={styles.notif_options_container}>
                <div className={styles.notif_descriptions}>
                  <div>General notifications</div>
                  <div>
                    Select when you'll be notified when the following changes
                    occur
                  </div>
                </div>
                <div className={styles.notif_buttons}>
                  <div>
                    <label>I'm mentioned in a message</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>Someone replies to any message</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>I'm assigned a task</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>A task is overdue</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>A task status is updated</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                </div>
              </div>
            </div>
            <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
            <div className={styles.inputs_container}>
              <div className={styles.notif_options_container}>
                <div className={styles.notif_descriptions}>
                  <div>Summary notifications</div>
                  <div>
                    Select when you'll be notified when the following summaries
                    are ready
                  </div>
                </div>
                <div className={styles.notif_buttons}>
                  <div>
                    <label>Daily summary</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>Weekly summary</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>Monthly summary</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                  <SlDivider style={{ "--width": "3px", borderRadius: 10 }} />
                  <div>
                    <label>Quarterly summary</label>
                    <SlRadioGroup name="a" value="1">
                      <SlRadioButton value="1">None</SlRadioButton>
                      <SlRadioButton value="3">Email</SlRadioButton>
                    </SlRadioGroup>
                  </div>
                </div>
              </div>
            </div>
          </SlTabPanel>
        </SlTabGroup>
      </div>
    </div>
  );
};

export default Settings;
