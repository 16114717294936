import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getPageFromName, PAGES} from "../../route_utils";
import AuthContext from "../AuthContext/AuthContext";

const HiddenViewContext = createContext({
  hidden : false,
  setHidden: () => {},
})

export function HiddenViewProvider({children}){
  const authContext = useContext(AuthContext);
  const [hidden, setHidden] = useState(false);
  const value = {hidden, setHidden};
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if(authContext?.authContext?.loggedIn && !isFirstLoad){
      if(hidden === true)
        navigate(getPageFromName("Tableau de bord").path)
      else if(hidden === false)
        navigate(getPageFromName("Organisations").path)  
    }

    setIsFirstLoad(false);
  }, [hidden]);
  
  return (
      <HiddenViewContext.Provider value={value}>
        {children}
      </HiddenViewContext.Provider>
  )
}

export function useHiddenView(){
  const context = useContext(HiddenViewContext);
  
  if(!context){
    throw new Error("useHiddenView must be used within HiddenViewProvider");
  }
  
  return context;
}