import styles from "./ManageOrganizations.module.css";
import { getPageFromName } from "../../route_utils";
import api_endpoints from "../../api";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import { NavigationButton } from "../../widgets/NavigationButton/NavigationButton";
import AddIcon from "@mui/icons-material/Add";

const ManageOrganizations = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const [loadingError, setLoadingError] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    legal_name: "",
    address: "",
    phone: "",
    owner_name: "",
    federal_tax_number: "",
  });
  const columns = [
    {
      field: "legal_name",
      headerName: pageTranslation.table_org_column_name || "Nom légal",
      sortable: true,
      numberSort: false,
    },
    {
      field: "address",
      headerName: pageTranslation.table_org_column_address || "Adresse",
      sortable: true,
      numberSort: false,
    },
    {
      field: "phone",
      headerName: pageTranslation.table_org_column_phone || "Téléphone",
      sortable: true,
      numberSort: true,
    },
    {
      field: "owner_name",
      headerName:
        pageTranslation.table_org_column_owner || "Nom du propriétaire",
      sortable: true,
      numberSort: false,
    },
    {
      field: "federal_tax_number",
      headerName:
        pageTranslation.table_org_column_tax_number || "Numéro fiscal fédéral",
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    legal_name: (
      <CustomTextField
        value={tableFilter.legal_name || ""}
        onChange={(e) => changeTableFilter("legal_name", e.target.value)}
        clearFilter={() => clearTableFilter("legal_name")}
      />
    ),
    address: (
      <CustomTextField
        value={tableFilter.address || ""}
        onChange={(e) => changeTableFilter("address", e.target.value)}
        clearFilter={() => clearTableFilter("address")}
      />
    ),
    phone: (
      <CustomTextField
        value={tableFilter.phone || ""}
        onChange={(e) => changeTableFilter("phone", e.target.value)}
        clearFilter={() => clearTableFilter("phone")}
      />
    ),
    owner_name: (
      <CustomTextField
        value={tableFilter.owner_name || ""}
        onChange={(e) => changeTableFilter("owner_name", e.target.value)}
        clearFilter={() => clearTableFilter("owner_name")}
      />
    ),
    federal_tax_number: (
      <CustomTextField
        value={tableFilter.federal_tax_number || ""}
        onChange={(e) =>
          changeTableFilter("federal_tax_number", e.target.value)
        }
        clearFilter={() => clearTableFilter("federal_tax_number")}
      />
    ),
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter]);

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchOrganizations(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const onOrganizationClicked = (e) => {
    navigate(getPageFromName("Modifier l'organisation").path, {
      state: { organization_id: e.id },
    });
  };

  const fetchOrganizations = async () => {
    setLoading(true);
    let response = await api_endpoints.getOrganizations(
      tableFilter.legal_name,
      tableFilter.address,
      tableFilter.phone,
      tableFilter.owner_name,
      tableFilter.federal_tax_number
    );

    if (response.status === 200) {
      return response.data.map((organization) => ({
        id: organization._id,
        legal_name: organization.legal_name,
        address: organization.address,
        phone: organization.phone,
        owner_name: `${organization.owner.first_name} ${organization.owner.last_name}`,
        federal_tax_number: organization.federal_tax_number,
      }));
    } else {
      setLoadingError(true);
    }

    setLoading(false);
  };

  const mapOrganizationsData = (organizations) => {
    let data = [];

    for (let organization of organizations) {
      data.push({
        id: organization._id,
        legal_name: organization.legal_name,
        address: organization.address,
        phone: organization.phone,
        owner_name: `${organization.owner.first_name} ${organization.owner.last_name}`,
        federal_tax_number: organization.federal_tax_number,
      });
    }

    setOrganizations(data);
  };

  return (
    <>
      <div className={"flex justify-end"}>
        <NavigationButton
          startIcon={<AddIcon />}
          text={"Organisation"}
          path={getPageFromName("Créer une organisation").path}
        />
      </div>
      <div className={styles.table_container_body}>
        <DataGrid
          columns={columns}
          rows={rows}
          showDetails={onOrganizationClicked}
          filters={filters}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ManageOrganizations;
