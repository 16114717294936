import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import api_endpoints from "../../api";
import LoadingContainer from "../../components/LoadingContainer/LoadingContainer";
import AuthContext from "../AuthContext/AuthContext.js";

const LocaleContext = createContext({
  locale: null,
  locales: null,
  defaultLocale: null,
  changeLocale: null,
});

export function LocaleProvider({ children }) {
  const authContext = useContext(AuthContext);
  const [locale, setLocale] = useState({});
  const [locales, setLocales] = useState([]);
  const [defaultLocale, setDefaultLocale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    fetchLocales();
  }, []);

  useEffect(() => {
    fetchLocales();
  }, [authContext?.authContext?.accessToken?.organization_id]);

  useLayoutEffect(() => {
    initLocaleCookie();
    initLocalesCookie();
  }, []);

  useEffect(() => {
    if (
      locale &&
      Object.keys(locale).length > 0 &&
      locales &&
      locales.length > 0
    )
      setLoading(false);
  }, [locale, locales]);

  const initLocaleCookie = () => {
    const cookie = getLocaleCookie();

    if (cookie) {
      setLocale(cookie);
    }
  };

  const initLocalesCookie = () => {
    const cookie = getLocalesCookie();

    if (cookie) {
      setLocales(cookie);
    }
  };

  const changeLocale = (locale_abbreviation) => {
    const locale = getLocaleFromAbbreviation(locale_abbreviation);
    setLocale(locale);
    setLocaleCookie(locale);
  };

  const getLocaleFromAbbreviation = (locale_abbreviation) => {
    for (const locale of locales) {
      if (locale.locale_abbreviation === locale_abbreviation) return locale;
    }
  };

  const setLocalesCookie = (locales) => {
    try {
      localStorage.setItem("locales", JSON.stringify(locales));
    } catch (e) {
      console.error("Could not set locales cookie");
    }
  };

  const setLocaleCookie = (locale) => {
    try {
      localStorage.setItem("locale", JSON.stringify(locale));
    } catch (e) {
      console.error("Could not set locale cookie");
    }
  };

  const getLocalesCookie = () => {
    try {
      return JSON.parse(localStorage.getItem("locales"));
    } catch (e) {
      removeLocalesCookie();
      console.error("Could not fetch locales cookie");
      return null;
    }
  };

  const getLocaleCookie = () => {
    try {
      return JSON.parse(localStorage.getItem("locale"));
    } catch {
      removeLocaleCookie();
      console.error("Could not fetch locale cookie");
      return null;
    }
  };

  const removeLocaleCookie = () => {
    localStorage.removeItem("locale");
  };

  const removeLocalesCookie = () => {
    localStorage.removeItem("locales");
  };

  const fetchLocales = async () => {
    try {
      const response = await api_endpoints.getLocales(
        authContext?.authContext?.accessToken?.organization_id
      );

      if (response.status !== 200)
        throw new Error("Locales fetch response not 200");

      setDefaultLocale(response.data.default_locale);

      if (!getLocaleCookie() || !getLocalesCookie()) {
        setLocale(response.data.default_locale);
        setLocaleCookie(response.data.default_locale);

        setLocales(response.data.locales);
        setLocalesCookie(response.data.locales);
      }
    } catch (e) {
      setLoadingError(true);
      console.error("Could not fetch locales");
    }
  };

  const value = { locale, changeLocale, defaultLocale, locales };
  return (
    <LocaleContext.Provider value={value}>
      <LoadingContainer loading={loading} error={loadingError}>
        {children}
      </LoadingContainer>
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error("useLocale must be used within LocaleProvider");
  }
  return context;
}
