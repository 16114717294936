import { Button } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { getPageFromName } from "../../route_utils";
import AddIcon from "@mui/icons-material/Add";
import api_endpoints from "../../api";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import CustomDatePicker from "../../widgets/CustomDatePicker/CustomDatePicker";
import axios from "axios";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import { NavigationButton } from "../../widgets/NavigationButton/NavigationButton";

const ManageSuperUsers = ({ pageTranslation }) => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);

  const [tableFilter, setTableFilter] = useState({
    first_name: "",
    last_name: "",
    email: "",
    created_at: "",
    is_verified: null,
    is_active: null,
  });

  const columns = [
    {
      field: "first_name",
      headerName: pageTranslation.superuser_table_lastname || "Nom",
      sortable: true,
      numberSort: false,
    },
    {
      field: "last_name",
      headerName: pageTranslation.superuser_table_firstname || "Prénom",
      sortable: true,
      numberSort: false,
    },
    {
      field: "email",
      headerName: pageTranslation.superuser_table_email || "Courriel",
      sortable: true,
      numberSort: false,
    },
    {
      field: "created_at",
      headerName:
        pageTranslation.superuser_table_creationdate || "Date création",
      sortable: true,
      numberSort: false,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "is_verified",
      headerName: pageTranslation.superuser_table_verify || "Vérifié",
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Vérifié" : "Non vérifié"}
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
    {
      field: "is_active",
      headerName: pageTranslation.superuser_table_status || "Statut",
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Actif" : "Innactif"}
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
  ];

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const filters = {
    first_name: (
      <CustomTextField
        value={tableFilter.first_name || ""}
        onChange={(e) => changeTableFilter("first_name", e.target.value)}
        clearFilter={() => clearTableFilter("first_name")}
      />
    ),
    last_name: (
      <CustomTextField
        value={tableFilter.last_name || ""}
        onChange={(e) => changeTableFilter("last_name", e.target.value)}
        clearFilter={() => clearTableFilter("last_name")}
      />
    ),
    email: (
      <CustomTextField
        value={tableFilter.email || ""}
        onChange={(e) => changeTableFilter("email", e.target.value)}
        clearFilter={() => clearTableFilter("email")}
      />
    ),
    created_at: (
      <CustomDatePicker
        value={tableFilter.created_at}
        onChange={(newValue) => changeTableFilter("created_at", newValue)}
        clearFilter={() => clearTableFilter("created_at")}
      />
    ),
    is_verified: (
      <CustomSelect
        value={tableFilter.is_verified}
        onChange={(e) => {
          changeTableFilter("is_verified", e.target.value);
        }}
        options={[
          { value: null, label: "Tout" },
          { value: true, label: "Oui" },
          { value: false, label: "Non" },
        ]}
      />
    ),
    is_active: (
      <CustomSelect
        value={tableFilter.is_active}
        onChange={(e) => {
          changeTableFilter("is_active", e.target.value);
        }}
        options={[
          { value: null, label: "Tout" },
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ]}
      />
    ),
  };

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchSUperUsers(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const fetchUserOTP = async (selectedrow) => {
    var rowData = selectedrow.row;
    if (rowData.is_verified === false) {
      try {
        let otpResponse = await api_endpoints.checkUserOTP(rowData.email);
        if (otpResponse.status === 200) {
          setOTPCode(otpResponse.data.otp);
          setModalOpen(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const fetchSUperUsers = async () => {
    setLoading(true);
    const created_at = formatDate(tableFilter.created_at);
    let response = await api_endpoints.getUsers(
      null,
      tableFilter.first_name,
      tableFilter.last_name,
      tableFilter.email,
      created_at,
      tableFilter.is_active,
      tableFilter.is_verified,
      true
    );

    if (response.status === 200) {
      return response.data.users.map((super_user) => ({
        id: super_user._id,
        first_name: super_user.first_name,
        last_name: super_user.last_name,
        email: super_user.email,
        created_at: super_user.created_at,
        is_verified: super_user.is_verified,
        is_active: super_user.is_active,
      }));
    } else {
      setLoadingError(true);
    }

    setLoading(false);
  };

  const changeTableFilter = (option, value) => {
    setTableFilter({ ...tableFilter, [option]: value });
  };

  return (
    <>
      <div className={"flex justify-end"}>
        <NavigationButton
          startIcon={<AddIcon />}
          text={"Super utilisateur"}
          path={getPageFromName("Créer un super utilisateur").path}
        />
      </div>
      <LoadingContainer>
        <div>
          <div className="mt-4">
            <Dialog
              sx={{ "& .MuiDialog-paper": { maxHeight: 435 } }}
              maxWidth="sm"
              open={modalOpen}
            >
              <DialogTitle>Code OTP de l'utilisateur</DialogTitle>
              <DialogContent>
                <div className="flex flex-1 flex-col space-y-4">
                  <h1>
                    Veuillez communiquer avec votre utilisateur le code OTP
                    suivant
                  </h1>
                  <strong className="w-full text-center text-7xl font-extrabold">
                    {OTPCode}
                  </strong>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal} variant="contained">
                  Fermer
                </Button>
              </DialogActions>
            </Dialog>
            <DataGrid
              columns={columns}
              rows={rows}
              showDetails={fetchUserOTP}
              filters={filters}
              loading={loading}
            />
          </div>
        </div>
      </LoadingContainer>
    </>
  );
};

export default ManageSuperUsers;
