import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CustomTextField = ({ value, onChange, clearFilter }) => {
  return (
    <TextField
      size="small"
      value={value}
      onChange={onChange}
      fullWidth
      InputProps={value.length > 0 ? {
        endAdornment: (
          <InputAdornment position="end" className={"cursor-pointer"}>
            <CloseOutlinedIcon onClick={clearFilter} />
          </InputAdornment>
        ),
      }: {}}
    />
  );
};

export default CustomTextField;
