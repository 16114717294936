import { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import FormController from "../../widgets/FormController/FormController";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { validPassword } from "../../utils";
import FormHelperText from "@mui/material/FormHelperText";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import styles from "./VerifyEmail.module.css";
import { Divider } from "@mui/material";

const VerifyEmail = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordNotMatching, setPasswordNotMatching] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const navigate = useNavigate();

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (e) => {
    e.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const isConfirmPasswordSame = (password, confirmPassword) => {
    return password === confirmPassword ? true : false;
  };

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    setLoading(true);

    if (!validPassword(formData.password)) {
      setInvalidPassword(true);
      setLoading(false);
      return;
    }

    if (!isConfirmPasswordSame(formData.password, formData.confirm_password)) {
      setPasswordNotMatching(true);
      setLoading(false);
      return;
    }
    
    try{
      let response = await api_endpoints.validateUser(
          formData.email,
          formData.password,
          formData.otpcode
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        navigate("/");
      }  
    }
    catch (e){}

    setLoading(false);
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.right_panel}>
        <div className="flex flex-1 rounded-3xl bg-white w-1/2 min-w-[400px] max-w-[600px] p-12 flex-col">
          <div className="flex flex-1 justify-center items-center flex-col">
            <div className="flex w-[300px] mb-2">
              <img
                className="object-contain"
                src={process.env.PUBLIC_URL + "/Kiosk-Donations.svg"}
              />
            </div>
            <div className={styles.title_container}>
              <label className="text-2xl">Validez votre inscription</label>
              <label className="text-gray-500">
                Entrez le code OTP de 6 chiffres fournis par votre
                administrateur, entrez votre courriel et ensuite votre mot de
                passe pour créer votre compte
              </label>
              <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
              <FormController
                initialValues={{
                  email: "",
                  password: "",
                  confirm_password: "",
                  otpcode: "",
                }}
              >
                {({ value, handleChange, getFormFields }) => (
                  <form onSubmit={(e) => handleSubmit(e, getFormFields())}>
                    <div className="flex flex-1 flex-col space-y-4 ">
                      <TextField
                        autoFocus
                        fullWidth
                        required
                        autoComplete="off"
                        variant="outlined"
                        label="Courriel"
                        value={value.email}
                        name="email"
                        onChange={handleChange}
                      />
                      <FormControl>
                        <InputLabel htmlFor="outlined-adornment-password">
                          Mot de passe
                        </InputLabel>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          required
                          autoComplete="off"
                          label="Mot de passe"
                          value={value.password}
                          name="password"
                          onChange={handleChange}
                          error={invalidPassword}
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText
                          sx={{ color: invalidPassword ? "error.main" : "" }}
                        >
                          Le mot de passe doit contenir au moins 8 caractères,
                          au moins un chiffre et au moins un caractère spécial
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirmer le mot de passe
                        </InputLabel>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          required
                          autoComplete="off"
                          label="Confirmer le mot de passe"
                          value={value.confirm_password}
                          name="confirm_password"
                          onChange={handleChange}
                          error={passwordNotMatching}
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {passwordNotMatching ? (
                          <FormHelperText sx={{ color: "error.main" }}>
                            Les mots de passe doivent correspondre.
                          </FormHelperText>
                        ) : (
                          <></>
                        )}
                      </FormControl>
                      <TextField
                        fullWidth
                        required
                        autoComplete="off"
                        variant="outlined"
                        label="Code OTP de 6 chiffres"
                        value={value.otpcode}
                        name="otpcode"
                        onChange={handleChange}
                        min={0}
                        inputProps={{
                          maxLength: 6,
                          type: "number",
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, e.target.maxLength);
                        }}
                      />
                      <ButtonLoading
                        loading={loading}
                        type="submit"
                        variant="contained"
                      >
                        Valider
                      </ButtonLoading>
                    </div>
                  </form>
                )}
              </FormController>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
