import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api_endpoints from "../../api";
import { getPageFromName } from "../../route_utils";
import FormController from "../../widgets/FormController/FormController";
import { TextField, Divider, Tabs, Tab, InputAdornment } from "@mui/material";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import TabWrapper from "../../widgets/TabWrapper/TabWrapper";
import Button from "@mui/material/Button";
import SwitchWrapper from "../SwitchWrapper/SwitchWrapper";

const EditOrganizations = () => {
  const location = useLocation();
  const [organizationData, setOrganizationData] = useState({});
  const [country, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryLoading, setCountryLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  useEffect(() => {
    fetchOrganization();
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setCountryLoading(true);

    try {
      let response = await api_endpoints.getCountries();
      if (response.status === 200) {
        const formattedCountries = response.data.map((country) => ({
          id: country._id,
          label: `${country.name} (${country.code})`,
        }));
        setCountries(formattedCountries);
      }
    } catch (error) {
      console.error("Error fetching Countries", error);
    } finally {
      setCountryLoading(false);
    }
  };

  const fetchUserOTP = async (userData) => {
    if (userData.is_verified === false) {
      try {
        let otpResponse = await api_endpoints.checkUserOTP(userData.email);
        if (otpResponse.status === 200) {
          setShowOTP(true);
          setOTPCode(otpResponse.data.otp);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    setLoading(true);

    const postData = { ...formData };

    // if (postData.vopay.is_active === false) delete postData["vopay"];

    try {
      let response = await api_endpoints.editOrganization(
        postData,
        location.state.organization_id,
        selectedCountry
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        navigate(getPageFromName("Organisations").path);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const fetchOrganization = async () => {
    setLoading(true);
    let response = await api_endpoints.getOrganization(
      location.state.organization_id
    );

    if (response.status === 200) {
      let organizationData = response.data;
      // if (response.data.vopay === undefined) console.log(response.dataaS00--);
      organizationData = {
        ...response.data,
        // vopay: {
        //   account_id: "",
        //   api_key: "",
        //   shared_secret: "",
        //   url: "",
        //   is_active: false,
        // },
      };

      if (organizationData.owner.is_verified === false) {
        fetchUserOTP(organizationData.owner);
      }

      setOrganizationData(organizationData);
      if (response.data.country) {
        const countryData = response.data.country;
        const formattedCountry = {
          id: countryData._id,
          label: `${countryData.name} (${countryData.code})`,
        };
        setSelectedCountry(formattedCountry);
      }
    } else {
      setLoadingError(true);
    }

    setLoading(false);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const handleDeleteOrganization = async (event) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir effacer cette organisation?"
    );
    if (confirmed) {
      try {
        let deleteResponse = await api_endpoints.deleteOrganization(
          location.state.organization_id
        );
        if (deleteResponse.status === 200) {
          setMessageSuccessAlert(deleteResponse.data);
          setShowSuccessAlert(true);
          navigate(getPageFromName("Organisations").path);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      // Cancelled deletion
    }
  };

  const handleCountrySelect = (event, value) => {
    setSelectedCountry(value);
  };

  return (
    <LoadingContainer loading={loading} error={loadingError}>
      <div className="flex flex-1 flex-col mt-4">
        <div className="flex justify-end mb-4">
          <Button
            variant="contained"
            color="error"
            onClick={(event) => handleDeleteOrganization(event)}
          >
            Effacer l'organisation
          </Button>
        </div>

        <FormController initialValues={organizationData}>
          {({ value, handleChange, getFormFields }) => (
            <form onSubmit={(e) => handleSubmit(e, getFormFields())}>
              <div className="container-white flex-col">
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Détails de l'organisation" />
                  <Tab label="Paramètres Clover" />
                  {/* <Tab label="Paramètres VoPay" /> */}
                </Tabs>
                <Divider />
                <TabWrapper
                  className="flex flex-1 space-y-6 flex-col mt-4"
                  index={0}
                  value={tabValue}
                >
                  <TextField
                    fullWidth
                    required
                    autoComplete="off"
                    variant="outlined"
                    label="Nom legal de l'organisation"
                    value={value.legal_name}
                    name="legal_name"
                    onChange={handleChange}
                  />
                  <Autocomplete
                    options={country}
                    getOptionLabel={(option) =>
                      option && option.label ? option.label : ""
                    }
                    onChange={handleCountrySelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          countryLoading
                            ? "Loading Countries List..."
                            : "Select Country"
                        }
                        required
                      />
                    )}
                    value={selectedCountry}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : -1)
                    }
                    renderTags={() => null}
                    disableClearable
                    name="country"
                    disabled={countryLoading}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Adresse"
                    autoComplete="off"
                    value={value.address}
                    name="address"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Téléphone"
                    autoComplete="off"
                    value={value.phone}
                    name="phone"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Numéro fiscal fédéral"
                    autoComplete="off"
                    value={value.federal_tax_number}
                    name="federal_tax_number"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Reçu fiscal"
                    autoComplete="off"
                    value={value.tax_receipt}
                    name="tax_receipt"
                    onChange={handleChange}
                  />
                  <SwitchWrapper
                    label={"Activer la surcharge"}
                    checked={value.is_surcharge_active}
                    required={value.is_surcharge_active}
                    name="is_surcharge_active"
                    onChange={handleChange}
                  >
                    <TextField
                      required
                      fullWidth
                      type="number"
                      variant="outlined"
                      label="Taux de la surcharge"
                      placeholder="Taux de la surcharge"
                      name="surcharge_rate"
                      onChange={(e) => {
                        e.target.value /= 100;
                        handleChange(e);
                      }}
                      value={value.surcharge_rate * 100}
                      InputProps={{
                        startAdornment: <div className="mr-4">%</div>,
                      }}
                    />
                  </SwitchWrapper>
                  <Divider />
                  <div className="text-lg">
                    Détails de la personne responsable
                  </div>
                  {showOTP ? (
                    <div className="text-md font-semibold text-red-600">
                      Code OTP : {OTPCode}
                    </div>
                  ) : null}
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Prénom"
                    autoComplete="off"
                    value={value?.owner?.first_name}
                    name="owner.first_name"
                    onChange={handleChange}
                    disabled
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Nom"
                    autoComplete="off"
                    value={value?.owner?.last_name}
                    name="owner.last_name"
                    onChange={handleChange}
                    disabled
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Courriel"
                    autoComplete="off"
                    value={value?.owner?.email}
                    name="owner.email"
                    onChange={handleChange}
                    disabled
                  />
                </TabWrapper>
                <TabWrapper
                  index={1}
                  value={tabValue}
                  className="flex flex-1 space-y-6 flex-col mt-4"
                >
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Identifiant du marchand"
                    autoComplete="off"
                    value={value?.merchant_id}
                    name="merchant_id"
                    onChange={handleChange}
                  />
                </TabWrapper>
                {/* <TabWrapper
                    index={2}
                    value={tabValue}
                    className="flex flex-1 space-y-6 flex-col mt-4"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={value.vopay?.is_active}
                            onChange={handleChange}
                            name="vopay.is_active"
                          />
                        }
                        label="Activer VoPay"
                      />
                    </FormGroup>
                    <Alert severity="info">
                      Les informations entrées ne seront pas visibles une fois
                      sauvegardé.
                    </Alert>
                    <PasswordInput
                      fullWidth
                      required
                      type="password"
                      variant="outlined"
                      label="Account ID"
                      autoComplete="off"
                      value={value.vopay?.account_id}
                      name="vopay.account_id"
                      onChange={handleChange}
                      disabled={!value.vopay?.is_active}
                      autocomplete="off"
                    />
                    <PasswordInput
                      fullWidth
                      required
                      variant="outlined"
                      label="API Shared Secret"
                      autoComplete="off"
                      value={value.vopay?.shared_secret}
                      name="vopay.shared_secret"
                      onChange={handleChange}
                      disabled={!value.vopay?.is_active}
                      autocomplete="off"
                    />
                    <PasswordInput
                      fullWidth
                      required
                      type="password"
                      variant="outlined"
                      label="API Key"
                      autoComplete="off"
                      value={value.vopay?.api_key}
                      name="vopay.api_key"
                      onChange={handleChange}
                      disabled={!value.vopay?.is_active}
                      autocomplete="off"
                    />
                    <PasswordInput
                      fullWidth
                      required
                      type="password"
                      variant="outlined"
                      label="URL"
                      autoComplete="off"
                      value={value.vopay?.url}
                      name="vopay.url"
                      onChange={handleChange}
                      disabled={!value.vopay?.is_active}
                      autocomplete="off"
                    />
                  </TabWrapper> */}
              </div>
              <div className="flex flex-1 mt-2 justify-end space-x-4">
                <CancelButton disabled={loading} label="Annuler" />
                <ButtonLoading
                  loading={loading}
                  variant="contained"
                  type="submit"
                >
                  Enregistrer
                </ButtonLoading>
              </div>
            </form>
          )}
        </FormController>
      </div>
    </LoadingContainer>
  );
};

export default EditOrganizations;
