import { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import styles from "./Dashboard.module.css";
import { dashboardChartOption } from "../../EchartsUtils/DashboardChartOptionBar";
import { PAGES } from "../../route_utils";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import React from "react";
import ReactECharts from "echarts-for-react";
import CountSummary from "./CountSummary";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, MenuItem, Chip } from "@mui/material";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import CountUp from "react-countup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChartComponent from "./PieChart";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import HiddenViewContainer from "../../widgets/HiddenViewContainer/HiddenViewContainer";
import { useHiddenView } from "../../context/HiddenViewContext/HiddenViewContext";

const Dashboard = ({ pageTranslation }) => {
  const echartRef = useRef(null);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [chartOption, setChartOption] = useState({});
  const [isTransactionsEmpty, setIsTransactionsEmpty] = useState(false);
  const [transactionsSummary, setTransactionsSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [campaignsData, setCampaignsData] = useState([]);
  const [kiosksData, setKiosksData] = useState([]);
  const [emplacementData, setEmplacementData] = useState([]);
  const [campaignsChartData, setCampaignsChartData] = useState([]);
  const [kiosksChartData, setKiosksChartData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const hiddenView = useHiddenView();

  const [pageFilter, setpageFilter] = useState({
    emplacement: "",
    campagne: "",
    kiosk: "",
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });

  useEffect(() => {
    fetchFilterData();
    onResizeEvent();

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  // useEffect(() => {
  //   resizeHandler();
  // }, [chartOption]);

  useEffect(() => {
    asyncDataFetch();
    // fetchAllDonations();
    // fetchPieChartData();
  }, [pageFilter]);

  const asyncDataFetch = async () => {
    try {
      await Promise.allSettled([fetchAllDonations(), fetchPieChartData()]);
    } catch (e) {
      console.error("Error fetching transactions data");
    }
  };

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setpageFilter({
      ...pageFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const changepageFilter = (option, value) => {
    setpageFilter({ ...pageFilter, [option]: value });
  };

  const formatDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day} ${timeString}`;
  };

  const fetchFilterData = async () => {
    try {
      setLoading(true);
      const [responseCampaigns, responseKiosks, responseLocations] =
        await Promise.all([
          api_endpoints.getCampaigns(
            authContext.authContext.accessToken.organization_id
          ),
          api_endpoints.getKiosks(
            authContext.authContext.accessToken.organization_id
          ),
          api_endpoints.getLocations(
            authContext.authContext.accessToken.organization_id
          ),
        ]);
      // Check if all responses are successful (status code 200)
      if (
        responseCampaigns.status === 200 &&
        responseKiosks.status === 200 &&
        responseLocations.status === 200
      ) {
        if (responseCampaigns.data.length > 0) {
          setCampaignsData(responseCampaigns.data);
        }
        if (responseKiosks.data.length > 0) {
          setKiosksData(responseKiosks.data);
        }
        if (responseLocations.data.length > 0) {
          setEmplacementData(responseLocations.data);
          if (responseLocations.data.length === 1) {
            changepageFilter("emplacement", responseLocations.data[0]._id);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching filter data:", error);
    }
  };

  const fetchPieChartData = async () => {
    try {
      setLoading(true);
      setCampaignsChartData([]); //setting pie charts values to null before fetching data
      setKiosksChartData([]);

      const startDate = formatDate(
        pageFilter.start_date,
        pageFilter.start_time
      );
      const endDate = formatDate(pageFilter.end_date, pageFilter.end_time);
      let response = await api_endpoints.getPieChartTransactions(
        authContext.authContext.accessToken.organization_id,
        pageFilter.emplacement,
        pageFilter.campagne,
        pageFilter.kiosk,
        startDate,
        endDate
      );
      if (response.status === 200) {
        mapCampaignsPieChart(response.data[0].campaignsDonations);
        mapKiosksPieChart(response.data[0].kioskDonations);
      }
      setLoading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching filter data:", error);
    }
    setLoading(false);
  };

  const fetchAllDonations = async () => {
    setLoading(true);
    const startDate = formatDate(pageFilter.start_date, pageFilter.start_time);
    const endDate = formatDate(pageFilter.end_date, pageFilter.end_time);
    setChartOption({});

    try {
      let response = await api_endpoints.allDonationsStats(
        authContext.authContext.accessToken.organization_id,
        pageFilter.emplacement,
        pageFilter.campagne,
        pageFilter.kiosk,
        startDate,
        endDate
      );

      if (response.status === 200) {
        setIsTransactionsEmpty(true);

        if (response.data.transactions[0].totalByDate.length > 0) {
          mapDonationData(response.data.transactions);
          setIsTransactionsEmpty(false);
        }

        setTransactionsSummary(response.data.transactions_summary);
      }
    } catch (error) {
      console.error("Error fetching all donations:", error);
    }

    setLoading(false);
  };

  const clearpageFilter = () => {
    setSelectedPeriod("today");

    let updatedPageFilter = {
      emplacement: "",
      campagne: "",
      kiosk: "",
      start_date: dayjs(new Date()),
      end_date: dayjs(new Date()),
      start_time: dayjs().startOf("day").format("HH:mm"),
      end_time: dayjs().endOf("day").format("HH:mm"),
    };

    if (emplacementData.length === 1) {
      updatedPageFilter.emplacement = emplacementData[0]._id;
    }

    setpageFilter(updatedPageFilter);
  };

  const resizeHandler = () => {
    if (echartRef.current) {
      const echartInstance = echartRef.current.getEchartsInstance();
      if (echartInstance) {
        echartInstance.resize();
      }
    }
  };

  const onResizeEvent = () => {
    // Add event listener
    window.addEventListener("resize", resizeHandler);
  };

  const mapDonationData = (donations) => {
    let chartData = [];
    let option = { ...dashboardChartOption };
    let donationsPerHour = donations[0].totalByHour;
    let donationsPerDate = donations[0].totalByDate;
    const { start_date, end_date } = pageFilter;
    const startDateString = start_date.format("YYYY-MM-DD");
    const endDateString = end_date.format("YYYY-MM-DD");

    if (startDateString === endDateString) {
      // When start date is the same as end date, populate x-axis labels with hours
      for (let donation of donationsPerHour) {
        // Push an array containing the hour and the total donation amount to the chartData array
        chartData.push([donation._id.hour, donation.total]);
      }

      option.series[0].data = chartData;
      option.xAxis.data = Array.from({ length: 24 }, (_, index) => {
        return `${index.toString().padStart(2, "0")}:00`;
      });
    } else {
      const xAxisData = [];

      for (let donation of donationsPerDate) {
        // Push an array containing the date and the total donation amount to the chartData array
        chartData.push([donation._id.date, donation.total]);
        xAxisData.push(donation._id.date);
      }
      option.series[0].data = chartData;
      option.xAxis.data = xAxisData;
    }

    option.title.text =
      pageTranslation.bar_chart_title || "Dons de toutes les campagnes";
    option.series[0].data = chartData;

    setChartOption({ ...option });
  };

  const mapCampaignsPieChart = (responseData) => {
    const mappedData = responseData.map((item) => ({
      value: item.totalDonation,
      name: item.campaignName,
    }));

    setCampaignsChartData(mappedData);
  };

  const mapKiosksPieChart = (responseData) => {
    const mappedData = responseData.map((item) => ({
      value: item.totalDonation,
      name: item.kioskName,
    }));

    setKiosksChartData(mappedData);
  };

  return (
    <>
      {authContext.authContext.accessToken.organization_id ? (
        <>
          <div className={styles.header}>
            <div className={styles.left_header}>
              {pageTranslation.welcome_message || "Bienvenue"}{" "}
              <span>{authContext.authContext.accessToken.last_name} ! </span>
              <div>
                {pageTranslation.motd || "Voici les dernières mises à jour"}
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-end space-x-4 flex-grow">
            <div className="flex w-1/4 flex-col">
              {pageTranslation.location_filter_desc || "Emplacement"}
              <Select
                size="small"
                variant="outlined"
                className="w-100"
                displayEmpty
                value={pageFilter.emplacement}
                onChange={(e) => {
                  changepageFilter("emplacement", e.target.value);
                }}
              >
                <MenuItem
                  value={""}
                  style={{
                    display: emplacementData.length <= 1 ? "none" : "block",
                  }}
                >
                  {pageTranslation.location_filter_default_value || "Tout"}
                </MenuItem>
                {emplacementData.map((emplacement, index) => (
                  <MenuItem
                    key={emplacement._id}
                    value={emplacement._id}
                    selected={emplacementData.length === 1 && index === 0}
                  >
                    <div className="flex items-center justify-between">
                      <span style={{ marginRight: "10px" }}>
                        {emplacement.location_name}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col">
              {pageTranslation.campaign_filter_decs || "Campagne"}
              <Select
                size="small"
                variant="outlined"
                className="w-60"
                displayEmpty
                value={pageFilter.campagne}
                onChange={(e) => {
                  changepageFilter("campagne", e.target.value);
                }}
              >
                <MenuItem value={""}>
                  {pageTranslation.campaign_filter_default_value || "Tout"}
                </MenuItem>
                {campaignsData.map((campaign) => (
                  <MenuItem key={campaign._id} value={campaign._id}>
                    <div className="flex flex-1 items-center justify-between">
                      <span>{campaign.name}</span>
                      <div>
                        <Chip
                          color={campaign.enabled ? "success" : "error"}
                          label={
                            campaign.enabled
                              ? pageTranslation.enabled_message || "Active"
                              : pageTranslation.disabled_message || "Désactivé"
                          }
                          size="small"
                        />
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col w-1/4">
              {pageTranslation.kiosk_filter_desc || "Kiosk"}
              <Select
                size="small"
                variant="outlined"
                className="w-40"
                displayEmpty
                fullWidth
                value={pageFilter.kiosk}
                onChange={(e) => {
                  changepageFilter("kiosk", e.target.value);
                }}
              >
                <MenuItem value={""}>
                  {pageTranslation.kiosk_filter_default_value || "Tout"}
                </MenuItem>
                {kiosksData.map((kiosk) => (
                  <MenuItem key={kiosk._id} value={kiosk._id}>
                    <div className="flex flex-1 items-center justify-between">
                      <span>{kiosk.name}</span>
                      <div>
                        <Chip
                          color={kiosk.is_active ? "success" : "error"}
                          label={
                            kiosk.is_active
                              ? pageTranslation.enabled_message || "Active"
                              : pageTranslation.disabled_message || "Désactivé"
                          }
                          size="small"
                        />
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col" style={{ width: "200px" }}>
              Periode
              <PredefinedPeriods
                selectedPeriod={selectedPeriod}
                handlePeriodChange={handlePeriodChange}
                setSelectedPeriod={setSelectedPeriod}
                setpersonalizedDates={setpersonalizedDates}
              />
            </div>
            <div className="items-end space-x-4" style={{ marginLeft: "auto" }}>
              <Button color="secondary" onClick={clearpageFilter}>
                {pageTranslation.clear_filter_button || "Effacer Le Filtre"}
              </Button>
            </div>
          </div>
          <div className={styles.stats_container}>
            <div className={styles.donations_stats_wrapper}>
              <div className={styles.donations_stats_left}>
                <LoadingContainer loading={loading}>
                  {isTransactionsEmpty ? (
                    <div
                      className="flex flex-1"
                      style={{
                        backgroundColor: "lightgray",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {pageTranslation.no_data_bar_graph ||
                        "Aucune transaction à afficher"}
                    </div>
                  ) : (
                    <div className="flex flex-1 space-x-4">
                      <div className="flex-[0.7]">
                        <ReactECharts
                          ref={echartRef}
                          option={chartOption}
                          style={{ height: "100%", width: "100%" }}
                          lazyUpdate={true}
                          notMerge={true}
                        />
                      </div>
                      <Divider orientation="vertical" />
                      <div className="flex flex-[0.3] flex-col justify-center items-center space-y-6">
                        <div className="text-center">
                          <div className="text-7xl font-extrabold [&>span]:bg-gradient-to-b [&>span]:from-[#1976d2] [&>span]:to-[#B1D4E0] [&>span]:bg-clip-text [&>span]:text-transparent">
                            <CountUp
                              end={
                                transactionsSummary
                                  ? transactionsSummary.count
                                  : 0
                              }
                            />
                          </div>
                          <label className="mt-4 text-lg text-gray-600 mb-4">
                            Transactions
                          </label>
                        </div>
                        <div className="text-center">
                          <div className="text-7xl font-extrabold [&>span]:bg-gradient-to-b [&>span]:from-[#1976d2] [&>span]:to-[#B1D4E0] [&>span]:bg-clip-text [&>span]:text-transparent">
                            <CountUp
                              decimals={2}
                              end={
                                transactionsSummary
                                  ? transactionsSummary.transactions_total
                                  : 0
                              }
                            />
                            <span>$</span>
                          </div>
                          <label className="mt-4 text-lg text-gray-600 mb-4">
                            {pageTranslation.collected_text || "Collectés"}
                          </label>
                        </div>
                        <AccessControl allowedPermissions={["is_super_user"]}>
                          <HiddenViewContainer show={!hiddenView.hidden}>
                            <div className="text-center">
                              <div className="text-7xl font-extrabold [&>span]:bg-gradient-to-b [&>span]:from-[#1976d2] [&>span]:to-[#B1D4E0] [&>span]:bg-clip-text [&>span]:text-transparent">
                                <CountUp
                                  decimals={2}
                                  end={
                                    transactionsSummary
                                      ? transactionsSummary.surcharges_total
                                      : 0
                                  }
                                />
                                <span>$</span>
                              </div>
                              <label className="mt-4 text-lg text-gray-600 mb-4">
                                {pageTranslation.surcharge_text || "Surcharges"}
                              </label>
                            </div>
                          </HiddenViewContainer>
                        </AccessControl>
                      </div>
                    </div>
                  )}
                </LoadingContainer>
              </div>
            </div>
          </div>
          <div className="container-white mt-4 space-x-4">
            <CountSummary
              label={pageTranslation.locations_summary || "Emplacement(s)"}
              endpoint="getLocationCount"
            />
            <CountSummary
              label={pageTranslation.campaigns_summary || "Campagne(s)"}
              endpoint="getCampaignCount"
            />
            <CountSummary label={"Kiosk(s)"} endpoint="getBorneCount" />
          </div>
          <div className="container-white mt-4 [&>div]:h-[400px] [&>div]:w-[450px] justify-evenly">
            <div>
              <ChartComponent
                title={pageTranslation.campaign_filter_desc || "Campagnes"}
                data={campaignsChartData}
                pageTranslation={pageTranslation}
              />
            </div>
            <div>
              <ChartComponent
                title="Kiosk"
                data={kiosksChartData}
                pageTranslation={pageTranslation}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
