import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CancelButton = ({ label, disabled }) => {
  const navigate = useNavigate();
  return (
    <Button
      disabled={disabled}
      color="error"
      variant="outlined"
      onClick={() => navigate(-1)}
    >
      {label}
    </Button>
  );
};

export default CancelButton;
