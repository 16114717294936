import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { sortStringNumberWithCommas } from "../../DataTableUtils/DataTableUtils";
import useWindowSize from "../../hooks/useWindowSize";

const DataTable = ({ columns, rows, showDetails, filters, loading }) => {
  const [sorting, setSorting] = useState({ field: "", order: "asc" });
  const { width, height } = useWindowSize();

  const handleSort = (field) => {
    const sortOrder = sorting.order === "asc" ? "desc" : "asc";
    setSorting({ field, order: sortOrder });
  };

  const sortRows = () => {
    if (!sorting.field) return rows;

    const column = columns.find((col) => col.field === sorting.field);
    if (!column) return rows;

    const sortedRows = [...rows].sort((a, b) => {
      const fieldA = a[sorting.field];
      const fieldB = b[sorting.field];

      if (column.numberSort) {
        // Use sortStringNumberWithCommas for numberSort columns
        const result = sortStringNumberWithCommas(a, b, sorting.field);
        return sorting.order === "asc" ? result : -result;
      } else {
        // Ensure fieldA and fieldB are strings before using localeCompare
        const strA = fieldA != null ? fieldA.toString() : "";
        const strB = fieldB != null ? fieldB.toString() : "";

        return sorting.order === "asc"
          ? strA.localeCompare(strB)
          : strB.localeCompare(strA);
      }
    });

    return sortedRows;
  };

  const customizedColumns = columns.map((col) => ({
    ...col,
    flex: 1,
    renderHeader: () => {
      const isSortedAsc =
        sorting.field === col.field && sorting.order === "asc";
      const isSortedDesc =
        sorting.field === col.field && sorting.order === "desc";
      const hasRows = rows.length > 0; // Check if there are any rows

      return (
        <div className={"flex flex-col items-start w-full"}>
          <div className={"flex items-center border-0"}>
            <span>{col.headerName}</span>
            <div className="relative h-8">
              {col.sortable && hasRows && (
                <>
                  <ArrowDropUpIcon
                    onClick={() => handleSort(col.field)}
                    className={`absolute cursor-pointer top-0 left-0 ${
                      isSortedAsc ? "text-black" : "text-gray-300"
                    }`}
                  />
                  <ArrowDropDownIcon
                    onClick={() => handleSort(col.field)}
                    className={`absolute cursor-pointer top-2.5 left-0 ${
                      isSortedDesc ? "text-black" : "text-gray-300"
                    }`}
                  />
                </>
              )}
            </div>
          </div>
          <div className={"mt-1 w-full"}>{filters[col.field]}</div>
        </div>
      );
    },
  }));

  return (
    <div
      className="container-white-no-padding min-w-0"
      style={{ width: "100%" }}
    >
      <DataGrid
        key={width}
        rows={sortRows()}
        columns={customizedColumns}
        disableAutosize={false}
        disableColumnMenu
        disableColumnSorting
        density="compact"
        onRowClick={showDetails}
        loading={loading}
        autoHeight
        getCellClassName={(params) =>
          sorting.field === params.field ? "sorted-column" : ""
        }
        sx={{
          "&, [class^=MuiDataGrid]": {
            border: "0",
          },
          "& .MuiDataGrid-main": {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnSeparator, & .MuiDataGrid-columnSeparator--sideRight":
            {
              display: "none",
            },
          "& .MuiDataGrid-columnHeader": {
            fontSize: "14px",
            fontWeight: "bold",
            minHeight: 90,
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader--last": {
            borderRight: 0,
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            flex: "1",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            flex: "1",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "14px",
            border: "0",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-cell:last-child": {
            borderRight: 0,
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
              backgroundColor: "#ffffff !important", // Force white color for even rows
            },
            "&:hover": {
              backgroundColor: "#f0f0f0 !important",
            },
            filter: loading ? "blur(5px)" : "none",
            cursor: "pointer",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add border below column headers
          },
        }}
      />
    </div>
  );
};

export default DataTable;
