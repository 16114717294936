import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";

const NoElements = (props) => {
  return (
    <div className="flex flex-1">
      {props.elements === null ||
      props.elements === undefined ||
      props?.elements.length <= 0 ? (
        <div className="flex flex-1 text-lg items-center font-semibold flex-col">
          {props.noElementsText || "Aucune donnée à afficher"}
          <IconButton onClick={props.onRefresh}>
            <RefreshIcon fontSize={"medium"} />
          </IconButton>
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};

export default NoElements;
