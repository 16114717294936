import { useState, useEffect, useReducer, useRef } from "react";
import {
  Tabs,
  Tab,
  Box,
  List,
  ListItemText,
  Divider,
  ListItemButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import api_endpoints from "../../api";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import FormController from "../../widgets/FormController/FormController";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const Translations = () => {
  const { setShowSuccessAlert, setMessageSuccessAlert } = useSuccessAlert();

  // keep a reference of the full object of locales data
  let localesList = useRef([]);

  const [selectedTab, setSelectedTab] = useState(0);

  // shows pages that can have translations
  const [pages, setPages] = useState([]);

  // general loading state for status of fetching pages translations
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  // know which page was selected by the user
  const [selectedPage, setSelectedPage] = useState(null);

  // loading state to know status of fetching data for the selected page
  const [loadingPageData, setLoadingPageData] = useState(true);
  const [loadingPageDataError, setLoadingPageDataError] = useState(false);

  // states telling us if  translations have been fetched for a selected locale or not
  const [loadingPageTranslations, setLoadingPageTranslation] = useState(true);
  const [loadingPageTranslationsError, setLoadingPageTranslationError] =
    useState(false);

  // contains a list of locales so the user can select one
  const [localesNameList, setLocalesNameList] = useState([]);

  // contains data of the selected locale
  const [selectedLocale, setSelectedLocale] = useState(null);

  // used as reference for the user to know what fields mean with an example of the words employed
  const [referencePageTranslation, setReferencePageTranslation] =
    useState(null);

  // get the page translation data, such as the field and its text for the selected locale
  const [pageTranslations, setPageTranslations] = useState(null);

  // form that will be used to store and change values of the edited translations
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchPagesTranslations();
  }, [selectedTab]);

  useEffect(() => {
    if (selectedPage && Object.keys(selectedPage).length > 0) {
      setLoadingPageData(true);
      fetchLocales();
      fetchReferencePageTranslations();

      if (selectedLocale) {
        fetchPageTranslations();
      }
    }
  }, [selectedPage]);

  useEffect(() => {
    if (selectedLocale) fetchPageTranslations();
  }, [selectedLocale]);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const fetchPagesTranslations = async () => {
    setLoading(true);
    setLoadingError(false);

    // by default fetches pages for web
    let platform = "web";

    // if selected tab is for the kiosk app
    if (selectedTab === 1) {
      platform = "kiosk";
    }

    try {
      const response = await api_endpoints.getPages(platform);

      if (response.status === 200) {
        setLoading(false);
        setPages(response.data);
      }
    } catch (e) {
      setLoadingError(true);
    }
  };

  const fetchLocales = async () => {
    try {
      const response = await api_endpoints.getAllLocales();

      if (response.status === 200) {
        localesList.current = response.data.locales;
        // set the state to an array of locale names only and not an object of the response
        setLocalesNameList(
          response.data.locales.map((locale) => locale.locale)
        );
      }
    } catch (e) {
      setLoadingPageDataError(true);
    }
  };

  const fetchReferencePageTranslations = async () => {
    try {
      const response = await api_endpoints.getPageTranslation(
        "en_US",
        selectedPage.page_name,
        "kiosk"
      );

      if (response.status === 200) {
        setReferencePageTranslation(response.data);
      }
    } catch (e) {
      setLoadingPageDataError(true);
    }

    setLoadingPageData(false);
  };

  const getLocaleFromName = () => {
    if (localesList.current) {
      for (const locale of localesList.current) {
        if (locale.locale === selectedLocale) return locale;
      }
    }

    return null;
  };

  const fetchPageTranslations = async () => {
    const locale = getLocaleFromName();

    try {
      if (!locale) throw new Error("Locale code not found");

      const response = await api_endpoints.getPageTranslation(
        locale.locale_abbreviation,
        selectedPage.page_name,
        "kiosk"
      );

      if (response.status === 200) {
        setPageTranslations(response.data);
      }

      // hold the temporary form data that will be inserted into the state
      const tempForm = {};

      // set the form data with default values if present
      for (const field of selectedPage.texts) {
        if (response.data?.translations && response.data?.translations[field])
          tempForm[field] = response.data?.translations[field];
        else tempForm[field] = "";
      }

      setFormData({ ...tempForm });
    } catch (e) {
      setLoadingPageTranslationError(true);
    }

    setLoadingPageTranslation(false);
  };

  const handleSubmit = async (e, formValues) => {
    e.preventDefault();

    try {
      const locale = getLocaleFromName(selectedLocale);

      const response = await api_endpoints.editTranslations(
        selectedPage._id,
        locale._id,
        formValues
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        fetchPageTranslations();
      }
    } catch (e) {}
  };

  return (
    <div className="container-main">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Web app"></Tab>
          <Tab label="Kiosk app"></Tab>
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <LoadingContainer loading={loading} error={loadingError}>
          En développement
        </LoadingContainer>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <LoadingContainer loading={loading} error={loadingError}>
          <div className="flex flex-1">
            <List className="flex w-1/5 flex-col">
              {pages.map((page) => (
                <ListItemButton
                  key={page._id}
                  onClick={() => setSelectedPage(page)}
                  selected={page.page_name === selectedPage?.page_name}
                >
                  <ListItemText
                    primary={page.page_name}
                    secondary={page.page_description}
                  />
                </ListItemButton>
              ))}
            </List>
            <Divider flexItem orientation="vertical" />
            {selectedPage ? (
              <LoadingContainer
                loading={loadingPageData}
                error={loadingPageDataError}
              >
                <div className="flex flex-1 m-2 [&>div]:flex [&>div]:flex-1 [&>div]:flex-col [&>div]:space-y-4">
                  <div>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      options={localesNameList}
                      onChange={(event, newValue) => {
                        setSelectedLocale(newValue);
                      }}
                      value={selectedLocale}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sélectionner une langue"
                        />
                      )}
                    />
                    {!selectedLocale ? (
                      <div>
                        Sélectionner une langue pour affecter la traduction
                      </div>
                    ) : (
                      <div>
                        <LoadingContainer
                          loading={loadingPageTranslations}
                          error={loadingPageTranslationsError}
                        >
                          <Divider sx={{ marginBottom: 4 }} />
                          <FormController initialValues={{ ...formData }}>
                            {({ value, handleChange, getFormFields }) => (
                              <form
                                className="flex flex-1 space-y-8 flex-col"
                                onSubmit={(e) =>
                                  handleSubmit(e, getFormFields())
                                }
                              >
                                {referencePageTranslation &&
                                  referencePageTranslation?.page?.texts?.map(
                                    (text, index) => {
                                      return (
                                        <TextField
                                          fullWidth
                                          label={`Champ ${text}`}
                                          key={text + index + value}
                                          placeholder={`Champ ${text}`}
                                          size="small"
                                          name={text}
                                          value={value[text] || ""}
                                          onChange={handleChange}
                                          helperText={
                                            <span className="italic ">
                                              Text en anglais :{" "}
                                              <span className="font-semibold">
                                                {
                                                  referencePageTranslation
                                                    .translations[text]
                                                }
                                              </span>
                                            </span>
                                          }
                                        />
                                      );
                                    }
                                  )}
                                <div className="flex flex-1 mt-2 justify-end space-x-4">
                                  <CancelButton
                                    disabled={loading}
                                    label="Annuler"
                                  />
                                  <ButtonLoading
                                    loading={loading}
                                    variant="contained"
                                    type="submit"
                                  >
                                    Sauvegarder
                                  </ButtonLoading>
                                </div>
                              </form>
                            )}
                          </FormController>
                        </LoadingContainer>
                      </div>
                    )}
                  </div>
                </div>
              </LoadingContainer>
            ) : (
              <div className="flex flex-1 m-4">
                Sélectionner une page pour visionner ses champs
              </div>
            )}
          </div>
          <Divider orientation="vertical" />
        </LoadingContainer>
      </TabPanel>
    </div>
  );
};
