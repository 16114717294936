import AuthContext from "../../context/AuthContext/AuthContext";
import { useContext } from "react";

const checkPermissions = (userContext, allowedPermissions) => {
  if (allowedPermissions.length === 0) return true;

  return allowedPermissions.some(
    (permission) => userContext.authContext.accessToken[permission] === true
  );
};

const AccessControl = ({ allowedPermissions, children }) => {
  const authContext = useContext(AuthContext);
  const permitted = checkPermissions(authContext, allowedPermissions);

  if (permitted) return children;

  return <></>;
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permission: [],
};

export default AccessControl;
