import PageTitle from "../PageTitle/PageTitle";

const PageWrapper = ({pageComponent, canReturn, title}) => {
    return (
        <div className={"container-main"}>
            <PageTitle title={title} canReturn={canReturn} />
            {pageComponent}
        </div>
    )
}

export default PageWrapper;