import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { useTheme } from "@mui/material";

const ColorPicker = ({ defaultColor, onColorChange, label }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({ r: "241", g: "112", b: "19", a: "1" });
  const theme = useTheme();

  useEffect(() => {
    if (defaultColor && defaultColor.length > 0) {
      const colorValues = defaultColor
        .replace("rgba(", "")
        .replace(")", "")
        .split(",");

      setColor({
        r: colorValues[0],
        g: colorValues[1],
        b: colorValues[2],
        a: colorValues[3],
      });
    }
  }, []);

  useEffect(() => {
    onColorChange(`rgba(${color.r},${color.g},${color.b},${color.a})`);
  }, [color]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.rgb);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "100%",
        height: "auto",
        borderRadius: "5px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div className="cursor-pointer">
      <div
        className={`w-full border border-[${theme.palette.grey["500"]}] flex space-x-2 p-2 rounded-md items-center`}
        onClick={handleClick}
      >
        <div className="flex rounded-xl border w-[50px] h-[35px]">
          <div className="h-full w-full" style={styles.color}></div>
        </div>
        <div>{label ? label : "Choisir une couleur"}</div>
        <div className="flex text-gray-500 text-xs">{`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}</div>
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
