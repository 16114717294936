import React, { useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AuthContext from "../../context/AuthContext/AuthContext";
import {useHiddenView} from "../../context/HiddenViewContext/HiddenViewContext";

export default function HiddenViewSwitch() {
  const authContext = useContext(AuthContext);
  const hiddenVueContext = useHiddenView();
  const isOrganizationSelected =
    authContext.authContext.accessToken.organization_id !== null &&
    authContext.authContext.accessToken.organization_id !== undefined;
  
  const toggleChange = () => {
    hiddenVueContext.setHidden(!hiddenVueContext.hidden);
  }

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Vue super utilisateur</Typography>
        <Tooltip 
            disableFocusListener={isOrganizationSelected} 
            disableHoverListener={isOrganizationSelected} 
            disableTouchListener={isOrganizationSelected} 
            title={
          <div style={{fontSize: 12}}>
            Une organisation doit être sélectionnée
          </div>}
        >
          <div>
            <Switch onChange={toggleChange} disabled={!isOrganizationSelected}/>  
          </div>
        </Tooltip>
        <Typography>Vue client</Typography>
      </Stack>
    </FormGroup>
  );
}
