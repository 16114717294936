import * as echarts from "echarts";
import { numberWithCommas } from "../utils";

export const kioskPingStatusChartOption = {
  tooltip: {
    trigger: "axis",
    backgroundColor: "white",
    textStyle: {
      fontSize: 16,
    },
  },
  title: {
    text: "Statut de la borne",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
  },
  textStyle: {
    color: "gray",
    fontFamily: "Poppins",
  },
  xAxis: {
    type: "category",
    showGrid: true,
    splitLine: {
      show: true,
    },
  },
  yAxis: {
    type: "category",
    inverse: true,
    showGrid: true,
    splitLine: {
      show: true,
    },
  },
  grid: {
    left: "55px",
    right: "10px",
    top: "45px",
    bottom: "25px",
  },
  series: [
    {
      data: [],
      type: "line",
    },
  ],
};
