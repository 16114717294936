import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import styles from "./Login.module.css";
import { useEffect, useState } from "react";
import { useLoading } from "../../context/LoadingContext/LoadingContext";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import { IconButton, TextField } from "@mui/material";
import React from "react";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading.jsx";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PAGES = {
  mainpage: "mainpage",
  otppage: "otppage",
  resetpage: "resetpage",
};

let email = "";

const ForgotPassword = () => {
  const [currentPage, setCurrentPage] = useState(PAGES.mainpage);

  return (
    <div className="flex flex-1 justify-center items-center">
      {currentPage === PAGES.mainpage ? (
        <MainPage setCurrentPage={setCurrentPage} />
      ) : currentPage === PAGES.otppage ? (
        <OTPConfirmation setCurrentPage={setCurrentPage} />
      ) : currentPage === PAGES.resetpage ? (
        <ResetPasswordPage setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
    </div>
  );
};

const MainPage = (props) => {
  const { loading, setLoading } = useLoading();
  const [emailInput, setEmailInput] = useState("");

  const emailExists = async () => {
    let response = await api_endpoints.emailExists(emailInput);

    if (response.status === 200) {
      let otpResponse = await api_endpoints.sendOTPForgotPassword(emailInput);

      if (otpResponse.status === 200) {
        email = emailInput;
        props.setCurrentPage(PAGES.otppage);
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  return (
    <div className="flex flex-1 flex-col space-y-4">
      <div className="text-2xl">Mot de passe oublié</div>
      <div>Entrez le courriel du compte avec le mot de passe oublié</div>
      <div className={styles.body_container}>
        <TextField
          fullWidth
          label="Courriel"
          placeholder="votre@courriel.com"
          onInput={handleEmailChange}
          value={emailInput}
        />
      </div>
      <div className={styles.button_container}>
        <ButtonLoading
          onClick={emailExists}
          loading={loading}
          variant="contained"
        >
          Réinitialiser
        </ButtonLoading>
      </div>
    </div>
  );
};

const ResetPasswordPage = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordNotMatching, setPasswordNotMatching] = useState(false);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const toggleVisibleConfirmPassword = () => {
    setVisibleConfirmPassword(!visibleConfirmPassword);
  };

  const toggleVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const resetPassword = async () => {
    setLoading(true);
    if (!isConfirmPasswordSame()) {
      setPasswordNotMatching(true);
      return;
    }

    try {
      let response = await api_endpoints.resetPassword(email, password);

      if (response.status === 202) {
        setShowSuccessAlert(true);
        setMessageSuccessAlert(response.data);
        navigate("/");
      }
    } catch (e) {}

    setLoading(false);
  };

  const isConfirmPasswordSame = () => {
    return password === confirmPassword ? true : false;
  };

  return (
    <div className={styles.title_container}>
      <div className="text-2xl">Réinitialiser le mot de passe</div>
      <label>
        Entrez votre nouveau mot de passe qui doit être différent de l'ancien.
      </label>
      <SlDivider />
      <div className="flex flex-1 flex-col space-y-4">
        <TextField
          fullWidth
          label="Nouveau mot de passe"
          placeholder="Nouveau mot de passe"
          type={visiblePassword ? "test" : "password"}
          value={password}
          onInput={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleVisiblePassword}>
                {visiblePassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Confirmez le nouveau mot de passe"
          placeholder="Confirmez le nouveau mot de passe"
          type={visibleConfirmPassword ? "test" : "password"}
          value={confirmPassword}
          onInput={(e) => setConfirmPassword(e.target.value)}
          helperText={
            passwordNotMatching ? "Les mots de passe ne concordent pas" : ""
          }
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleVisibleConfirmPassword}>
                {visibleConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
      </div>
      <div className={styles.button_container}>
        <ButtonLoading
          loading={loading}
          onClick={resetPassword}
          variant="contained"
        >
          Réinitialiser
        </ButtonLoading>
      </div>
    </div>
  );
};

const OTPConfirmation = (props) => {
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    let container = document.getElementById("otp_input_container");

    container.oninput = (e) => {
      const target = e.target;
      const val = target.value;

      if (isNaN(val)) {
        target.value = "";
        return;
      }

      if (val != "") {
        const next = target.nextElementSibling;
        if (next) {
          next.focus();
        }
      }
    };

    container.onkeyup = (e) => {
      const target = e.target;
      const key = e.key.toLowerCase();

      if (key == "backspace" || key == "delete") {
        target.value = "";
        const prev = target.previousElementSibling;
        if (prev) {
          prev.focus();
        }
        return;
      }
    };
  }, []);

  const verifyOTP = async () => {
    let otpValue = "";
    let inputs = document
      .getElementById("otp_input_container")
      .querySelectorAll("INPUT");

    for (let input of inputs) {
      otpValue += input.value.toString();
    }

    let response = await api_endpoints.verifyOTPForgotPassword(email, otpValue);

    if (response.status === 200) {
      props.setCurrentPage(PAGES.resetpage);
    }
  };

  return (
    <div className={styles.title_container}>
      <div className="text-2xl">OTP</div>
      <label>Un code OTP à 6 chiffres a été envoyé à votre courriel</label>
      <div id="otp_input_container" className={styles.otp_input_container}>
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
        <input type="text" inputMode="numeric" maxLength="1" placeholder="0" />
      </div>
      <div className={styles.button_container}>
        <ButtonLoading
          variant="contained"
          loading={loading}
          onClick={verifyOTP}
        >
          Vérifier
        </ButtonLoading>
      </div>
    </div>
  );
};

export default ForgotPassword;
