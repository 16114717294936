import { useEffect, useState } from "react";
import { useLocale } from "../../context/LocaleContext/LocaleContext";
import api_endpoints from "../../api";

// Higher order component for fetching page translation
const pageTranslation = (BaseComponent, pageName = "") => {
  return (props) => {
    const locale = useLocale();
    const [pageTranslation, setPageTranslation] = useState({});

    useEffect(() => {
      if (locale.locale) fetchPageTranslation();
    }, [locale.locale]);

    const fetchPageTranslation = async () => {
      try {
        const response = await api_endpoints.getPageTranslation(
          locale.locale.locale_abbreviation,
          pageName,
          "web"
        );

        if (
          response.status === 200 &&
          Object.keys(response.data.translations).length > 0
        )
          setPageTranslation(response.data.translations);
        else setPageTranslation({});
      } catch (e) {
        setPageTranslation({});
        console.error("Page translation could not be fetched");
      }
    };

    return <BaseComponent {...props} pageTranslation={pageTranslation} />;
  };
};

export default pageTranslation;
