const TabWrapper = (props) => {
  return (
    <div
      // copy props object but ignore index attribute since it is not a part of the Button component
      {...(({ index, value, ...o }) => o)(props)}
      style={{ display: props.index !== props.value ? "none" : "" }}
    >
      {props.children}
    </div>
  );
};

export default TabWrapper;
