import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import dayjs from "dayjs";

const CustomDatePicker = ({ value, onChange, clearFilter }) => {
  const isDateSelected = value !== null && value !== undefined && value !== "";

  const handleClearFilter = (event) => {
    event.stopPropagation(); // Stop the event from propagating to the MuiDatePicker
    clearFilter();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
      <MuiDatePicker
        value={value || null}
        onChange={onChange}
        {...(isDateSelected && {
          slots: {
            openPickerIcon: () => (
              <CloseOutlinedIcon onClick={handleClearFilter} />
            ),
          },
        })}
        renderInput={(params) => <TextField {...params} />}
        sx={{
          "& .MuiInputBase-root": {
            height: "41px",
          },
          "& .MuiInputBase-input": {
            padding: "10px", // Adjust padding to align text properly
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
