import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, TextField, Grid, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const PersonalizedPeriodPopup = ({
  modalOpen,
  handleCloseModal,
  handleSubmitModal,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const today = dayjs(new Date());

  // Function to handle date selection changes
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Dialog maxWidth="md" open={modalOpen}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Sélectionnez Date Personnalisée
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ marginBottom: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="fr-ca"
            >
              <DatePicker
                label="Date de début"
                value={startDate}
                onChange={setStartDate}
                maxDate={today}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
            <Box mt={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr-ca"
              >
                <TimePicker
                  label="Heure de début"
                  value={startTime}
                  onChange={setStartTime}
                  renderInput={(props) => <TextField {...props} />}
                  ampm={true}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ marginBottom: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="fr-ca"
            >
              <DatePicker
                label="Date de fin"
                value={endDate}
                onChange={setEndDate}
                maxDate={today}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
            <Box mt={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr-ca"
              >
                <TimePicker
                  label="Heure de fin"
                  value={endTime}
                  onChange={setEndTime}
                  renderInput={(props) => <TextField {...props} />}
                  ampm={true}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() =>
                handleSubmitModal(startDate, endDate, startTime, endTime)
              }
              fullWidth
              variant="contained"
              color="success"
            >
              OK
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleCloseModal}
              fullWidth
              variant="contained"
              color="error"
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PersonalizedPeriodPopup;
