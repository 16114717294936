import React, { createContext, useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";

const SuccessAlertContext = createContext({
  showSuccessAlert: false,
  setShowSuccessAlert: null,
  messageSuccessAlert: "",
  setMessageSuccessAlert: null,
  showWarningAlert: false,
  setShowWarningAlert: null,
  messageWarningAlert: "",
  setMessageWarningAlert: null,
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="outlined"
      className="bg-white"
      sx={{ backgroundColor: "white" }}
      {...props}
    />
  );
});

export function SuccessAlertProvider({ children }) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [messageSuccessAlert, setMessageSuccessAlert] = useState("");
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [messageWarningAlert, setMessageWarningAlert] = useState("");

  const value = {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
    showWarningAlert,
    setShowWarningAlert,
    messageWarningAlert,
    setMessageWarningAlert,
  };

  const resetAlert = () => {
    setShowSuccessAlert(false);
    setMessageSuccessAlert("");
  };

  const resetWarningAlert = () => {
    setShowWarningAlert(false);
    setMessageWarningAlert("");
  };

  return (
    <SuccessAlertContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSuccessAlert}
        autoHideDuration={4500}
        onClose={resetAlert}
      >
        <Alert onClose={resetAlert} severity="success">
          <AlertTitle>Succès</AlertTitle>
          {messageSuccessAlert
            ? messageSuccessAlert
            : "La demande a été traité avec succès"}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showWarningAlert}
        autoHideDuration={4500}
        onClose={resetWarningAlert}
      >
        <Alert onClose={resetWarningAlert} severity="warning">
          <AlertTitle>Avertissement</AlertTitle>
          {messageWarningAlert
            ? messageWarningAlert
            : "Il y a un avertissement à traiter"}
        </Alert>
      </Snackbar>
      {children}
    </SuccessAlertContext.Provider>
  );
}

export function useSuccessAlert() {
  const context = useContext(SuccessAlertContext);
  if (!context) {
    throw new Error("useSuccessAlert must be used within SuccessAlertProvider");
  }
  return context;
}
