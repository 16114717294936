import styles from "./ViewCampaign.module.css";
import PageTitle from "../../widgets/PageTitle/PageTitle";
import { getPageFromName, PAGES } from "../../route_utils";
import { useEffect, useState, useRef, useContext } from "react";
import { viewCampaignChartOption } from "../../EchartsUtils/ViewCampaignChartOptionBar";
import { tableStyles } from "../../TableStyle";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import api_endpoints from "../../api";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AuthContext from "../../context/AuthContext/AuthContext";
import ReactECharts from "echarts-for-react";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { Button, TextField, Chip } from "@mui/material";
import "dayjs/locale/fr-ca";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { dateTimeReadable, numberWithCommas } from "../../utils";

const ViewCampaign = () => {
  const echartRef = useRef(null);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [transactions, setTransaction] = useState([]);
  const [addCashDialogOpen, setAddCashDialogOpen] = useState(false);
  const [bornesInfoDialogOpen, setBornesInfoDialogOpen] = useState(false);
  const [loadingDataDialog, setLoadingDataDialog] = useState(true);
  const [loadingErrorDataDialog, setLoadingErrorDataDialog] = useState(false);
  const [cashAmountInput, setCashAmountInput] = useState("");
  const [transactionsStats, setTransactionsStats] = useState({});
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [cashTableData, setCashTableData] = useState([]);
  const [bornesData, setBornesData] = useState([]);
  const [selectedBorneData, setSelectedBorneData] = useState({});
  const [locationsDialog, setLocationsDialog] = useState([]);
  const [donationDate, setDonationDate] = useState("");
  const [locationId, setLocationId] = useState("");
  const [totalCollected, setTotalCollected] = useState(0);
  const [dateFilter, setDateFilter] = useState(dayjs());

  const columns = [
    {
      name: "Montant comptant",
      selector: (row) => row.cashAmount,
      sortable: true,
    },
    {
      name: "Inséré le",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Inséré par",
      selector: (row) => row.inserted_by,
      sortable: true,
    },
    {
      name: "Date du don",
      selector: (row) => row.date_donated,
      sortable: true,
    },
  ];

  const columnsBornes = [
    {
      name: "Nom kiosk",
      selector: (row) => row.kiosk_name,
      sortable: true,
    },
    {
      name: "Montant récolté",
      selector: (row) => row.amount_collected,
      sortable: true,
    },
  ];

  // useEffect(() => {
  //   fetchCampaignData();
  // }, []);

  useEffect(() => {
    if (dateFilter) fetchCampaignData();
  }, [dateFilter]);

  useEffect(() => {
    fetchCampaignTransactionsData();
  }, [campaignData]);

  const fetchCampaignData = async () => {
    setLoading(true);

    try {
      const date = `${dateFilter.get("year")}-${(dateFilter.get("month") + 1)
        .toString()
        .padStart(2, "0")}-${dateFilter
        .get("date")
        .toString()
        .padStart(2, "0")}`;

      let response = await api_endpoints.getCampaign(state.campaign_id, date);

      if (response.status === 200) {
        setCampaignData(response.data.campaign);
        setBornesData(response.data.bornes);

        mapBorneData(response.data.bornes);
        mapCashDataTable(response.data.cashTransactions);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }
  };

  const fetchCampaignTransactionsData = async () => {
    const date = `${dateFilter.get("year")}-${(dateFilter.get("month") + 1)
      .toString()
      .padStart(2, "0")}-${dateFilter.get("date").toString().padStart(2, "0")}`;

    let response = await api_endpoints.getCampaignsTransacitons(
      state.campaign_id,
      date
    );

    let responseTransactionsStats =
      await api_endpoints.getCampaignsTransacitonsStats(
        state.campaign_id,
        date
      );

    if (response.status === 200) {
      getTotalCollected(response.data);
      mapChartData(response.data);
      onResizeEvent();
    }

    if (responseTransactionsStats.status === 200) {
      setTransactionsStats(responseTransactionsStats.data);
    }

    setLoading(false);
  };

  const getTotalCollected = (transactions) => {
    let total = 0;

    for (const [key, value] of Object.entries(transactions)) {
      for (let transaction of transactions[key]) {
        total += transaction.total;
      }
    }

    setTotalCollected(total);
  };

  const mapChartData = (transactions) => {
    let dataCash = [];
    let dataTransactions = [];
    let option = { ...viewCampaignChartOption };

    for (const cardTransaction of transactions.transactions) {
      dataTransactions.push([cardTransaction._id.date, cardTransaction.total]);
    }

    for (const cashTransaction of transactions.cashDonations) {
      dataCash.push([cashTransaction._id.date, cashTransaction.total]);
    }

    option.title.text = `Récoltes pour ${campaignData?.name}`;
    option.series[0].data = dataTransactions;
    option.series[1].data = dataCash;

    setChartData({ ...option });
  };

  const mapBorneData = (bornes) => {
    let data = [];

    for (let borne of bornes) {
      data.push({
        id: borne._id,
        kiosk_name: borne.doc.borne.name,
        location: borne.doc.borne.location.address,
        amount_collected: borne.total,
      });
    }

    setTransactionData(data);
  };

  const mapCashDataTable = (tableData) => {
    let data = [];

    for (let transaction of tableData) {
      data.push({
        cashAmount: transaction.transaction.amount,
        date: dateTimeReadable(transaction.transaction.created_at),
        date_donated: dateTimeReadable(transaction.date_of_donation),
        inserted_by: `${transaction.user.last_name} ${transaction.user.first_name}`,
      });
    }

    setCashTableData(data);
  };

  const onResizeEvent = () => {
    window.addEventListener("resize", () => {
      echartRef?.current?.getEchartsInstance().resize();
    });
  };

  const handleAddCashToCampaign = async (e) => {
    e.preventDefault();

    let response = await api_endpoints.addCashToCampaign(
      state.campaign_id,
      cashAmountInput,
      authContext.authContext.accessToken.id,
      authContext.authContext.accessToken.organization_id,
      donationDate
    );

    if (response.status === 200) {
      setAddCashDialogOpen(false);
      setCashAmountInput("");
      setMessageSuccessAlert(response.data);
      setShowSuccessAlert(true);
      fetchCampaignData();
    }
  };

  const handleArchiveCampaign = async () => {
    let response = await api_endpoints.setArchiveCampaignStatus(
      campaignData._id,
      !campaignData?.is_archived
    );

    if (response.status === 200) {
      setMessageSuccessAlert(response.data);
      setShowSuccessAlert(true);
      fetchCampaignData();
    }
  };

  const handleDeleteCampaign = async () => {
    let response = await api_endpoints.deleteCampaign(state.campaign_id);

    if (response.status === 200) {
      setMessageSuccessAlert(response.data);
      setShowSuccessAlert(true);
      navigate(getPageFromName("Campagnes").path);
    }
  };

  const selectBorne = (e) => {
    for (let borne of bornesData) {
      if (borne._id.borne === e.id.borne) {
        setSelectedBorneData(borne);
        setBornesInfoDialogOpen(true);
        break;
      }
    }
  };

  const handleDateFilterChange = (value) => {
    setDateFilter(value);
  };

  return (
    <LoadingContainer loading={loading} error={loadingError}>
      <Dialog
        fullWidth
        open={addCashDialogOpen}
        onClose={() => setAddCashDialogOpen(false)}
      >
        <form onSubmit={handleAddCashToCampaign}>
          <DialogTitle>Ajouter Comptant</DialogTitle>
          <DialogContent>
            <div className="m-2 flex flex-1 flex-col space-y-4">
              <TextField
                placeholder="Insérer le montant"
                label="Insérer le montant"
                type="number"
                value={cashAmountInput}
                onChange={(e) => setCashAmountInput(e.target.value)}
                required
              />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr-ca"
              >
                <DatePicker
                  value={dayjs(donationDate)}
                  onChange={(value) => setDonationDate(value.$d.getTime())}
                  label="Date de donations"
                  sx={{ width: "100%" }}
                  maxDate={dayjs(new Date())}
                  slotProps={{
                    textField: {
                      error: false,
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SlDialog
        label="Information Kiosk"
        open={bornesInfoDialogOpen}
        onSlAfterHide={() => setBornesInfoDialogOpen(false)}
        style={{ "--width": "60vw" }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div>
              <span style={{ fontSize: 20 }}>Kiosk </span> -{" "}
              {selectedBorneData?.doc?.borne.name}
            </div>
            <div>
              <span style={{ fontSize: 20 }}>Situé au </span>
              {selectedBorneData?.doc?.borne.location.address}
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div>
              <span style={{ fontSize: 20 }}>Fonds récoltés par la borne</span>{" "}
              : {selectedBorneData?.total}
            </div>
            <div>
              <span style={{ fontSize: 20 }}>Emplacement</span> :{" "}
              {selectedBorneData?.doc?.borne.location.location_name}
            </div>
          </div>
        </div>
      </SlDialog>
      <div className="mb-4 flex flex-1 justify-end">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
          <DatePicker
            value={dateFilter}
            onChange={(value) => handleDateFilterChange(value)}
            label="Date de transactions"
            disableFuture={true}
          />
        </LocalizationProvider>
      </div>
      <div className={styles.graph_container}>
        <ReactECharts
          ref={echartRef}
          option={chartData}
          style={{ height: "100%", width: "100%" }}
          lazyUpdate={true}
          notMerge={true}
          autoResize={true}
        />
      </div>
      <div className={styles.campaign_buttons}>
        <div></div>
        <div className="space-x-4">
          <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
            <Button
              variant="contained"
              className={styles.add_cash_button}
              onClick={() => {
                setAddCashDialogOpen(true);
              }}
              disabled={campaignData?.is_archived}
            >
              Ajouter Comptant
            </Button>
            <Button
              variant="outlined"
              className={styles.edit_campaign_button}
              onClick={() => {
                navigate(getPageFromName("Modifier la campagne").path, {
                  state: { campaign_id: state.campaign_id },
                });
              }}
              disabled={campaignData?.is_archived}
            >
              Modifier Campagne
            </Button>
            <Button
              color="error"
              variant="outlined"
              className={styles.delete_campaign_button}
              onClick={handleDeleteCampaign}
              disabled={campaignData?.is_archived}
            >
              Effacer Campagne
            </Button>
          </AccessControl>
        </div>
      </div>
      <div className="container-white space-x-20">
        <div className="flex flex-1 flex-col space-y-4 [&_label]:text-gray-500 [&>div]:justify-between [&>div]:flex">
          <div className="text-xl font-semibold">
            {campaignData?.name}{" "}
            {campaignData?.enabled ? (
              <Chip color="success" label="Active"></Chip>
            ) : campaignData?.is_archived ? (
              <Chip color="error" label="Archivé"></Chip>
            ) : (
              <Chip color="error" label="Désactivé"></Chip>
            )}
          </div>
          <div>
            Emplacement : <label>{campaignData?.location?.location_name}</label>
          </div>
          <div>
            Durée :{" "}
            <label>
              {campaignData?.has_duration
                ? `${campaignData.date_start.split("T")[0]} à ${
                    campaignData.date_end.split("T")[0]
                  }`
                : "Illimité"}
            </label>
          </div>
          <div>
            Objectif :{" "}
            <label>
              {campaignData?.has_objective
                ? `${numberWithCommas(campaignData.objective)} $`
                : "Aucun objectif"}{" "}
            </label>
          </div>
          <div>
            Fonds récoltés :{" "}
            <label>
              {numberWithCommas(totalCollected) || Number(0).toFixed(2)} $
            </label>
          </div>
          <div className="flex flex-1 flex-col">
            Description :
            <p className="text-gray-500 mt-4">{campaignData?.description}</p>
          </div>
        </div>
        <div className="flex flex-1 flex-col space-y-4 [&_label]:text-gray-500 [&>div]:justify-between [&>div]:flex">
          <div>
            Nombre de dons argent comptant:{" "}
            <label>{transactionsStats?.cashDonationsStats?.count || 0}</label>
          </div>
          <div>
            Nombre de dons Débit / Crédit:{" "}
            <label>{transactionsStats?.transactionsStats?.count || 0}</label>
          </div>
          <div>
            Don moyen argent comptant:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.cashDonationsStats?.avg)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            Don moyen Débit / Crédit:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.transactionsStats?.avg)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            Don maxmial argent comptant:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.cashDonationsStats?.max)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            Don maximal Débit / Crédit:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.transactionsStats?.max)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
        </div>
      </div>
      <div className={styles.campaign_buttons}>
        <div></div>
        <div>
          <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
            <Button
              color="warning"
              variant="contained"
              className={styles.add_to_archive_button}
              onClick={handleArchiveCampaign}
            >
              {campaignData?.is_archived
                ? "Retirer des archives"
                : "Ajouter aux archives"}
            </Button>
          </AccessControl>
        </div>
      </div>
      <div className="flex flex-1 flex-col space-y-4">
        <div className="flex flex-1 flex-col">
          <div style={{ fontSize: 20 }}>Dons comptant</div>
          <DataTable
            highlightOnHover
            customStyles={tableStyles}
            columns={columns}
            data={cashTableData}
            pagination
            pointerOnHover
          />
        </div>
        <div className="flex flex-1 flex-col">
          <div style={{ fontSize: 20 }}>Details des Kiosk</div>
          <DataTable
            highlightOnHover
            customStyles={tableStyles}
            columns={columnsBornes}
            data={transactionData}
            pagination
            pointerOnHover
            onRowClicked={selectBorne}
          />
        </div>
      </div>
    </LoadingContainer>
  );
};

export default ViewCampaign;
