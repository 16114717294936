import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";

const ButtonLoading = (props) => {
  return (
    <Button
      // copy props object but ignore loading attribute since it is not a part of the Button component
      {...(({ loading, ...o }) => o)(props)}
      disabled={props.loading}
    >
      {props.loading ? (
        <CircularProgress thickness={5} size="1.655rem" />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default ButtonLoading;
