import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const CustomSelect = ({
  value,
  onChange,
  options,
  label = "",
  required = false,
}) => {
  return (
    <FormControl fullWidth required={required}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        size="small"
        variant="outlined"
        displayEmpty
        fullWidth
        label={label}
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
