import React, { Component } from "react";
import AuthContext from "./AuthContext";
import jwt_decode from "jwt-decode";
import api_endpoints from "../../api";

/**
 * Authentication Provider permettant d'authentifier ou effacer son jeton d'authentification.
 * Avec ce jeton, on peut obtenir l'information de la base de données qu'il/elle peut accéder et
 * de s'assurer de vérifier que chaque requêtes sont fait par un utilisateur valide.
 */
class AuthProvider extends Component {
  constructor(props) {
    super(props);

    // Chercher le jeton d'accès et de rafraîchissement dans le stockage du navigateur.
    let accessToken = localStorage.getItem("accessTokenRootforgood");

    this.state = {
      accessToken: accessToken
        ? {
            ...jwt_decode(accessToken),
            token: JSON.parse(accessToken),
          }
        : null,
      loggedIn: accessToken ? true : false,
      loginUser: this.loginUser,
      logoutUser: this.logoutUser,
      isTokenExpired: this.isTokenExpired,
      setSuperUserOrganization: this.setSuperUserOrganization,
    };
  }

  componentDidMount = () => {
    if (this.isTokenExpired()) {
      this.logoutUser();
    }
  };

  setAccessTokens = (data) => {
    this.setState({ accessToken: data });
  };

  setSuperUserOrganization = (organization_id) => {
    if (this.state.accessToken.is_super_user)
      this.setState({
        accessToken: {
          ...this.state.accessToken,
          organization_id: organization_id,
        },
      });
  };

  setLoggedIn = (value) => {
    this.setState({ loggedIn: value });
  };

  requestToken = (email, password, rememberMe) => {
    return api_endpoints.login(email, password, rememberMe);
  };

  /**
   * Sauvegarder les tokens dans les variables states et aussi dans le localStorage.
   * Le token dans le logal storage sera encrypté par le back-end Django
   * @param {*} token contient le access token et le refresh token de l'utilisateur que
   * l'on veut sauvegarder.
   */
  saveToken = (token) => {
    localStorage.setItem("accessTokenRootforgood", JSON.stringify(token));
    this.setAccessTokens(token, () => {this.setLoggedIn(true);});
  };

  /**
   * Mettre les variables states de token à null et retirer les tokens
   * du localStorage
   */
  deleteToken = () => {
    this.setAccessTokens(null);
    localStorage.removeItem("accessTokenRootforgood");
  };

  loginUser = async (email, password, rememberMe) => {
    let response = null;

    try {
      response = await this.requestToken(email, password, rememberMe);
      this.saveToken(response.data);
    } catch {
      this.deleteToken();
    }

    return response ? true : false;
  };

  logoutUser = () => {
    this.deleteToken();
    this.redirectUserRootPath()
  };
  
  redirectUserRootPath = () => {
    window.location.href = "/";
  }

  isTokenExpired = () => {
    if (this.state.accessToken) {
      return Date.now() <= this.state.accessToken.exp * 1000 ? false : true;
    }
    
    return false;
  };

  render() {
    return (
      <AuthContext.Provider value={{ authContext: this.state }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
