import styles from "./Login.module.css";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext/AuthContext";
import { Checkbox, IconButton, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loading, setLoading] = useState(false)

  const toggleVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const handleLoginRequest = async (e) => {
    e?.preventDefault();

    let response = await authContext.authContext.loginUser(
      email,
      password,
      rememberMe
    );

    if (response) {
      setLoading(false)
      window.location.reload();
    }
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="flex flex-1 justify-center items-center flex-col">
      <div className="flex w-[300px] mb-2">
        <img
          className="object-contain"
          src={process.env.PUBLIC_URL + "/Kiosk-Donations.svg"}
        />
      </div>
      <div className={styles.title_container}>
        <label className="text-2xl">Connexion</label>
        <SlDivider />
        <form onSubmit={handleLoginRequest} autoComplete="off">
          <div>
            <TextField
              fullWidth
              label="Courriel"
              placeholder="Votre@courriel.com"
              onInput={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              onInput={(e) => setPassword(e.target.value)}
              label="Mot de passe"
              type={visiblePassword ? "test" : "password"}
              placeholder="Mot de passe"
              sx={{ marginTop: 3 }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={toggleVisiblePassword}>
                    {visiblePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className="flex flex-1 items-center justify-between mt-10">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleRememberMe} />}
                label="Se souvenir de moi"
              />
            </FormGroup>
            <a href="forgotpassword" className="text-[#0000EE]">
              Mot de passe oublié ?
            </a>
          </div>
          <div className={styles.button_container}>
            <ButtonLoading variant="contained" type="submit" loading={loading}>
              Connexion
            </ButtonLoading>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
