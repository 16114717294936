import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";

const FailAlertContext = createContext({
  showFailAlert: false,
  setShowFailAlert: null,
  messageFailAlert: "",
  setMessageFailAlert: null,
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function FailAlertProvider({ children }) {
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [messageFailAlert, setMessageFailAlert] = useState("");

  const resetAlert = () => {
    setShowFailAlert(false);
    setMessageFailAlert("");
  };

  const showAlert = () => {
    setShowFailAlert(true);
  };

  const setMessage = (message) => {
    setMessageFailAlert(message);
  };

  const setMessageAndShow = (message) => {
    setMessage(message);
    showAlert();
  };

  const value = {
    showAlert,
    setMessage,
    setMessageAndShow,
  };

  return (
    <FailAlertContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showFailAlert}
        onClose={resetAlert}
      >
        <Alert onClose={resetAlert} severity="error" variant="filled">
          <AlertTitle>Erreur</AlertTitle>
          {messageFailAlert ? messageFailAlert : "Erreur non géré"}
        </Alert>
      </Snackbar>
      {children}
    </FailAlertContext.Provider>
  );
}

export function useFailAlert() {
  const context = useContext(FailAlertContext);
  if (!context) {
    throw new Error("useFailAlert must be used within FailAlertProvider");
  }
  return context;
}
