import styles from "./ModifyUser.module.css";
import SlSwitch from "@shoelace-style/shoelace/dist/react/switch";
import SlTab from "@shoelace-style/shoelace/dist/react/tab";
import SlTabGroup from "@shoelace-style/shoelace/dist/react/tab-group";
import SlTabPanel from "@shoelace-style/shoelace/dist/react/tab-panel";
import SlDetails from "@shoelace-style/shoelace/dist/react/details";

const ModifyUser = () => {
  return (
    <>
      <div>Level Of Access: Assign Permissions</div>
      <div className={styles.user_details_container}>
        <div className={styles.user_info}>
          <div>
            <div>
              <span>User's Role:</span> Manager
            </div>
          </div>
          <div>
            <span>Name:</span> Jackson Khan
          </div>
          <div>
            <span>Email Address:</span> jacksonkha319@hotmail.com
          </div>
        </div>
        <div className={styles.user_permissions_container}>
          <SlTabGroup>
            <SlTab slot="nav" panel="documents">
              Documents
            </SlTab>
            <SlTab slot="nav" panel="administration">
              Administration
            </SlTab>
            <SlTab slot="nav" panel="dashboard">
              Dashboard
            </SlTab>
            <SlTab slot="nav" panel="expenditure">
              Expenditure
            </SlTab>
            <SlTab slot="nav" panel="constructionProjects">
              Construction Projects
            </SlTab>
            <SlTab slot="nav" panel="cloud">
              Cloud
            </SlTab>

            <SlTabPanel name="documents">
              <SlDetails summary="Offers">
                <div className={styles.switch_categories_container}>
                  <div className={styles.switch_categories_title}>Overview</div>
                  <div>
                    <SlSwitch>Create Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Download Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Assign User</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Add Comments</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Add Comments</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Add Comments</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Add Comments</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Upload Files</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>View History</SlSwitch>
                  </div>
                </div>
                <div className={styles.switch_categories_container}>
                  <div className={styles.switch_categories_title}>Draft</div>
                  <div>
                    <SlSwitch>Book Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Offers</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Offers</SlSwitch>
                  </div>
                </div>
                <div className={styles.switch_categories_container}>
                  <div className={styles.switch_categories_title}>Template</div>
                  <div>
                    <SlSwitch>Create Templates</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Templates</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Delete Templates</SlSwitch>
                  </div>
                </div>
              </SlDetails>
              <SlDetails summary="Products">
                <div className={styles.switch_categories_container}>
                  <div>
                    <SlSwitch>Create Products</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Edit Products</SlSwitch>
                  </div>
                  <div>
                    <SlSwitch>Delete Products</SlSwitch>
                  </div>
                </div>
              </SlDetails>
            </SlTabPanel>
          </SlTabGroup>
        </div>
      </div>
    </>
  );
};

export default ModifyUser;
