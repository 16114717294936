import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ReactECharts from "echarts-for-react";
import { kioskPingStatusChartOption } from "../../EchartsUtils/KioskPingStatusChartOption";

const KioskStatusChart = ({
  kioskName,
  status,
  showDateTime,
  kioskActiveStatus,
}) => {
  const echartRef = useRef(null);

  useEffect(() => {
    const myChart = echartRef.current?.getEchartsInstance();

    let seriesData = [];
    let dataAxis = [];

    for (let i = 0; i < status.length; i++) {
      const datetime = new Date(status[i].timestamp);
      let time;

      if (showDateTime) {
        time = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${datetime
          .getDate()
          .toString()
          .padStart(2, "0")} ${datetime
          .getHours()
          .toString()
          .padStart(2, "0")}:${datetime
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      } else {
        time = `${datetime.getHours().toString().padStart(2, "0")}:${datetime
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      }

      dataAxis.push(time);

      if (
        i > 0 &&
        datetime.getTime() - new Date(status[i - 1].timestamp).getTime() >
          120000
      ) {
        seriesData.push([time, "Inactif"]);
      } else {
        seriesData.push([time, "Actif"]);
      }
    }

    let option = { ...kioskPingStatusChartOption };

    option.xAxis.min = showDateTime ? null : "00:00";
    option.xAxis.max = showDateTime ? null : "23:59";
    option.xAxis.type = "category";
    option.grid = {
      bottom: 80, // Increase bottom margin to accommodate slider
    };
    option.title = {
      text: kioskName,
      left: "center",
    };
    option.dataZoom = [
      {
        type: "inside",
        start: 0,
        end: 100,
      },
      {
        type: "slider",
        start: 0,
        end: 100,
        bottom: 10, // Position the slider below the chart
      },
    ];
    option.series[0].data = seriesData;

    // Handle click event to enable zooming
    const handleClick = (event) => {
      const pointInPixel = [event.offsetX, event.offsetY];
      if (myChart.containPixel("grid", pointInPixel)) {
        const pointInGrid = myChart.convertFromPixel("grid", pointInPixel);
        const xIndex = Math.round(pointInGrid[0]);
        const zoomSize = 6;

        myChart.dispatchAction({
          type: "dataZoom",
          startValue: dataAxis[Math.max(xIndex - zoomSize / 2, 0)],
          endValue:
            dataAxis[Math.min(xIndex + zoomSize / 2, dataAxis.length - 1)],
        });
      }
    };

    myChart.getZr().on("click", handleClick);

    echartRef.current?.getEchartsInstance().setOption(option);

    // Cleanup function to remove event listener
    return () => {
      myChart.getZr()?.off("click", handleClick);
    };
  }, [kioskName, status, showDateTime, kioskActiveStatus]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          textAlign: "right",
          position: "absolute",
          width: "100%",
          right: "10px",
          zIndex: 10,
          padding: "4px",
          borderRadius: "5px",
        }}
      >
        {kioskActiveStatus}
      </div>
      <div style={{ height: "400px", paddingTop: "4px" }}>
        <ReactECharts ref={echartRef} option={{}} notMerge={true} />
      </div>
      <Button
        onClick={() => {
          const myChart = echartRef.current.getEchartsInstance();
          myChart.dispatchAction({ type: "dataZoom", start: 0, end: 100 });
        }}
      >
        Réinitialiser le zoom
      </Button>
    </div>
  );
};

export default KioskStatusChart;
