import styles from "./CreateKiosk.module.css";
import { getPageFromName, PAGES } from "../../route_utils";
import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  TextField,
  Divider,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormGroup,
  Checkbox,
  List,
  ListItem,
} from "@mui/material";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr-ca";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import ImageInputV2 from "../../widgets/ImageInput/ImageInputV2";
import ColorPicker from "../../widgets/ColorPicker/ColorPicker";

const CreateKiosk = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingCampaignsError, setLoadingCampaignsError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [image, setImage] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  const [formData, setFormData] = useState({
    organization: authContext.authContext.accessToken.organization_id,
    location_id: "",
    name: "",
    clover_device_id: "",
    terminal_position: "right",
  });

  const [lightingModeData, setLightingModedata] = useState({
    is_active: false,
    lighting_mode_type: 0,
    date_start: null,
    date_end: null,
    time_start: null,
    time_end: null,
    time_start_monday: null,
    time_end_monday: null,
    time_start_tuesday: null,
    time_end_tuesday: null,
    time_start_wednesday: null,
    time_end_wednesday: null,
    time_start_thursday: null,
    time_end_thursday: null,
    time_start_friday: null,
    time_end_friday: null,
    time_start_saturday: null,
    time_end_saturday: null,
    time_start_sunday: null,
    time_end_sunday: null,
    is_monday_selected: false,
    is_tuesday_selected: false,
    is_wednesday_selected: false,
    is_thursday_selected: false,
    is_friday_selected: false,
    is_saturday_selected: false,
    is_sunday_selected: false,
    campaign_id: "",
    color_code: "",
  });

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    setLoading(true);

    try {
      let response = await api_endpoints.getLocations(
        authContext.authContext.accessToken.organization_id,
        null
      );

      if (response.status === 200 && response.data.length > 0) {
        if (!formData.location_id) {
          changeFormData("location_id", response.data[0]._id);
        }
        setLocations(response.data);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }

    setLoading(false);
  };

  const fetchCampaigns = async () => {
    setLoadingCampaigns(true);

    try {
      const response = await api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id,
        null,
        true,
        null,
        null,
        false
      );

      if (response.status === 200 && response.data.length > 0) {
        if (!lightingModeData.campaign_id) {
          changeLightingModeData("campaign_id", response.data[0]._id);
        }

        setCampaigns(response.data);
      }
    } catch (e) {
      console.error(e);
      setLoadingCampaignsError(true);
    }

    setLoadingCampaigns(false);
  };

  const setRequestData = () => {
    const dataToSend = {
      ...formData,
      lighting_mode_data: { ...lightingModeData },
    };
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(dataToSend)) {
      if (key === "lighting_mode_data") {
        for (const [innerKey, innerValue] of Object.entries(dataToSend[key])) {
          data.append(`${key}[${innerKey}]`, innerValue);
        }
      } else {
        data.append(key, value);
      }
    }

    return data;
  };

  const handleCreateKiosk = async (e) => {
    e?.preventDefault();
    setSendingData(true);

    try {
      let response = await api_endpoints.createKiosk(setRequestData());

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        setSendingData(false);
        navigate(getPageFromName("Kiosks").path);
      }
    } catch (e) {}

    setSendingData(false);
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const changeLightingModeData = (key, value) => {
    setLightingModedata((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleLocationSelect = (e) => {
    setFormData({ ...formData, location_id: e.target.value });
  };

  const handleCampaignSelect = (e) => {
    setLightingModedata({ ...lightingModeData, campaign_id: e.target.value });
  };

  const handleLightingModeToggle = () => {
    changeLightingModeData("is_active", !lightingModeData.is_active);

    if (!lightingModeData.is_active) {
      fetchCampaigns();
    }
  };

  const handleLightingModeType = (e) => {
    changeLightingModeData("lighting_mode_type", e.target.value);
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  return (
    <AccessControl allowedPermissions={["is_super_user"]}>
      <LoadingContainer loading={loading} error={loadingError}>
        <form
          id="publish_campaign_form"
          onSubmit={handleCreateKiosk}
          autoComplete="off"
          className="mt-4"
        >
          <div className={styles.details_container}>
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col space-y-4">
                <div>
                  {pageTranslation.create_kiosk_header || "Détails du Kiosk"}
                </div>
                <FormControl fullWidth>
                  <InputLabel required>
                    {pageTranslation.create_kiosk_location ||
                      "Choisir un emplacement"}
                  </InputLabel>
                  <Select
                    value={formData.location_id}
                    label={
                      pageTranslation.create_kiosk_location ||
                      "Choisir un emplacement"
                    }
                    onChange={handleLocationSelect}
                    fullWidth
                    required
                  >
                    {locations.map((location) => (
                      <MenuItem key={location._id} value={location._id}>
                        <div className="flex flex-col">
                          <label className="text-lg font-semibold">
                            {location.location_name}
                          </label>
                          <label>{location.address}</label>
                          <label>{location.phone}</label>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="flex w-full space-x-4">
                  <TextField
                    fullWidth
                    placeholder={pageTranslation.create_kiosk_name || "Name"}
                    label={pageTranslation.create_kiosk_name || "Name"}
                    required
                    value={formData.name}
                    onInput={(e) => changeFormData("name", e.target.value)}
                  />
                  <FormControl fullWidth>
                    <InputLabel>Position du terminal de paiement</InputLabel>
                    <Select
                      label="Position du terminal clover"
                      value={formData.terminal_position || "right"}
                      defaultValue="right"
                      onChange={(e) => {
                        changeFormData("terminal_position", e.target.value);
                      }}
                    >
                      <MenuItem value="right">Droite</MenuItem>
                      <MenuItem value="down">En bas</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  placeholder={
                    pageTranslation.create_kiosk_cloverterminal ||
                    "Numéro de série du terminal Clover"
                  }
                  label={
                    pageTranslation.create_kiosk_cloverterminal ||
                    "Numéro de série du terminal Clover"
                  }
                  required
                  value={formData.clover_device_id}
                  onInput={(e) =>
                    changeFormData("clover_device_id", e.target.value)
                  }
                />
                <Divider />
                <div>
                  {pageTranslation.create_kiosk_quickmode || "Mode rapide"}
                  <Switch
                    checked={lightingModeData.is_active}
                    onChange={handleLightingModeToggle}
                  />
                </div>
                {lightingModeData.is_active ? (
                  <LoadingContainer
                    loading={loadingCampaigns}
                    error={loadingCampaignsError}
                  >
                    <div className="flex flex-1 space-x-4">
                      <div className="flex flex-1">
                        <FormControl fullWidth>
                          <InputLabel required>Choisir une campagne</InputLabel>
                          <Select
                            value={lightingModeData.campaign_id}
                            label="Choisir une campagne"
                            onChange={handleCampaignSelect}
                            fullWidth
                            required
                          >
                            {campaigns.map((campaign) => (
                              <MenuItem key={campaign._id} value={campaign._id}>
                                {campaign.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error>
                            Assurez-vous que la campagne séléctionné possède des
                            montants préconfigurés. Sinon, les paiements ne
                            pourront être fait à partir du kiosk.
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col pt-5">
                      <FormLabel>
                        Choisir une couleur pour les boutons des montants
                        préconfigurés
                      </FormLabel>
                      <ColorPicker
                        width={"100px"}
                        height={"30px"}
                        onColorChange={(color) => {
                          changeLightingModeData("color_code", color);
                        }}
                      />
                    </div>

                    <div className="flex flex-1 flex-col pt-5">
                      <FormLabel>Image du fond d'écran du Kiosk</FormLabel>
                      <ImageInputV2
                        defaultImage={null}
                        image={image}
                        onChange={handleSelectImage}
                        removeImage={removeImage}
                        placeholder={"Ajouter une image pour le mode rapide"}
                        helperText={
                          "L'image peut avoir une taille maximale de 1 MB et les formats suivants sont acceptés : .png .gif .jpeg"
                        }
                      />
                    </div>

                    <div className="flex w-full flex-col pt-5">
                      <div>
                        <FormControl>
                          <FormLabel>Type de mode rapide : </FormLabel>
                          <RadioGroup
                            row
                            value={lightingModeData.lighting_mode_type}
                            onChange={handleLightingModeType}
                          >
                            <FormControlLabel
                              value={0}
                              label="Temporaire"
                              control={<Radio />}
                            />
                            <FormControlLabel
                              value={1}
                              label="Réccurent"
                              control={<Radio />}
                            />
                            <FormControlLabel
                              value={2}
                              label="Permanent"
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fr-ca"
                    >
                      <div className="flex flex-1">
                        {lightingModeData.lighting_mode_type == 0 ? (
                          <div className="flex flex-col space-y-4 items-center">
                            <div className="flex flex-1">
                              Avec le mode temporaire, votre Kiosk activera sa
                              fonctionnalité du mode rapide entre les dates
                              choisies dans les heures sélectionnées.
                            </div>
                            <div className="flex flex-1 space-x-4 items-center">
                              <DatePicker
                                required
                                disablePast
                                label="Date début"
                                onChange={(value) =>
                                  changeLightingModeData("date_start", value)
                                }
                                slotProps={{ textField: { required: true } }}
                                maxDate={lightingModeData.date_end}
                              />
                              <div>-</div>
                              <DatePicker
                                disablePast
                                label="Date fin"
                                onChange={(value) =>
                                  changeLightingModeData("date_end", value)
                                }
                                slotProps={{ textField: { required: true } }}
                                minDate={lightingModeData.date_start}
                              />
                            </div>
                            <div className="flex flex-1 space-x-4 items-center">
                              <TimePicker
                                required
                                label="Heure début"
                                onChange={(value) =>
                                  changeLightingModeData("time_start", value)
                                }
                                slotProps={{ textField: { required: true } }}
                                maxTime={lightingModeData.time_end}
                              />{" "}
                              <div>-</div>
                              <TimePicker
                                required
                                label="Heure fin"
                                onChange={(value) =>
                                  changeLightingModeData("time_end", value)
                                }
                                slotProps={{ textField: { required: true } }}
                                minTime={lightingModeData.time_start}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {lightingModeData.lighting_mode_type == 1 ? (
                          <>
                            <div className="flex flex-1 space-x-4 w-full flex-col">
                              <div>
                                Avec ce mode, le kiosk activera sa
                                fonctionnalité rapide aux journées et heures
                                choisies.
                              </div>
                              <FormGroup className="w-full">
                                <List fullWidth>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_sunday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_sunday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Dimanche"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_sunday",
                                            value
                                          )
                                        }
                                        value={
                                          lightingModeData.time_start_sunday
                                        }
                                        maxTime={
                                          lightingModeData.time_end_sunday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_sunday",
                                            value
                                          )
                                        }
                                        value={lightingModeData.time_end_sunday}
                                        minTime={
                                          lightingModeData.time_start_sunday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_monday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_monday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Lundi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_monday",
                                            value
                                          )
                                        }
                                        value={
                                          lightingModeData.time_start_monday
                                        }
                                        maxTime={
                                          lightingModeData.time_end_monday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_monday",
                                            value
                                          )
                                        }
                                        value={lightingModeData.time_end_monday}
                                        minTime={
                                          lightingModeData.time_start_monday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_tuesday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_tuesday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Mardi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        value={
                                          lightingModeData.time_start_tuesday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_tuesday",
                                            value
                                          )
                                        }
                                        maxTime={
                                          lightingModeData.time_end_tuesday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        value={
                                          lightingModeData.time_end_tuesday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_tuesday",
                                            value
                                          )
                                        }
                                        minTime={
                                          lightingModeData.time_start_tuesday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_wednsday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_wednsday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Mercredi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        value={
                                          lightingModeData.time_start_wednsday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_wednsday",
                                            value
                                          )
                                        }
                                        maxTime={
                                          lightingModeData.time_end_wednsday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        value={
                                          lightingModeData.time_end_wednsday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_wednsday",
                                            value
                                          )
                                        }
                                        minTime={
                                          lightingModeData.time_start_wednsday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_thursday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_thursday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Jeudi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        value={
                                          lightingModeData.time_start_thursday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_thursday",
                                            value
                                          )
                                        }
                                        maxTime={
                                          lightingModeData.time_end_thursday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        value={
                                          lightingModeData.time_end_thursday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_thursday",
                                            value
                                          )
                                        }
                                        minTime={
                                          lightingModeData.time_start_thursday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_friday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_friday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Vendredi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        value={
                                          lightingModeData.time_start_friday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_friday",
                                            value
                                          )
                                        }
                                        maxTime={
                                          lightingModeData.time_end_friday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        value={lightingModeData.time_end_friday}
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_friday",
                                            value
                                          )
                                        }
                                        minTime={
                                          lightingModeData.time_start_friday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            lightingModeData.is_saturday_selected
                                          }
                                          onChange={(e) =>
                                            changeLightingModeData(
                                              "is_saturday_selected",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Samedi"
                                      className="w-1/6"
                                    />
                                    <div className="flex flex-1 items-center space-x-4">
                                      <TimePicker
                                        value={
                                          lightingModeData.time_start_saturday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_start_saturday",
                                            value
                                          )
                                        }
                                        maxTime={
                                          lightingModeData.time_end_saturday
                                        }
                                      />{" "}
                                      <div>-</div>{" "}
                                      <TimePicker
                                        value={
                                          lightingModeData.time_end_saturday
                                        }
                                        onChange={(value) =>
                                          changeLightingModeData(
                                            "time_end_saturday",
                                            value
                                          )
                                        }
                                        minTime={
                                          lightingModeData.time_start_saturday
                                        }
                                      />
                                    </div>
                                  </ListItem>
                                </List>
                              </FormGroup>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {lightingModeData.lighting_mode_type == 2 ? (
                          <>
                            <div className="flex flex-1">
                              Ce type de mode rapide indique que le kiosk sera
                              en mode rapide en permanence. Seule la campagne
                              sélectionnée sera affichée avec ses montants
                              préconfigurés.
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </LocalizationProvider>
                  </LoadingContainer>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-4 mt-4">
            <CancelButton
              disabled={sendingData}
              label={pageTranslation.create_kiosk_cancel_button || "Annuler"}
            />
            <ButtonLoading
              loading={sendingData}
              variant="contained"
              type="submit"
            >
              {pageTranslation.create_kiosk_create_button || "Créer"}
            </ButtonLoading>
          </div>
        </form>
      </LoadingContainer>
    </AccessControl>
  );
};

export default CreateKiosk;
