import styles from "./CreateUser.module.css";
import { getPageFromName } from "../../route_utils";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import { TextField } from "@mui/material";
import api_endpoints from "../../api";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import FormController from "../../widgets/FormController/FormController";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CancelButton from "../../widgets/CancelButton/CancelButton";

const CreateUser = () => {
  const authContext = useContext(AuthContext);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [OTPCode, setOTPCode] = useState("");

  const handleCreateUser = async (e, formData) => {
    e.preventDefault();

    setLoading(true);
    try {
      let response = await api_endpoints.createUser(
        formData.first_name,
        formData.last_name,
        formData.email,
        formData.organization_id,
        formData.kiosk_code
      );
      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        let otpResponse = await api_endpoints.createUserOTP(
          formData.email,
          true,
          false,
          false
        );
        if (otpResponse.status === 200) {
          setOTPCode(otpResponse.data.otp);
          setModalOpen(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate(getPageFromName("Super utilisateurs").path);
  };

  return (
    <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { maxHeight: 435 } }}
        maxWidth="sm"
        open={modalOpen}
      >
        <DialogTitle>Code OTP de l'utilisateur</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-col space-y-4">
            <h1>
              Veuillez communiquer avec votre utilisateur le code OTP suivant
            </h1>
            <strong className="w-full text-center text-7xl font-extrabold">
              {OTPCode}
            </strong>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <FormController
        initialValues={{
          organization_id: authContext.authContext.accessToken.organization_id,
          first_name: "",
          last_name: "",
          email: "",
          kiosk_code: "",
        }}
      >
        {({ value, handleChange, getFormFields }) => (
          <>
            <div className={`${styles.details_container} mt-4`}>
              <form
                id="publish_campaign_form"
                onSubmit={(e) => handleCreateUser(e, getFormFields())}
              >
                <div className={styles.inputs_container}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      placeholder="Nom"
                      label="Nom"
                      required
                      name="first_name"
                      value={value.first_name}
                      onChange={handleChange}
                    />
                    <TextField
                      placeholder="Prénom"
                      label="Prénom"
                      style={{ marginLeft: 30 }}
                      required
                      fullWidth
                      name="last_name"
                      value={value.last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      placeholder="Courriel"
                      label="Courriel"
                      required
                      fullWidth
                      name="email"
                      value={value.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      placeholder="NIP Kiosk"
                      label="NIP Kiosk"
                      required
                      helperText="Doit être d'une longueur de 6 chiffres et contenir que des chiffres"
                      name="kiosk_code"
                      value={value.kiosk_code}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 6,
                        minLength: 6,
                        pattern: "^[0-9]*$",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <CancelButton disabled={loading} label="Annuler" />
              <ButtonLoading
                variant="contained"
                type="submit"
                form="publish_campaign_form"
                loading={loading}
              >
                Créer
              </ButtonLoading>
            </div>
          </>
        )}
      </FormController>
    </AccessControl>
  );
};

export default CreateUser;
