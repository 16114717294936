import { useState, useContext } from "react";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AuthContext from "../../context/AuthContext/AuthContext";
import {
  dateTimeReadableSimple,
  numberWithCommas,
  validEmail,
  formatDateTime,
} from "../../utils";
import dayjs from "dayjs";
import {
  MenuItem,
  Select,
  Chip,
  Button,
  TextField,
  Divider,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import OutboxIcon from "@mui/icons-material/Outbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";

const ManageTransactions = ({ pageTranslation }) => {
  const authContext = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [campaignsMenuOption, setCampaignsMenuOption] = useState([]);
  const [rows, setRows] = useState([]);
  const [kiosksMenuOption, setKiosksMenuOption] = useState([]);
  const [emplacementData, setEmplacementData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [emailError, setEmailError] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    amount: "",
    location_name: "",
    donor_email: "",
    campaignName: "",
    kioskName: "",
    emplacement: "",
    donor: "",
    campagne: "",
    kiosk: "",
    date: dayjs(new Date()),
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });
  const [formData, setFormData] = useState({
    exportFormat: "csv",
    recurring: false,
    recurringDay: 1,
    timeOfDay: dayjs().startOf("day"),
    endDate: null,
    emails: [],
  });

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setTableFilter({
      ...tableFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const columns = [
    {
      field: "amount",
      headerName: pageTranslation.transactions_table_amount || "Montant",
      sortable: true,
      numberSort: true,
    },
    {
      field: "location_name",
      headerName: pageTranslation.transactions_table_location || "Emplacement",
      sortable: true,
      numberSort: false,
    },
    {
      field: "donor_email",
      headerName: pageTranslation.transactions_table_donorEmail || "Donateur",
      sortable: true,
      numberSort: false,
    },
    {
      field: "campaignName",
      headerName: pageTranslation.transactions_table_campaign || "Campagne",
      sortable: true,
      numberSort: false,
    },
    {
      field: "kioskName",
      headerName: pageTranslation.transactions_table_kiosk || "Kiosk",
      sortable: true,
      numberSort: false,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    amount: (
      <CustomTextField
        value={tableFilter.amount || ""}
        onChange={(e) => changeTableFilter("amount", e.target.value)}
        clearFilter={() => clearTableFilter("amount")}
      />
    ),
    location_name: (
      <Select
        size="small"
        variant="outlined"
        className="w-60"
        sx={{ width: "100%" }}
        fullWidth
        displayEmpty
        value={tableFilter.location_name}
        onChange={(e) => {
          changeTableFilter("location_name", e.target.value);
        }}
      >
        <MenuItem
          value={""}
          style={{
            display: emplacementData.length <= 1 ? "none" : "block",
          }}
        >
          Tout
        </MenuItem>
        {emplacementData.map((emplacement, index) => (
          <MenuItem
            key={emplacement._id}
            value={emplacement._id}
            selected={emplacementData.length === 1 && index === 0}
          >
            <div className="flex items-center justify-between">
              <span className="mr-2.5">{emplacement.location_name}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    ),
    donor_email: (
      <CustomTextField
        value={tableFilter.donor_email || ""}
        onChange={(e) => changeTableFilter("donor_email", e.target.value)}
        clearFilter={() => clearTableFilter("donor_email")}
      />
    ),
    campaignName: (
      <CustomSelect
        value={tableFilter.campaignName}
        onChange={(e) => {
          changeTableFilter("campaignName", e.target.value);
        }}
        options={campaignsMenuOption}
      />
    ),
    kioskName: (
      <CustomSelect
        value={tableFilter.kioskName}
        onChange={(e) => {
          changeTableFilter("kioskName", e.target.value);
        }}
        options={kiosksMenuOption}
      />
    ),
    date: (
      <PredefinedPeriods
        selectedPeriod={selectedPeriod}
        handlePeriodChange={handlePeriodChange}
        setSelectedPeriod={setSelectedPeriod}
        setpersonalizedDates={setpersonalizedDates}
      />
    ),
  };

  const kiosksFilterQuery = useApiClientQuery(
    () =>
      api_endpoints.getKiosks(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [`kiosksFilter_organizationid_${tableFilter.organization_id}`],
    },
    (data) => {
      if (data.length == 0) {
        setKiosksMenuOption([{ value: "", label: "Tout" }]);
        return;
      }

      // Populate kiosksMenuOption
      const options = data.map((kiosk) => ({
        value: kiosk._id,
        label: (
          <div className="flex flex-1 items-center justify-between">
            <span>{kiosk.name}</span>
            <div>
              <Chip
                color={kiosk.is_active ? "success" : "error"}
                label={kiosk.is_active ? "Active" : "Désactivé"}
                size="small"
              />
            </div>
          </div>
        ),
      }));

      // Add "Tout" option at the beginning
      options.unshift({ value: "", label: "Tout" });

      // Update state
      setKiosksMenuOption(options);
    }
  );

  const locationsFilterQuery = useApiClientQuery(
    () =>
      api_endpoints.getLocations(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `locationFilter_organizationid_${tableFilter.organization_id}`,
      ],
    },
    (data) => {
      if (data.length > 0) {
        setEmplacementData(data);
        if (data.length === 1) {
          changeTableFilter("emplacement", data[0]._id);
        }
      }
    }
  );

  const campaignFilterQuery = useApiClientQuery(
    () =>
      api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `campaignFilter_organizationid_${tableFilter.organization_id}`,
      ],
    },
    (data) => {
      const options = data.map((campaign) => ({
        value: campaign._id,
        label: (
          <div className="flex flex-1 items-center justify-between">
            <span>{campaign.name}</span>
            <div>
              <Chip
                color={campaign.enabled ? "success" : "error"}
                label={campaign.enabled ? "Active" : "Désactivé"}
                size="small"
              />
            </div>
          </div>
        ),
      }));

      // Add "Tout" option at the beginning
      options.unshift({ value: "", label: "Tout" });

      // Update state
      setCampaignsMenuOption(options);
    }
  );

  const transactionsQuery = useApiClientQuery(
    () =>
      api_endpoints.getTransactions(
        authContext.authContext.accessToken.organization_id,
        tableFilter.amount,
        tableFilter.location_name,
        tableFilter.donor_email,
        tableFilter.campaignName,
        tableFilter.kioskName,
        formatDateTime(tableFilter.start_date, tableFilter.start_time),
        formatDateTime(tableFilter.end_date, tableFilter.end_time)
      ),
    {
      queryKey: [
        "transactions",
        tableFilter,
        authContext.authContext.accessToken.organization_id,
      ],
    },
    (data) => {
      const formattedData = data.map((transaction) => ({
        id: transaction._id,
        amount: `${numberWithCommas(transaction.amount)}$`,
        campaignName: transaction.campaign?.name,
        location_name: transaction.campaign_location?.location_name,
        location_add: transaction.campaign_location?.address,
        donor_email: transaction.donor?.email,
        kioskName: transaction.cardTransaction.kiosk[0]?.name,
        date: dateTimeReadableSimple(transaction.created_at),
      }));
      setRows(formattedData);
    }
  );

  const isLoading =
    kiosksFilterQuery.isLoading ||
    locationsFilterQuery.isLoading ||
    campaignFilterQuery.isLoading ||
    transactionsQuery.isLoading;

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
    if (emplacementData.length === 1) {
      changeTableFilter("location_name", emplacementData[0]._id);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    // Reset formData when closing the modal
    setFormData({
      exportFormat: "csv",
      recurring: false,
      recurringDay: 1,
      timeOfDay: dayjs().startOf("day"),
      endDate: null,
      emails: [],
    });

    // Reset other modal-related states
    setEmailInput(""); // For non-formData states
    setEmailError("");
    mutation.reset(); //reset error states
    mutationRecurring.reset();
  };

  const handleSuccessfulMutation = (successMessage) => {
    setMessageSuccessAlert(successMessage);
    setShowSuccessAlert(true);
    handleCloseModal();
  };

  const mutation = useApiClientMutation(
    api_endpoints.emailTransactionExport,
    [`export_NonRecurring_Transactions_${tableFilter.organization_id}`],
    (data) => {
      handleSuccessfulMutation(data);
    }
  );

  const mutationRecurring = useApiClientMutation(
    api_endpoints.createRecurringTransactionExport,
    [`export_recurring_transactions_${tableFilter.organization_id}`],
    (data) => {
      handleSuccessfulMutation(data);
    }
  );

  const formatTime = (timeObject) => {
    const time = timeObject.$d; // Extract the Date object
    const formattedTime = `${time.getHours().toString().padStart(2, "0")}:${time
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const commonData = {
      format: formData.exportFormat,
      data: rows,
      emails: Array.isArray(formData.emails)
        ? formData.emails
        : [formData.emails], // Ensure emails is always an array
    };

    // Prepare the data for the recurring scenario
    const recurringData = {
      ...commonData,
      dayOfMonth: formData.recurringDay,
      timeOfDay: formatTime(formData.timeOfDay),
      endDate: formData.endDate,
      filters: tableFilter,
      userId: authContext.authContext.accessToken.id,
      range: selectedPeriod,
    };

    const mutationToUse = formData.recurring ? mutationRecurring : mutation;

    mutationToUse.mutate(formData.recurring ? recurringData : commonData);
  };

  const openModal = async () => {
    setModalOpen(true);
  };

  const handleAddEmail = () => {
    const isValidEmail = validEmail(emailInput);
    if (!isValidEmail) {
      // Handle invalid email (e.g., show an error message)
      setEmailError("Invalid email format");
      return;
    }

    if (emailInput && !formData.emails.includes(emailInput)) {
      changeFormData("emails", [...formData.emails, emailInput]);
      setEmailInput("");
      setEmailError(""); // Clear any previous errors
    } else if (formData.emails.includes(emailInput)) {
      // Handle duplicate email case
      setEmailError("Email already added");
    }
  };

  const handleDeleteEmail = (email) => {
    changeFormData(
      "emails",
      formData.emails.filter((e) => e !== email)
    );
  };

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
    if (emailError) {
      setEmailError("");
    }
  };

  const handleRecurringSwitchChange = (event) => {
    changeFormData("recurring", event.target.checked);
  };

  const handleRecurringDayChange = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 31)) {
      changeFormData("recurringDay", value);
    }
  };

  return (
    <>
      <Dialog
        open={modalOpen} // Use unified modal state
        onClose={handleCloseModal}
        sx={{ "& .MuiDialog-paper": { minHeight: 70, minWidth: 500 } }}
      >
        <DialogTitle>Export Transactions</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} autoComplete="off">
            <LoadingContainer
              loading={mutation.isPending || mutationRecurring.isPending}
              error={mutation.isError || mutationRecurring.isError}
            >
              <div className="flex flex-1 flex-col space-y-4">
                <TextField
                  fullWidth
                  placeholder="Enter Email"
                  label="Email"
                  type="email"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  error={!!emailError} // Show error state if there's an error message
                  helperText={emailError}
                  InputProps={
                    emailInput.length > 0
                      ? {
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={"cursor-pointer"}
                            >
                              <ArrowForwardIcon onClick={handleAddEmail} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
                <div className="flex flex-wrap gap-2">
                  {formData.emails.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleDeleteEmail(email)}
                    />
                  ))}
                </div>
                {formData.emails.length > 0 && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Export Format</FormLabel>
                    <RadioGroup
                      value={formData.exportFormat}
                      onChange={(e) =>
                        changeFormData("exportFormat", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="csv"
                        control={<Radio />}
                        label="CSV"
                      />
                      <FormControlLabel
                        value="xlsx"
                        control={<Radio />}
                        label="XLSX"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                <Divider />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!formData.recurring}
                      onChange={handleRecurringSwitchChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Recurring"
                  disabled={!formData.emails.length}
                />
                {formData.recurring && (
                  <div className="mt-2 space-y-4">
                    <FormControl fullWidth>
                      <TextField
                        label="Day of the Month"
                        type="number"
                        required
                        value={formData.recurringDay}
                        onChange={handleRecurringDayChange}
                        InputProps={{ inputProps: { min: 1, max: 31 } }}
                        helperText={"Specify the day of the month (1-31)."}
                      />
                    </FormControl>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fr-ca"
                    >
                      <TimePicker
                        label="Time"
                        value={formData.timeOfDay}
                        onChange={(newTime) =>
                          changeFormData("timeOfDay", newTime)
                        }
                        slotProps={{
                          textField: { fullWidth: true, required: true },
                        }}
                        ampm={true}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fr-ca"
                    >
                      <DatePicker
                        label="Date de fin"
                        value={formData.endDate}
                        onChange={(newDate) =>
                          changeFormData("endDate", newDate)
                        }
                        minDate={dayjs(new Date())}
                        renderInput={(props) => <TextField {...props} />}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </LoadingContainer>
            <DialogActions>
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseModal} // Correctly close the modal
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !formData.emails.length ||
                  mutation.isPending ||
                  mutationRecurring.isPending
                }
              >
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <div className={"flex justify-end"}>
        <Tooltip title={rows.length === 0 ? "No data to export" : ""} arrow>
          <span>
            <Button
              variant="contained"
              color="primary"
              startIcon={<OutboxIcon />}
              onClick={openModal}
              disabled={rows.length === 0}
            >
              Export
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className="flex flex-1 h-full mt-5 flex-col">
        <DataGrid
          columns={columns}
          rows={rows}
          filters={filters}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ManageTransactions;
