import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageTitle = ({ title, canReturn }) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="page-title">
      {canReturn ? (
        <IconButton onClick={navigateBack}>
          <KeyboardArrowLeft />
        </IconButton>
      ) : (
        <></>
      )}
      {title}
    </div>
  );
};

export default PageTitle;
