import { useEffect, useState, useContext } from "react";
import { numberWithCommas } from "../../utils";
import api_endpoints from "../../api";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import AuthContext from "../../context/AuthContext/AuthContext";

const CountSummary = (props) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorFetching, setErrorFetching] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchDocumentCount();
  }, []);

  const fetchDocumentCount = async () => {
    try {
      let response = await api_endpoints[props.endpoint](
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        setCount(response.data.count);
        setLoading(false);
        setErrorFetching(false);
      }
    } catch (e) {
      setErrorFetching(true);
    }
  };

  return (
    <div className="flex flex-1 border-gray-400 border rounded-2xl flex-col">
      <LoadingContainer loading={loading} error={errorFetching}>
        <div className="ml-5 mt-5 text-lg text-gray-600"></div>
        <div className="flex flex-1 justify-center items-center flex-col">
          <div className="text-7xl font-extrabold">
            <label className="bg-gradient-to-b from-[#1976d2] to-[#B1D4E0] bg-clip-text text-transparent">
              {count ? numberWithCommas(count) : "0"}
            </label>
          </div>
          <div className="mt-4 text-lg text-gray-600 mb-4">{props.label}</div>
        </div>
      </LoadingContainer>
    </div>
  );
};

export default CountSummary;
