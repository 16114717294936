import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";

const LoadingContainer = (props) => {
  if (props.blur) {
    return (
      <>
        {props.error ? (
          <div className="flex flex-1 justify-center items-center h-full w-full">
            <Alert
              className="flex flex-1 justify-center items-center"
              severity="error"
            >
              <AlertTitle>
                Erreur lors de la récupération des données
              </AlertTitle>
              <p>Essayez de rafraîchir la page</p>
            </Alert>
          </div>
        ) : (
          <div className="relative" style={{ minHeight: "100%" }}>
            {props.loading && (
              <div className="absolute inset-0 flex justify-center bg-white z-50">
                <div className="flex mt-10">
                  <CircularProgress />
                  {props.message && <div className="ml-4">{props.message}</div>}
                </div>
              </div>
            )}
            <div className="relative z-10">{props.children}</div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.error ? (
          <div className="flex flex-1 justify-center items-center h-full w-full">
            <Alert
              className="flex flex-1 justify-center items-center"
              severity="error"
            >
              <AlertTitle>
                Erreur lors de la récupération des données
              </AlertTitle>
              <p>Essayez de rafraîchir la page</p>
            </Alert>
          </div>
        ) : props.loading ? (
          <div className="flex flex-1 justify-center items-center h-full w-full flex-col space-y-4">
            <CircularProgress />
            {props.message ? (
              <div className="mr-4">{props.message}</div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          props.children
        )}
      </>
    );
  }
};

export default LoadingContainer;
