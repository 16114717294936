import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./ManageCampaigns.module.css";
import { getPageFromName } from "../../route_utils";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { numberWithCommas } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import axios from "axios";
import {NavigationButton} from "../../widgets/NavigationButton/NavigationButton";

const ManageCampaigns = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [fetchingTableData, setFetchingTableData] = useState(false);
  const [completionData, setCompletionData] = useState([]);
  const [modalStatusOpen, setmodalStatusOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const [tableFilter, setTableFilter] = useState({
    campaignName: "",
    campaignCreator: "",
    donationAmount: "",
    status: "",
    acceptCash: "",
    is_archived: "",
  });

  const columns = [
    {
      field: "campaignName",
      headerName: pageTranslation.table_column_name || "Nom campagne",
      sortable: true,
      numberSort: false,
    },
    {
      field: "campaignCreator",
      headerName: pageTranslation.table_column_created_by || "Créé par",
      sortable: true,
      numberSort: false,
    },
    {
      field: "donationAmount",
      headerName: pageTranslation.table_column_amount || "Montant de dons",
      sortable: true,
      numberSort: true,
    },
    {
      field: "status",
      headerName: pageTranslation.table_column_status || "Statut",
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Actif" : "Désactivé"}
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
    {
      field: "acceptCash",
      headerName: pageTranslation.cash_filter_desc || "Comptant accepté",
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Oui" : "Non"}
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
    {
      field: "is_archived",
      headerName: pageTranslation.archived_filter_desc || "Archivé",
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Oui" : "Non"}
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
  ];

  const filters = {
    campaignName: (
      <CustomTextField
        value={tableFilter.campaignName}
        onChange={(e) => changeTableFilter("campaignName", e.target.value)}
        clearFilter={() => clearTableFilter("campaignName")}
      />
    ),
    campaignCreator: (
      <CustomTextField
        value={tableFilter.campaignCreator || ""}
        onChange={(e) => changeTableFilter("campaignCreator", e.target.value)}
        clearFilter={() => clearTableFilter("campaignCreator")}
      />
    ),
    donationAmount: (
      <CustomTextField
        value={tableFilter.donationAmount || ""}
        onChange={(e) => changeTableFilter("donationAmount", e.target.value)}
        clearFilter={() => clearTableFilter("donationAmount")}
      />
    ),
    status: (
      <CustomSelect
        value={tableFilter.status}
        onChange={(e) => {
          changeTableFilter("status", e.target.value);
        }}
        options={[
          { value: "", label: pageTranslation.status_filter_all || "Tout" },
          {
            value: true,
            label: pageTranslation.status_filter_enabled || "Actif",
          },
          {
            value: false,
            label: pageTranslation.status_filter_disabled || "Désactivé",
          },
        ]}
      />
    ),
    acceptCash: (
      <CustomSelect
        value={tableFilter.acceptCash}
        onChange={(e) => {
          changeTableFilter("acceptCash", e.target.value);
        }}
        options={[
          { value: "", label: pageTranslation.cash_filter_all || "Tout" },
          {
            value: true,
            label: pageTranslation.cash_filter_yes || "Oui",
          },
          {
            value: false,
            label: pageTranslation.cash_filter_no || "Non",
          },
        ]}
      />
    ),
    is_archived: (
      <CustomSelect
        value={tableFilter.is_archived}
        onChange={(e) => {
          changeTableFilter("is_archived", e.target.value);
        }}
        options={[
          { value: "", label: pageTranslation.archived_filter_all || "Tout" },
          {
            value: true,
            label: pageTranslation.archived_filter_yes || "Oui",
          },
          {
            value: false,
            label: pageTranslation.archived_filter_no || "Non",
          },
        ]}
      />
    ),
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {
    fetchPageData();
  }, [authContext.authContext.accessToken.organization_id]);

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter, authContext.authContext.accessToken.organization_id]);

  const fetchPageData = async () => {
    setLoading(true);
    await fetchCampaignsStatus();
    await fetchCampaigns();
    setLoading(false);
  };

  const fetchCampaigns = async () => {
    setFetchingTableData(true);
    try {
      let response = await api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id,
        tableFilter.campaignName,
        tableFilter.campaignCreator,
        tableFilter.donationAmount,
        tableFilter.status,
        tableFilter.acceptCash,
        tableFilter.is_archived
      );

      if (response.status === 200) {
        return response.data.map((campaign) => ({
          id: campaign._id,
          campaignId: campaign._id,
          acceptCash: campaign.can_donate_with_cash,
          campaignName: campaign.name,
          campaignCreator: `${campaign.created_by.last_name} ${campaign.created_by.first_name}`,
          donationAmount: campaign?.amountDonated
            ? numberWithCommas(campaign.amountDonated)
            : 0,
          status: campaign.enabled,
          is_archived: campaign.is_archived,
        }));
      }

      setFetchingTableData(false);
    } catch (e) {
      setFetchingTableData(false);
      setLoading(false);
    }
  };

  const fetchCampaignsStatus = async () => {
    try {
      let response = await api_endpoints.getCampaignsStatus(
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        setCompletionData(response.data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const selectCampaign = (e) => {
    navigate(getPageFromName("Campagne").path, {
      state: { campaign_id: e.id },
    });
  };

  const changeTableFilter = (option, value) => {
    setTableFilter({ ...tableFilter, [option]: value });
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchCampaigns(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  return (
      <>
        <div className={"flex justify-end"}>
          <NavigationButton
              startIcon={<AddIcon/>}
              text={"Campagne"}
              path={getPageFromName("Créer une campagne").path}
          />
        </div>
        <div className={styles.header}>
          <div className={styles.left_header}></div>
        </div>
        <div className={styles.stats_container}>
          <div className={styles.charts_wrapper}>
            <div className="container-white flex-col mt-4">
              <div>
                <div className="mb-4 flex w-full text-lg">
                  {pageTranslation.progress_graph_title ||
                      "Campagnes actives avec des objectifs"}
                </div>
                {completionData.length > 0 ? (
                    <React.Fragment key={uuidv4()}>
                      <div className="overflow-hidden">
                        {completionData.slice(0, 3).map((data, index) => (
                            <div
                                className={styles.bar_chart_data_wrapper}
                                key={uuidv4()}
                            >
                              <div className={styles.bar_chart_title}>
                                <label style={{justifyContent: "flex-start"}}>
                                  {data.name}
                                </label>
                                <label style={{fontSize: 14}}>
                                  {(
                                      (data.amountDonated / data.objective) *
                                      100
                                  ).toFixed(2)}{" "}
                                  %
                                </label>
                                <label style={{justifyContent: "flex-end"}}>
                                  {numberWithCommas(data.objective)}
                                </label>
                              </div>
                              <div className={styles.bar_chart_progress}>
                                <div
                                    style={{
                                      width:
                                          data.amountDonated / data.objective > 1
                                              ? "100%"
                                              : `${
                                                  (data.amountDonated / data.objective) *
                                                  100
                                              }%`,
                                    }}
                                ></div>
                              </div>
                            </div>
                        ))}
                      </div>
                      {completionData.length > 3 ? (
                          <>
                            <Dialog
                                fullWidth
                                open={modalStatusOpen}
                                onClose={() => setmodalStatusOpen(false)}
                                maxWidth="md"
                            >
                              <DialogTitle>
                                Liste complète de dons en cours
                              </DialogTitle>
                              <DialogContent>
                                {completionData.map((data, index) => (
                                    <div
                                        className={styles.bar_chart_data_wrapper}
                                        key={uuidv4()}
                                    >
                                      <div className={styles.bar_chart_title}>
                                        <label
                                            style={{justifyContent: "flex-start"}}
                                        >
                                          {data.name}
                                        </label>
                                        <label style={{fontSize: 14}}>
                                          {(
                                              (data.amountDonated / data.objective) *
                                              100
                                          ).toFixed(2)}{" "}
                                        </label>
                                        <label style={{justifyContent: "flex-end"}}>
                                          {numberWithCommas(data.objective)}
                                        </label>
                                      </div>
                                      <div className={styles.bar_chart_progress}>
                                        <div
                                            style={{
                                              width:
                                                  data.amountDonated / data.objective > 1
                                                      ? "100%"
                                                      : (data.amountDonated /
                                                          data.objective) *
                                                      100,
                                            }}
                                        ></div>
                                      </div>
                                    </div>
                                ))}
                              </DialogContent>
                            </Dialog>
                            <Button
                                fullWidth
                                onClick={() => setmodalStatusOpen(true)}
                            >
                              Afficher plus
                            </Button>
                          </>
                      ) : (
                          <></>
                      )}
                    </React.Fragment>
                ) : (
                    <div>
                      {pageTranslation.progress_graph_no_data ||
                          "Créez des campagnes pour visualiser leur progression"}
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-2 flex items-end space-x-4"></div>
          <div className={styles.table_container_body}>
            <DataGrid
                columns={columns}
                rows={rows}
                showDetails={selectCampaign}
                filters={filters}
                loading={loading}
            />
          </div>
        </div>
      </>
  );
};

export default ManageCampaigns;
