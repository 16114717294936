import { createTheme } from "@mui/material/styles";

export const colors = createTheme({
  palette: {
    secondary: {
      main: "#293241",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
    MuiDialogContent: {
      styleOverrides: { root: { paddingTop: `5px !important` } },
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});
