import styles from "./CreateCampaign.module.css";
import { getPageFromName } from "../../route_utils";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect, useState, useContext } from "react";
import SwitchWrapper from "../SwitchWrapper/SwitchWrapper";
import { numberWithCommas } from "../../utils";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Divider,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "dayjs/locale/fr-ca";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import InfoIcon from "@mui/icons-material/Info";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import ImageInputV2 from "../../widgets/ImageInput/ImageInputV2";

const DEFAULT_PRESETS = [5, 10, 20, 50, 100];

const CreateCampaign = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [organizationLocales, setOrganizationLocales] = useState([]);
  const [countryLocales, setCountryLocales] = useState([]);
  const [combinedLocales, setCombinedLocales] = useState([]);
  const [selectedLocale, setSelectedLocale] = useState("");
  const [campaignTranslations, setCampaignTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
    showWarningAlert,
    setShowWarningAlert,
    messageWarningAlert,
    setMessageWarningAlert,
  } = useSuccessAlert();
  const [presetKioskModalOpen, setPresetKioskModalOpen] = useState(false);
  const [presetAmount, setPresetAmount] = useState("");
  const [presetAmountExists, setPresetAmountExists] = useState(false);
  const [presetAmountBelowMin, setPresetAmountBelowMin] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    organization: authContext.authContext.accessToken.organization_id,
    name: "",
    has_duration: false,
    description: "",
    has_objective: false,
    objective: null,
    can_donate_with_cash: false,
    can_donate_anonymously: false,
    has_minimum_donation: false,
    donation_minimum: null,
    lightning_donation: false,
    campagne_img_url: "",
    campagne_type: "",
    cash_amount: [],
    kiosk_preset: DEFAULT_PRESETS,
    created_by: authContext.authContext.accessToken.id,
    reccuring: false,
    enabled: true,
    is_archived: false,
    date_start: null,
    date_end: null,
    is_global: false,
    location: "",
  });

  useEffect(() => {
    fetchInitialData();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (presetKioskModalOpen) setPresetAmount("");
  }, [presetKioskModalOpen]);

  useEffect(() => {
    if (countryLocales.length > 0) {
      const combined = [...countryLocales, ...organizationLocales];
      setCombinedLocales(combined);
      setSelectedLocale(countryLocales[0].id); // Assuming each locale has an 'id' property
    }
  }, [organizationLocales, countryLocales]);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      await fetchOrganizationLocales();
      await fetchCountryLocales();
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setLoadingError(true);
    }
    setLoading(false);
  };

  const fetchOrganizationLocales = async () => {
    setLoading(true);
    try {
      let response = await api_endpoints.getOrganizationLocale(
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        const selected = response.data.map((locale) => ({
          id: locale.locale._id,
          label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
        }));
        setOrganizationLocales(selected);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }
    setLoading(false);
  };

  const fetchCountryLocales = async () => {
    setLoading(true);

    try {
      const organizationResponse = await api_endpoints.getOrganization(
        authContext.authContext.accessToken.organization_id
      );

      if (
        organizationResponse.status === 200 &&
        organizationResponse.data.country
      ) {
        const countryId = organizationResponse.data.country._id;
        const countryLocalesResponse = await api_endpoints.getCountryLocales(
          countryId
        );

        if (countryLocalesResponse.status === 200) {
          const selected = countryLocalesResponse.data.map((locale) => ({
            id: locale.locale._id,
            label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
          }));

          setCountryLocales(selected);
        } else {
          console.error(
            "Failed to fetch country locales:",
            countryLocalesResponse
          );
        }
      } else {
        console.error(
          "Failed to fetch organization or country data:",
          organizationResponse
        );
      }
    } catch (error) {
      console.error("Error fetching country locales:", error);
    }

    setLoading(false);
  };

  const handleCreateCampaign = async (e) => {
    setSubmitting(true);
    e?.preventDefault();

    if (
      formData.has_duration &&
      (formData.date_start > formData.date_end ||
        formData.date_start === "" ||
        formData.date_end === "")
    ) {
      setDateError(true);
      setSubmitting(false);
      return;
    }

    const data = setRequestData();

    setSubmitting(false);

    try {
      let response = await api_endpoints.createCampaign(data);

      if (response.status === 200) {
        const campaign_id = response.data.campaignId;

        // Update translations
        const translationsResponse =
          await api_endpoints.editCampaignTranslations(
            campaign_id,
            campaignTranslations
          );

        if (translationsResponse.status === 200) {
          setMessageSuccessAlert(
            response.data.message ||
              "Campaign created and translations updated successfully"
          );
          setShowSuccessAlert(true);
        } else {
          console.error("Failed to update translations:", translationsResponse);
          setMessageWarningAlert(
            "Campaign created, but failed to update translations."
          );
          setShowWarningAlert(true); // Show a different alert for partial success
        }

        // Navigate to the campaigns page
        navigate(getPageFromName("Campagnes").path);
      }
    } catch (e) {
      console.error("Error creating campaign:", e);
      setMessageSuccessAlert("An error occurred while creating the campaign.");
      setShowSuccessAlert(true);
    } finally {
      setSubmitting(false);
    }
  };

  const setRequestData = () => {
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(formData)) {
      // set the campaign name to the first instance of a campaign name
      // translated
      if (key === "name") {
        // give it an initial value of empty string
        data.append(key, "");

        for (const campaignTranslationKey of Object.keys(
          campaignTranslations
        )) {
          // if the translation has a campaign name, set the first instance as the default value
          // and break from the loop
          if (campaignTranslations[campaignTranslationKey].campaign_name) {
            data.set(
              key,
              campaignTranslations[campaignTranslationKey].campaign_name
            );
            break;
          }
        }
      } else {
        data.append(key, value);
      }
    }

    return data;
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const fetchLocations = async () => {
    setLoading(true);

    try {
      let response = await api_endpoints.getLocations(
        authContext.authContext.accessToken.organization_id,
        null
      );

      if (response.status === 200) {
        if (!formData.location) {
          changeFormData("location", response.data[0]._id);
        }
        setLocations(response.data);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }

    setLoading(false);
  };

  const addPresetAmount = () => {
    if (!presetAmount) return;

    if (formData.kiosk_preset.includes(parseFloat(presetAmount).toFixed(2))) {
      setPresetAmountExists(true);
      return;
    }

    if (
      parseFloat(formData.donation_minimum) &&
      parseFloat(formData.donation_minimum) > parseFloat(presetAmount)
    ) {
      setPresetAmountBelowMin(true);
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      kiosk_preset: [
        ...prevState.kiosk_preset,
        parseFloat(presetAmount).toFixed(2),
      ].sort(compareNumbers),
    }));
    setPresetKioskModalOpen(false);
    setPresetAmountBelowMin(false);
  };

  const compareNumbers = (a, b) => {
    return a - b;
  };

  const handleRemovePresetKiosk = (preset) => {
    setFormData((prevState) => ({
      ...prevState,
      kiosk_preset: prevState.kiosk_preset.filter((item) => item !== preset),
    }));
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  const changelocale = (localeId) => {
    setSelectedLocale(localeId);
    setCampaignTranslations((prevTranslations) => ({
      ...prevTranslations,
      [localeId]: {
        campaign_name: prevTranslations[localeId]?.campaign_name || "",
        campaign_description:
          prevTranslations[localeId]?.campaign_description || "",
      },
    }));
  };

  return (
    <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
      <Dialog
        fullWidth
        open={presetKioskModalOpen}
        onClose={() => {
          setPresetKioskModalOpen(false);
          setPresetAmountExists(false);
        }}
      >
        <DialogTitle>Ajouter un montant préconfiguré</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            value={presetAmount}
            onInput={(e) => {
              setPresetAmount(e.target.value);
              setPresetAmountExists(false);
            }}
            type="number"
            placeholder="Montant"
            label="Montant"
            FormHelperTextProps={{ sx: { color: "error.main" } }}
            helperText={
              presetAmountExists
                ? "Ce montant existe déjà"
                : presetAmountBelowMin
                ? "Le montant entré est inférieur au montant minimum"
                : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addPresetAmount}>
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingContainer loading={loading} error={loadingError}>
        <form
          id="publish_campaign_form"
          onSubmit={handleCreateCampaign}
          autoComplete="off"
          enctype="multipart/form-data"
        >
          <div className={`${styles.details_container} mt-4`}>
            <div className="flex flex-1 flex-col space-y-4">
              <div className="flex flex-1 space-y-4">
                <div className="w-full space-y-4">
                  <div className="flex flex-1 space-x-4 space-y-4">
                    <FormControl fullWidth>
                      <InputLabel required>
                        {pageTranslation.create_campaign_location_choice ||
                          "Choisir un emplacement"}
                      </InputLabel>
                      <Select
                        value={formData.location}
                        label={
                          pageTranslation.create_campaign_location_choice ||
                          "Choisir un emplacement"
                        }
                        onChange={(e) =>
                          changeFormData("location", e.target.value)
                        }
                        fullWidth
                        required
                      >
                        {locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            <div className="flex flex-col">
                              <label className="text-lg font-semibold">
                                {location.location_name}
                              </label>
                              <label>{location.address}</label>
                              <label>{location.phone}</label>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <ImageInputV2
                      pageTranslation={pageTranslation}
                      defaultImage={null}
                      image={image}
                      onChange={handleSelectImage}
                      removeImage={removeImage}
                      placeholder={
                        pageTranslation.create_campaign_imagepreview ||
                        "Ajouter une image de campagne"
                      }
                      helperText={
                        pageTranslation.create_campaign_imagerequirements ||
                        "L'image peut avoir une taille maximale de 1 MB et les formats suivants sont acceptés : .png .gif .jpeg"
                      }
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex flex-1 flex-col space-y-4">
                <div>
                  <FormControl fullWidth>
                    <InputLabel required>Choisir une langue</InputLabel>
                    <Select
                      value={selectedLocale}
                      label="Choisir une langue"
                      onChange={(e) => changelocale(e.target.value)}
                      fullWidth
                      required
                    >
                      {combinedLocales.map((locale) => (
                        <MenuItem key={locale.id} value={locale.id}>
                          <div className="flex flex-col">{locale.label}</div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="flex items-end space-x-4">
                <TextField
                  fullWidth
                  label={
                    pageTranslation.create_campaign_name || "Nom de campagne"
                  }
                  required
                  value={
                    campaignTranslations[selectedLocale]?.campaign_name || ""
                  }
                  onChange={(e) => {
                    // Update the translation for the selected locale
                    setCampaignTranslations((prevTranslations) => ({
                      ...prevTranslations,
                      [selectedLocale]: {
                        ...prevTranslations[selectedLocale],
                        campaign_name: e.target.value,
                      },
                    }));
                  }}
                />
                <SwitchWrapper
                  label={pageTranslation.create_campaign_duration || "Durée"}
                  style={{ width: "100%" }}
                  className="space-y-2"
                  checked={formData.has_duration}
                  onChange={(e) =>
                    changeFormData("has_duration", e.target.checked)
                  }
                  required={formData.has_duration}
                >
                  <div className="flex space-x-2 justify-between w-full">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fr-ca"
                    >
                      <DatePicker
                        value={formData.date_start}
                        onChange={(value) =>
                          changeFormData("date_start", value)
                        }
                        label={pageTranslation.create_campaign_start || "Début"}
                        sx={{ width: "100%" }}
                        slotProps={
                          dateError
                            ? {
                                textField: {
                                  error: dateError,
                                  helperText: "Dates invalides",
                                  required: formData.has_duration,
                                },
                              }
                            : {
                                textField: {
                                  error: false,
                                  required: formData.has_duration,
                                },
                              }
                        }
                      />
                      <DatePicker
                        value={formData.date_end}
                        onChange={(value) => changeFormData("date_end", value)}
                        label={pageTranslation.create_campaign_end || "Fin"}
                        sx={{ width: "100%" }}
                        slotProps={
                          dateError
                            ? {
                                textField: {
                                  error: dateError,
                                  helperText: "Dates invalides",
                                  required: formData.has_duration,
                                },
                              }
                            : {
                                textField: {
                                  error: false,
                                  required: formData.has_duration,
                                },
                              }
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </SwitchWrapper>
              </div>
              <div className="flex flex-row space-x-4">
                <SwitchWrapper
                  label={
                    pageTranslation.create_campaign_objective || "Objectif"
                  }
                  style={{ width: "100%" }}
                  checked={formData.has_objective}
                  onChange={(e) => {
                    changeFormData("has_objective", e.target.checked);
                  }}
                  required={formData.has_objective}
                >
                  <TextField
                    label={
                      pageTranslation.create_campaign_objectivetext ||
                      "L'objectif que cette campagne doit atteindre"
                    }
                    type="number"
                    fullWidth
                    value={formData.objective}
                    onInput={(e) => {
                      changeFormData("objective", e.target.value);
                    }}
                    required={formData.has_objective}
                  />
                </SwitchWrapper>
                <SwitchWrapper
                  style={{ marginLeft: 30, width: "100%" }}
                  label={
                    pageTranslation.create_campaign_minamount ||
                    "Entrer un montant minimum"
                  }
                  checked={formData.has_minimum_donation}
                  onChange={(e) => {
                    changeFormData("has_minimum_donation", e.target.checked);
                  }}
                  required={formData.has_minimum_donation}
                >
                  <TextField
                    label={
                      pageTranslation.create_campaign_no_min || "Pas de minimum"
                    }
                    fullWidth
                    type="number"
                    value={formData.donation_minimum}
                    onChange={(e) => {
                      changeFormData("donation_minimum", e.target.value);
                    }}
                    required={formData.has_minimum_donation}
                  />
                </SwitchWrapper>
              </div>
              <div>
                <TextField
                  label={
                    pageTranslation.create_campaign_description || "Description"
                  }
                  fullWidth
                  multiline
                  rows={5}
                  value={
                    campaignTranslations[selectedLocale]
                      ?.campaign_description || ""
                  }
                  onChange={(e) => {
                    setCampaignTranslations((prevTranslations) => ({
                      ...prevTranslations,
                      [selectedLocale]: {
                        ...prevTranslations[selectedLocale],
                        campaign_description: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <Divider />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.enabled}
                      disabled
                      value={formData.enabled}
                    />
                  }
                  label={pageTranslation.create_campaign_active || "Active"}
                />
                <Tooltip title="Par défaut, toutes les campagnes créées seront actives. Vous pouvez aller la modifier ultérieurement">
                  <InfoIcon className="text-gray-400" />
                </Tooltip>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.can_donate_anonymously}
                    value={formData.can_donate_anonymously}
                    onChange={(e) =>
                      changeFormData("can_donate_anonymously", e.target.checked)
                    }
                  />
                }
                label={
                  pageTranslation.create_campaign_anonymous_donations ||
                  "Accepter dons anonymes"
                }
              />
              <Divider />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}></div>
                <div style={{ display: "flex", flexDirection: "column" }}></div>
                <div style={{ display: "flex", flexDirection: "column" }}></div>
              </div>
            </div>
            <div className={styles.amount_presets_container}>
              <label>
                {pageTranslation.create_campaign_preset_donations ||
                  "Montant de don ( Montants préconfigurés sur le Kiosk )"}
              </label>
              <div>
                {formData.kiosk_preset?.map((preset, index) => (
                  <div className={styles.kiosk_preset} key={preset + index}>
                    <SlIconButton
                      className="flex-[0] !justify-end z-10 text-red-500"
                      name="trash"
                      label="Remove preset"
                      onClick={() => handleRemovePresetKiosk(preset)}
                    />
                    <div className={styles.preset_amount}>
                      <SlIcon library="boxicons" name="bx-dollar" />
                      {numberWithCommas(parseFloat(preset).toFixed(2))}
                    </div>
                  </div>
                ))}
                <Button
                  variant="contained"
                  onClick={() => setPresetKioskModalOpen(true)}
                  className="flex flex-col text-center w-[123px] h-[110px]"
                  sx={{
                    padding: 7,
                  }}
                >
                  {pageTranslation.create_campaign_addamount ||
                    "Ajouter un montant"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex mt-4 pb-4 space-x-4 justify-end">
            <CancelButton
              disabled={submitting}
              label={pageTranslation.create_campaign_cancel_button || "Annuler"}
            />
            <ButtonLoading
              loading={submitting}
              variant="contained"
              type="submit"
            >
              {pageTranslation.create_campaign_publish_button ||
                "Publier campagne"}
            </ButtonLoading>
          </div>
        </form>
      </LoadingContainer>
    </AccessControl>
  );
};

export default CreateCampaign;
