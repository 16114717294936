import Header from "../Header/Header.js";
import styles from "./AppContainer.module.css";
import {getPageFromName, PAGES} from "../../route_utils";
import {Routes, Route, useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext/AuthContext.js";
import React, {useContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import pageTranslation from "../../higher-order-components/PageTranslation/PageTranslation.jsx";
import PageWrapper from "../../widgets/PageWrapper/PageWrapper";
import LoadingContainer from "../LoadingContainer/LoadingContainer";

const AppContainer = () => {
  const authContext = useContext(AuthContext);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(isFirstLoad){
      if (
          !authContext?.authContext?.accessToken?.organization_id &&
          authContext?.authContext?.accessToken?.is_super_user
      ) {
        navigate(getPageFromName("Organisations").path);
      } else if (
          authContext?.authContext?.accessToken?.organization_id &&
          !authContext?.authContext?.accessToken?.is_super_user
      ) {
        navigate(getPageFromName("Tableau de bord").path);
      }

      setIsFirstLoad(false);  
    }

  }, [location.pathname]);

  useEffect(() => {
    if(!isFirstLoad){
      setLoading(false)  
    }
  }, [location.pathname, isFirstLoad]);

  return (
    <div className={styles.main_container}>
      <div className={styles.middlepanel_container}>
        <LoadingContainer loading={loading} message={"Chargement de l'application"}>
          <Header>
            <Routes>
              {Object.keys(PAGES).map((category, index) => {
                // if root has no pages then we return empty fragment
                if (PAGES[category].pages.length === 0)
                  return <React.Fragment key={PAGES[category].label + index} />;

                // if root requires super user access and user is not super user then we return empty fragment
                if (
                    PAGES[category].super_user &&
                    !authContext?.authContext?.accessToken?.is_super_user
                )
                  return <React.Fragment key={PAGES[category].label + index} />;

                return (PAGES[category].pages.map((page, index) => {

                  // if user does not have an org and a page requires an org to be accessed then we return empty fragment
                  if (
                      page.requiresOrg &&
                      !authContext?.authContext?.accessToken?.organization_id
                  )
                    return <React.Fragment key={page.name} />;

                  const HigherOrderPageTranslationComponent = pageTranslation(
                      page.component,
                      page.name
                  );

                  return (
                      <Route
                          key={page.name}
                          path={`${page.path}`}
                          element={
                            // wrap each pages in a div and give it a page title on top
                            <PageWrapper
                                title={page.label}
                                canReturn={page.canReturn}
                                pageComponent={<HigherOrderPageTranslationComponent />}
                            />
                          }
                      />
                  );
                }))
              })}
            </Routes>
          </Header>
        </LoadingContainer>
      </div>
    </div>
  );
};

export default AppContainer;
